import { __decorate } from "tslib";
// Watch
import { Component, Vue } from 'vue-property-decorator';
import { userToolStore, rotaStore } from '@/store';
import ClickableCard from '@/components/ClickableCard.vue';
import EntidadeLinkComponent from '@/components/EntidadeLinkComponent.vue';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import _ from 'lodash';
let RotaList = class RotaList extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            {
                text: 'Dia',
                sortable: true,
                value: 'dia_semana',
                align: 'left',
            },
            {
                text: 'Horário',
                sortable: true,
                value: 'dia_semana',
                align: 'left',
            },
            {
                text: 'Veículo',
                sortable: true,
                value: 'veiculo',
                align: 'left',
            },
            {
                text: 'Ações',
                value: 'actions',
            },
        ];
        this.propertyToShowDetails = null;
        this.loading = true;
        this.search = '';
        this.selectedItem = {};
        this.searchAlunoSemTransporte = '';
        this.searchAlunoRetirarTransporte = '';
        this.searchAlunoComTransporte = '';
        this.filtroHoraInicio = '';
        this.filtroHoraFim = '';
        this.headersAlunoSemTransporte = [
            { text: 'Aluno', value: 'aluno_id' },
            { text: 'Bairro', value: 'bairro' },
            { text: 'Horário da aula', value: 'cronograma_horario' },
        ];
        this.headersAlunoRetirarTransporte = [
            { text: 'Aluno', value: 'aluno_id' },
            { text: 'Rota', value: 'dia_semana' },
        ];
        this.headersAlunoComTransporte = [
            { text: 'Aluno', value: 'aluno_id' },
            { text: 'Rota', value: 'dia_semana' },
        ];
    }
    get allowView() {
        if (userToolStore.userToolsIndexed.Rotas) {
            return userToolStore.userToolsIndexed.Rotas[0].allow_view;
        }
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed.Rotas) {
            return userToolStore.userToolsIndexed.Rotas[0].allow_create;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed.Rotas) {
            return userToolStore.userToolsIndexed.Rotas[0].allow_edit;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed.Rotas) {
            return userToolStore.userToolsIndexed.Rotas[0].allow_delete;
        }
    }
    get alunoSemTransporte() {
        return rotaStore.alunoSemTransporte.sort((a, b) => {
            return a.aluno_nome < b.aluno_nome ? -1 : a.aluno_nome > b.aluno_nome ? 1 : 0;
        });
    }
    get filteredAlunoSemTransporte() {
        if (!this.searchAlunoSemTransporte) {
            return this.alunoSemTransporte;
        }
        return this.alunoSemTransporte.filter((aluno) => aluno.aluno_nome.toLowerCase().includes(this.searchAlunoSemTransporte.toLowerCase()));
    }
    get alunoRetirarTransporte() {
        return rotaStore.alunoRetirarTransporte.sort((a, b) => {
            return a.aluno_nome < b.aluno_nome ? -1 : a.aluno_nome > b.aluno_nome ? 1 : 0;
        });
    }
    get filteredAlunoRetirarTransporte() {
        if (!this.searchAlunoRetirarTransporte) {
            return this.alunoRetirarTransporte;
        }
        return this.alunoRetirarTransporte.filter((aluno) => aluno.aluno_nome.toLowerCase().includes(this.searchAlunoRetirarTransporte.toLowerCase()));
    }
    get alunoComTransporte() {
        return rotaStore.alunoComTransporte.sort((a, b) => {
            return a.aluno_nome < b.aluno_nome
                ? -1
                : a.aluno_nome > b.aluno_nome
                    ? 1
                    : 0;
        });
    }
    get filteredAlunoComTransporte() {
        if (!this.searchAlunoComTransporte) {
            return this.alunoComTransporte;
        }
        return this.alunoComTransporte.filter((aluno) => aluno.aluno_nome.toLowerCase().includes(this.searchAlunoComTransporte.toLowerCase()));
    }
    get rotas() {
        const inicioSplitted = this.filtroHoraInicio.split(':');
        const fimSplitted = this.filtroHoraFim.split(':');
        let inicioInt = 0;
        let fimInt = 0;
        if (parseInt(inicioSplitted[0], 10) <= 23 && parseInt(inicioSplitted[1], 10) <= 59) {
            inicioInt = parseInt(`${inicioSplitted[0]}${inicioSplitted[1]}`, 10);
        }
        if (parseInt(fimSplitted[0], 10) <= 23 && parseInt(fimSplitted[1], 10) <= 59) {
            fimInt = parseInt(`${fimSplitted[0]}${fimSplitted[1]}`, 10);
        }
        if (inicioInt > 0 && fimInt > 0) {
            return _.filter(rotaStore.rotas, (el) => {
                // @ts-ignore
                if (el.horario_inicio && el.horario_fim) {
                    // @ts-ignore
                    const elInicioSplitted = el.horario_inicio.split(':');
                    // @ts-ignore
                    const elFimSplitted = el.horario_fim.split(':');
                    const elInicioInt = parseInt(`${elInicioSplitted[0]}${elInicioSplitted[1]}`, 10);
                    const elFimInt = parseInt(`${elFimSplitted[0]}${elFimSplitted[1]}`, 10);
                    return (elInicioInt >= inicioInt && elInicioInt <= fimInt) || (elFimInt >= inicioInt && elFimInt <= fimInt);
                }
                return false;
            });
        }
        return rotaStore.rotas;
    }
    getColorByQuantity(quantity) {
        if (quantity <= 0) {
            return 'red';
        }
        else if (quantity > 0 && quantity <= 2) {
            return 'yellow';
        }
        else {
            return 'green';
        }
    }
    async deleteItem(id) {
        this.$swal({
            title: 'Confirma a exclusão da rota?',
            text: 'Após exclusão a rota não pode ser recuperada!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.loading = true;
                // @ts-ignore
                await rotaStore.deleteRota(id);
                // @ts-ignore
                await rotaStore.getRotas();
                this.loading = false;
            }
        });
    }
    async printItem(rotaId) {
        const bytes = await rotaStore.getRotaPDF(rotaId);
        if (bytes) {
            const w = window.open();
            w.document.write(bytes);
            setTimeout(() => {
                w.print();
            }, 1000);
        }
    }
    async mounted() {
        this.loading = true;
        await rotaStore.getRotas();
        await rotaStore.getAlunoComTransporte();
        await rotaStore.getAlunoSemTransporte();
        await rotaStore.getAlunoRetirarTransporte();
        this.loading = false;
    }
    routeEdit(id) {
        this.$router.push({ name: 'main-rotas-edit', params: { id } });
    }
};
RotaList = __decorate([
    Component({
        components: {
            ClickableCard,
            EntidadeLinkComponent,
            OpenNewTabComponent,
        },
    })
], RotaList);
export default RotaList;
