import { __decorate } from "tslib";
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { api } from '@/api';
import { mainStore } from '@/utils/store-accessor';
let MetaModule = class MetaModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.metas = [];
    }
    setMetas(payload) {
        this.metas = payload;
    }
    addMeta(payload) {
        this.metas.unshift(payload);
    }
    removeMeta(id) {
        this.metas = this.metas.filter((meta) => meta.id !== id);
    }
    updateMetaMutation(payload) {
        const index = this.metas.findIndex((meta) => meta.id === payload.id);
        if (index !== -1) {
            this.metas.splice(index, 1, payload);
        }
    }
    async getMetas() {
        try {
            const response = await api.getMetas(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getMetasByMonth(payload) {
        try {
            const response = await api.getMetasByMonth(mainStore.token, payload.mesGeral);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async createMeta(payload) {
        try {
            const response = await api.createMeta(mainStore.token, payload);
            if (response && response.data) {
                this.addMeta(response.data);
                mainStore.addNotification({
                    content: 'Meta criada com sucesso',
                    color: 'success',
                });
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async updateMeta(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.updateMeta(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Meta atualizado com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async deleteMeta(id) {
        try {
            await api.deleteMeta(mainStore.token, id);
            this.removeMeta(id);
            mainStore.addNotification({
                content: 'Meta excluída com sucesso',
                color: 'success',
            });
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getContratosAndCda(payload) {
        try {
            const response = await api.getContratosAndCda(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getMetasPerformance(payload) {
        try {
            const response = await api.getMetasPerformance(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getMetasMensalidade(payload) {
        try {
            const response = await api.getMetasMensalidade(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getMetasVisitas(payload) {
        try {
            const response = await api.getMetasVisitas(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], MetaModule.prototype, "setMetas", null);
__decorate([
    Mutation
], MetaModule.prototype, "addMeta", null);
__decorate([
    Mutation
], MetaModule.prototype, "removeMeta", null);
__decorate([
    Mutation
], MetaModule.prototype, "updateMetaMutation", null);
__decorate([
    Action
], MetaModule.prototype, "getMetas", null);
__decorate([
    Action
], MetaModule.prototype, "getMetasByMonth", null);
__decorate([
    Action
], MetaModule.prototype, "createMeta", null);
__decorate([
    Action
], MetaModule.prototype, "updateMeta", null);
__decorate([
    Action
], MetaModule.prototype, "deleteMeta", null);
__decorate([
    Action
], MetaModule.prototype, "getContratosAndCda", null);
__decorate([
    Action
], MetaModule.prototype, "getMetasPerformance", null);
__decorate([
    Action
], MetaModule.prototype, "getMetasMensalidade", null);
__decorate([
    Action
], MetaModule.prototype, "getMetasVisitas", null);
MetaModule = __decorate([
    Module({ name: 'meta' })
], MetaModule);
export default MetaModule;
