import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import { ocorrenciaStore, ocorrenciaTarefaStore } from '@/utils/store-accessor';
import SearchEntidadeComponent from './SearchEntidadeComponent.vue';
import DateFieldComponent from './DateFieldComponent.vue';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
Validator.extend('timeValidationRules', {
    validate: (value) => {
        const regex = /^([01]\d|2[0-3]):([0-5]\d)$/;
        return regex.test(value);
    },
    getMessage: (field) => 'O campo de hora deve estar no formato HH:MM',
});
let TarefaAgendaReuniaoComponent = class TarefaAgendaReuniaoComponent extends Vue {
    constructor() {
        super(...arguments);
        this.currentItem = null;
        this.currentItemObservacao = '';
        this.ocorrenciaTarefaHora = '';
        this.ocorrenciaEntidadeSelected = null;
        this.ocorrenciaReuniaoSelected = 1;
        this.ocorrenciaTarefaData = null;
    }
    get ocorrenciaReuniaoOptions() {
        return [
            { id: 1, nome: 'Outros' },
            { id: 2, nome: 'Reunião financeira' },
            { id: 3, nome: 'Reunião pedagógica' },
            { id: 4, nome: 'Reunião de rendimento' },
            { id: 5, nome: 'Reunião troca de livro' },
            { id: 6, nome: 'Reunião rematrícula' },
        ];
    }
    onCancel() {
        this.$emit('on-close');
    }
    async realizarTarefa() {
        let entidadeIsValid = true;
        // @ts-ignore
        entidadeIsValid = await this.$refs.searchEntidadeComponent.validateField();
        // @ts-ignore
        const isValid = await this.$refs.observer.validate();
        if (!isValid || !entidadeIsValid) {
            return;
        }
        const obj = {
            ocorrencia_id: this.propOcorrenciaId,
            responsavel_id: this.ocorrenciaEntidadeSelected.id,
            data: this.ocorrenciaTarefaData,
            hora: this.ocorrenciaTarefaHora,
            descricao: this.currentItemObservacao,
            reuniao_tipo_id: this.ocorrenciaReuniaoSelected,
            pedido_reuniao_tipo_id: null,
            status_id: 0,
        };
        await ocorrenciaTarefaStore.createOcorrenciaTarefa(obj);
        const current_obj = {
            id: this.propOcorrenciaTarefaId,
            status_id: 1,
            observacao: this.currentItemObservacao,
        };
        await ocorrenciaStore.updateOcorrenciaTarefa(current_obj);
        this.$emit('on-confirm');
        this.$emit('on-close');
    }
};
__decorate([
    Prop({ type: Number, default: null })
], TarefaAgendaReuniaoComponent.prototype, "propOcorrenciaId", void 0);
__decorate([
    Prop({ type: Number, default: null })
], TarefaAgendaReuniaoComponent.prototype, "propOcorrenciaTarefaId", void 0);
TarefaAgendaReuniaoComponent = __decorate([
    Component({
        components: {
            SearchEntidadeComponent,
            DateFieldComponent,
            ValidationObserver,
            ValidationProvider
        },
    })
], TarefaAgendaReuniaoComponent);
export default TarefaAgendaReuniaoComponent;
