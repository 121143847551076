import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { userStore, metaStore, mainStore, metaIndividualStore } from '@/store';
import ConfigMetasComponent from '@/components/ConfigMetasComponent.vue';
let MetasCreateComponent = class MetasCreateComponent extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            { text: 'Colaborador', value: 'full_name', show: true },
            { text: 'Metas', value: 'meta', show: true },
            { text: 'Bonificação (R$)', value: 'bonus', show: true },
            { text: 'Editar/Excluir', value: 'actions', show: true },
        ];
        this.entityObject = {
            mes_geral: [],
            formattedDateGeral: [],
            mensalidades: '',
            qtdCupons: '',
            selectedColaboradores: [],
            meta1: 8,
            meta2: 12,
            meta3: 15,
            bonus1: '',
            bonus2: '',
            bonus3: '',
        };
        this.items = [];
        this.uniqueIdCounter = 0;
        this.menuGeral = false;
        this.mensalidades = '';
        this.qtdCupons = '';
        this.selectedColaboradores = [];
        this.somenteAtivos = true;
        this.search = '';
        this.loading = false;
        this.selectedTab = 0;
        this.editDialog = false;
        this.selectedItem = null;
        this.confirmationDialog = false;
        this.minDate = new Date().toISOString().substr(0, 7);
        this.date = new Date().toISOString().substr(0, 7);
    }
    handleDateInput(newDates) {
        this.entityObject.mes_geral = Array.from(new Set(newDates));
        this.updateFormattedDateGeral();
    }
    updateFormattedDateGeral() {
        this.entityObject.formattedDateGeral = this.entityObject.mes_geral.map((date) => this.formatDateToDisplay(date));
    }
    formatDateToDisplay(date) {
        const meses = [
            'Janeiro',
            'Fevereiro',
            'Março',
            'Abril',
            'Maio',
            'Junho',
            'Julho',
            'Agosto',
            'Setembro',
            'Outubro',
            'Novembro',
            'Dezembro',
        ];
        const [year, month] = date.split('-');
        const monthIndex = parseInt(month, 10) - 1;
        return `${meses[monthIndex]} de ${year}`;
    }
    formatCurrency(field) {
        const rawValue = this.entityObject[field].toString().replace(/[^\d]/g, '');
        const formattedValue = new Intl.NumberFormat('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: true,
        }).format(Number(rawValue) / 100);
        this.entityObject[field] = formattedValue;
    }
    preventNonNumeric(event) {
        const charCode = event.key.charCodeAt(0);
        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
        }
    }
    openConfirmationDialog() {
        this.confirmationDialog = true;
    }
    cancelConfirmation() {
        this.confirmationDialog = false;
    }
    async confirmAndSave() {
        this.confirmationDialog = false;
        await this.createMetas();
    }
    clearMetas() {
        this.entityObject = {
            formattedDateGeral: [],
            selectedColaboradores: [],
            meta1: '',
            meta2: '',
            meta3: '',
            bonus1: '',
            bonus2: '',
            bonus3: '',
        };
    }
    clearMetasIndividual() {
        this.entityObject.selectedColaboradores = [];
        this.entityObject.meta1 = '';
        this.entityObject.meta2 = '';
        this.entityObject.meta3 = '';
        this.entityObject.bonus1 = '';
        this.entityObject.bonus2 = '';
        this.entityObject.bonus3 = '';
    }
    openEditDialog(item) {
        this.selectedItem = { ...item };
        this.editDialog = true;
    }
    closeEditDialog() {
        this.editDialog = false;
        this.selectedItem = null;
    }
    get isAddButtonEnabled() {
        return (this.entityObject.selectedColaboradores.length > 0 &&
            this.entityObject.meta1 !== '' &&
            this.entityObject.bonus1 !== '');
    }
    updateItem(updatedItem) {
        const index = this.items.findIndex((item) => item.id === updatedItem.id);
        if (index !== -1) {
            updatedItem.meta = `${updatedItem.meta_01 ||
                ''} - ${updatedItem.meta_02 || ''} - ${updatedItem.meta_03 || ''}`;
            updatedItem.bonus = `${updatedItem.bonus1 || ''} - ${updatedItem.bonus2 ||
                ''} - ${updatedItem.bonus3 || ''}`;
            this.$set(this.items, index, updatedItem);
        }
    }
    async addMeta() {
        this.entityObject.selectedColaboradores.forEach((colaborador) => {
            const newItem = {
                id: ++this.uniqueIdCounter,
                full_name: colaborador.full_name,
                meta: `${this.entityObject.meta1} - ${this.entityObject.meta2} - ${this.entityObject.meta3}`,
                bonus: `${this.entityObject.bonus1} - ${this.entityObject.bonus2} - ${this.entityObject.bonus3}`,
                user_id: colaborador,
                meta_01: this.entityObject.meta1 || '',
                meta_02: this.entityObject.meta2 || '',
                meta_03: this.entityObject.meta3 || '',
                bonificacao_01: this.formatForApi(this.entityObject.bonus1),
                bonificacao_02: this.formatForApi(this.entityObject.bonus2),
                bonificacao_03: this.formatForApi(this.entityObject.bonus3),
            };
            this.items.push(newItem);
        });
        this.clearMetasIndividual();
    }
    async createMetas() {
        this.loading = true;
        const metasPromises = this.entityObject.mes_geral.map(async (mes) => {
            const metaPayload = {
                mes_geral: mes.includes('-') ? `${mes}-01` : mes,
                qtd_mensalidades: this.entityObject.mensalidades,
                qtd_cupons: this.entityObject.qtdCupons,
                company_id: this.userProfile.company.id,
            };
            const metaResponse = await metaStore.createMeta(metaPayload);
            const metaId = metaResponse.id;
            const metasIndividualPromises = this.items.map((item) => {
                const metaIndividualPayload = {
                    meta_01: item.meta_01,
                    meta_02: item.meta_02,
                    meta_03: item.meta_03,
                    bonificacao_01: item.bonificacao_01,
                    bonificacao_02: item.bonificacao_02,
                    bonificacao_03: item.bonificacao_03,
                    user_id: item.user_id.id,
                    company_id: this.userProfile.company.id,
                    meta_id: metaId,
                };
                return metaIndividualStore.createMetaIndividual(metaIndividualPayload);
            });
            return Promise.all(metasIndividualPromises);
        });
        await Promise.all(metasPromises);
        this.items = [];
        this.clearMetas();
        this.$emit('on-cancel');
        this.$emit('reload');
        this.loading = false;
    }
    formatForApi(value) {
        const rawValue = value.replace(/[^\d,]/g, '').replace(',', '.');
        return rawValue || '';
    }
    async deleteItem(id) {
        this.$swal({
            title: 'Confirma a exclusão da meta?',
            text: 'Após exclusão da meta, não pode ser recuperado!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then((result) => {
            if (result.isConfirmed) {
                this.items = this.items.filter((item) => item.id !== id);
                this.$swal('Excluído!', 'A meta foi excluída com sucesso.', 'success');
            }
        });
    }
    onCancel() {
        this.$emit('on-cancel');
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    get colaboradorItems() {
        return userStore.users;
    }
    async buscarColaboradores() {
        await userStore.getUsers(this.somenteAtivos);
    }
    get filteredColaboradores() {
        const cargoId = this.selectedTab === 0 ? 3 : 5;
        return this.colaboradorItems.filter((colaborador) => colaborador.cargo_id === cargoId);
    }
    async mounted() {
        this.buscarColaboradores();
    }
};
MetasCreateComponent = __decorate([
    Component({
        components: {
            ConfigMetasComponent,
        },
    })
], MetasCreateComponent);
export default MetasCreateComponent;
