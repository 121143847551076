import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
let Confirmation = class Confirmation extends Vue {
    get mesVigencia() {
        return 'Janeiro';
        // return format((addMonths(new Date(), 1)), 'LLLL', { locale: ptBR });
    }
};
Confirmation = __decorate([
    Component({
        components: {},
    })
], Confirmation);
export default Confirmation;
