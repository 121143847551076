import { mainStore } from '@/store';
export const getLocalToken = () => localStorage.getItem('token');
export const saveLocalToken = (token) => localStorage.setItem('token', token);
export const removeLocalToken = () => localStorage.removeItem('token');
export const validateCPF = (CPF) => {
    const checkCPF = (cpf) => {
        let lastNum = -1;
        let isIqual = false;
        for (let i = 0; i < cpf.length; i++) {
            const num = +cpf[i];
            if (lastNum < 0) {
                lastNum = num;
                continue;
            }
            if (num !== lastNum) {
                isIqual = false;
                break;
            }
            isIqual = true;
        }
        if (isIqual) {
            return false;
        }
        const verfDigit1 = +cpf[cpf.length - 2];
        const verfDigit2 = +cpf[cpf.length - 1];
        let cpfNumbers = cpf.substring(0, cpf.length - 2);
        let mult = 10;
        let result = 0;
        for (let i = 0; i < cpfNumbers.length; i++) {
            const num = +cpfNumbers[i];
            result += num * mult;
            mult--;
        }
        let mod = (result * 10) % 11;
        mod = mod === 10 ? 0 : mod;
        if (mod === verfDigit1) {
            cpfNumbers += `${verfDigit1}`;
        }
        else {
            return false;
        }
        mult = 11;
        result = 0;
        for (let i = 0; i < cpfNumbers.length; i++) {
            const num = +cpfNumbers[i];
            result += num * mult;
            mult--;
        }
        mod = (result * 10) % 11;
        mod = mod === 10 ? 0 : mod;
        if (mod === verfDigit2) {
            cpfNumbers += `${verfDigit2}`;
        }
        else {
            return false;
        }
        return true;
    };
    if (CPF.length === 11) {
        return checkCPF(CPF);
    }
    else if (CPF.length === 14) {
        const parsedCPF = CPF.replaceAll('.', '').replaceAll('-', '');
        return checkCPF(parsedCPF);
    }
    else {
        return false;
    }
};
export const downloadZip = (fileName, base64String) => {
    const binaryData = atob(base64String);
    const uint8Array = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
        uint8Array[i] = binaryData.charCodeAt(i);
    }
    const blob = new Blob([uint8Array], { type: 'application/zip' });
    const blobUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = blobUrl;
    a.download = `${fileName}.zip`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    return;
};
export const regexValidacaoEmail = () => {
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
};
export const regexValidacaoCelular = () => {
    return /^\(?\d{2}\)?\s?\d{5}-?\d{4}$/;
};
export const getNomeAulaExtraSingular = () => {
    return mainStore.userProfile.company.escola_id == 1 ? 'Aula extra' : 'Aula plus';
};
