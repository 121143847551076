import { __decorate } from "tslib";
// Watch
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import _ from 'lodash';
import { contaBancariaStore, dashboardStore, financeiroCategoriaStore, financeiroParcelaMovimentacaoStore, financeiroParcelaStore, financeiroStore, mainStore, userToolStore, userActionStore } from '@/store';
import SaveButton from '@/components/SaveButton.vue';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
import { newFinanceiroContaPagar, newFinanceiroContaReceber, newFinanceiroContaPagarComplete, newFinanceiroContaReceberComplete } from '@/interfaces/financeiro';
import { newFinanceiroParcelaMovimentacao, } from '@/interfaces/financeiroParcelaMovimentacao';
import SearchEntidadeComponent from '@/components/SearchEntidadeComponent.vue';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
import ClickableCard from '@/components/ClickableCard.vue';
import ContaBancariaSelectComponent from '@/components/ContaBancariaSelectComponent.vue';
import FinanceiroBaixarTituloComponent from '@/components/FinanceiroBaixarTituloComponent.vue';
import FinanceiroEstornoTituloComponent from '@/components/FinanceiroEstornoTituloComponent.vue';
import FinanceiroCancelarTituloComponent from '@/components/FinanceiroCancelarTituloComponent.vue';
import UploadFilesComponent from '@/components/UploadFilesComponent.vue';
import { addDays, addMonths, addYears } from 'date-fns';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => !!value,
});
let Create = class Create extends Vue {
    constructor() {
        super(...arguments);
        this.parcelasParaCriarHeaders = [
            { text: 'Data vencimento', value: 'data_vencimento' },
            { text: 'Valor', value: 'valor' },
            { text: '', value: 'actions' },
        ];
        this.editParcelas = false;
        this.parcelasParaCriar = [];
        this.tab = '';
        this.loading = false;
        this.valorPago = 0;
        this.valorMulta = 0;
        this.valorJuros = 0;
        this.id = null;
        this.entityObject = null;
        this.search = '';
        this.selectedItem = {};
        this.repetir = false;
        this.valor_pago = null;
        this.data_pagamento = null;
        this.pago = false;
        this.contaBancariaSelected = null;
        this.selectedFornecedor = null;
        this.entidadeAlunoSelected = null;
        this.selectedParcelaModel = null;
        this.movimentacaoObservacao = null;
        this.dataMovimentacao = null;
        this.valorPagoMovimentacao = 0;
        this.ocorrencia = { id: 1, label: 'Único' };
        this.colaboradorEntidadeSelected = null;
        this.quitarDialog = false;
        this.baixarDialog = false;
        this.isEntidadeChanged = false;
        this.entidadeInDb = {};
        this.dialogEstornoTitulo = false;
        this.dialogEstornoTituloObject = null;
        this.dialogCancelarTitulos = false;
        this.entityFinanceiroParcela = {
            valor_atualizado: 0,
            id: 0,
            colaborador_id: 0,
            aluno_id: 0,
            fornecedor_id: 0,
            vencimento: '',
        };
        this.entidadeSelected = { tipo: null, id: 0, nome: null };
        this.parcela_id = null;
        this.parcelaObject = null;
        this.colaboradorFornecedorSelected = [];
        this.observacaoPagar = '';
        this.comprovantesUploadFiles = [];
        this.isLoading = false;
        this.parcelaHistoricoHeaders = [
            { text: 'Data pagamento', value: 'data_pagamento' },
            { text: 'Valor pago', value: 'valor_pago' },
            { text: 'Tipo', value: 'tipo_movimentacao' },
            { text: 'Observação', value: 'observacao' },
            { text: '', value: 'actions' },
        ];
        this.parcelasHeaders = [
            { text: 'Número da parcela', value: 'parcela_numero' },
            { text: 'Valor', value: 'valor' },
            { text: 'Vencimento', value: 'vencimento' },
            { text: 'Valor restante', value: 'restante' },
        ];
        this.movimentacoesHeaders = [
            { text: 'Observação', value: 'observacao' },
            { text: 'Data Pagamento', value: 'data_pagamento' },
            { text: 'Valor Pago', value: 'valor_pago' },
            { text: 'Ações', value: 'actions' },
        ];
        this.descontarValorSelected = [
            this.descontarValorOptions[0].value,
            this.descontarValorOptions[0],
        ];
    }
    get allowViewCaixa() {
        if (userToolStore.userToolsIndexed.Caixa) {
            return userToolStore.userToolsIndexed.Caixa[0].allow_view;
        }
    }
    get userActionsCancelarTituloAllow() {
        return userActionStore.cancelarTituloAllow;
    }
    get allowViewContaBancaria() {
        if (userToolStore.userToolsIndexed['Contas bancárias']) {
            return userToolStore.userToolsIndexed['Contas bancárias'][0]
                .allow_edit;
        }
    }
    get valorPagoFinal() {
        return this.valorPago + this.valorJuros + this.valorMulta;
    }
    get descontarValorOptions() {
        const options = [
            {
                id: 2,
                value: 'Não descontar',
                description: 'O valor para o pagamento não será descontado',
            },
            {
                id: 1,
                value: 'Da conta bancária',
                description: 'O valor será retirado de uma conta bancaria',
            },
        ];
        // @ts-ignore
        if (this.dashboardCaixa.caixa_id) {
            options.push({
                id: 3,
                value: 'Do caixa atual',
                description: 'O valor será retirado do seu caixa',
            });
        }
        return options;
    }
    get FinanceiroCategoriasDespesas() {
        return _.filter(financeiroCategoriaStore.FinanceiroCategorias, 
        // @ts-ignore
        (item) => item.tipo == 2 && item.classificacao != '2');
    }
    get FinanceiroCategoriasReceber() {
        return _.filter(financeiroCategoriaStore.FinanceiroCategorias, 
        // @ts-ignore
        (item) => item.tipo == 1 && item.classificacao != '1');
    }
    get financeiroParcelas() {
        return financeiroParcelaStore.financeiroParcelas;
    }
    get dashboardCaixa() {
        return dashboardStore.dashboardCaixa;
    }
    get quitarSaldo() {
        if (!this.entityObject) {
            return;
        }
        // @ts-ignore
        if (this.entityObject.financeiro_parcela_status_id === this.getFinanceiroParcelaStatusIdPago()) {
            return 0;
        }
        return this.entityFinanceiroParcela.valor_atualizado - this.valorPago;
    }
    get financeiroParcelaMovimentacoes() {
        return financeiroParcelaMovimentacaoStore.financeiroParcelaMovimentacoes;
    }
    get ocorrenciaOptions() {
        return [
            { id: 1, label: 'Único' },
            { id: 2, label: 'Diariamente' },
            { id: 3, label: 'Semanalmente' },
            { id: 4, label: 'Mensalmente' },
            { id: 5, label: 'Bimestralmente' },
            { id: 6, label: 'Trimestralmente' },
            { id: 7, label: 'Semestralmente' },
            { id: 8, label: 'Anualmente' },
        ];
    }
    get selectedParcela() {
        if (!this.selectedParcelaModel) {
            return false;
        }
        return this.selectedParcelaModel[0];
    }
    get contasBancariasOptions() {
        return contaBancariaStore.contasBancarias;
    }
    onChangedescontarValorSelected(val, oldVal) {
        this.contaBancariaSelected = null;
    }
    async onChangeFinanceiroType(val, oldVal) {
        if (val) {
            this.entityObject = newFinanceiroContaPagar();
        }
        else {
            this.entityObject = newFinanceiroContaReceber();
        }
    }
    async onChangeFinanceiroParcela(val, oldval) {
        this.loading = true;
        if (val) {
            await financeiroParcelaMovimentacaoStore.getFinanceiroParcelaMovimentacoesByParcelaId(val.id);
        }
        this.loading = false;
    }
    onChangeEntidade(item) {
        if (this.loading) {
            return;
        }
        // @ts-ignore
        if (this.entidadeInDb.id && item) {
            // @ts-ignore
            if (this.entidadeInDb.id != item.id) {
                this.isEntidadeChanged = true;
                // @ts-ignore
            }
            else if (this.entidadeInDb.id == item.id) {
                this.isEntidadeChanged = false;
            }
        }
        else {
            this.isEntidadeChanged = true;
        }
    }
    async estornarContaPaga(item) {
        this.dialogEstornoTituloObject = item;
        this.dialogEstornoTitulo = true;
    }
    async estornoOnCancel() {
        this.dialogEstornoTituloObject = null;
        this.dialogEstornoTitulo = false;
    }
    async onConfirmEstorno() {
        this.loading = true;
        this.dialogEstornoTituloObject = null;
        this.dialogEstornoTitulo = false;
        const arr = await financeiroParcelaStore.getFinanceiroParcelas([
            `financeiro_parcela.id = ${this.id}`,
        ]);
        this.entityFinanceiroParcela = arr[0];
        if (arr[0].financeiro_parcela_fornecedor_id) {
            this.entidadeSelected = {
                id: arr[0].financeiro_parcela_fornecedor_id,
                nome: arr[0].financeiro_parcela_fornecedor_nome_fantasia,
                tipo: 'Fornecedor',
            };
        }
        else if (arr[0].financeiro_parcela_colaborador_id) {
            this.entidadeSelected = {
                id: arr[0].financeiro_parcela_colaborador_id,
                nome: arr[0].financeiro_parcela_colaborador_full_name,
                tipo: 'Colaborador',
            };
        }
        else if (arr[0].aluno_id) {
            this.entidadeSelected = {
                id: arr[0].aluno_id,
                nome: arr[0].aluno_nome,
                tipo: 'Aluno',
            };
        }
        this.entidadeInDb = this.entidadeSelected;
        this.entityObject = await financeiroStore.getFinanceiro(parseInt(arr[0].financeiro_id, 0));
        this.entityObject.vencimento = arr[0].vencimento;
        this.entityObject.financeiro_parcela_status_id = arr[0].financeiro_parcela_status_id;
        // @ts-ignore
        const categoriaFinded = _.find(this.financeiroType
            ? this.FinanceiroCategoriasDespesas
            : this.FinanceiroCategoriasReceber, (item) => 
        // @ts-ignore
        item.financeiro_categoria_id === arr[0].financeiro_categoria_id);
        this.entityObject.financeiro_categoria_id = arr[0].financeiro_categoria_id;
        await financeiroParcelaMovimentacaoStore.getFinanceiroParcelaMovimentacoesByParcelaId(this.entityFinanceiroParcela.id);
        this.loading = false;
    }
    async imprimirComprovante() {
        const comprovante = await financeiroParcelaStore.getFinanceiroParcelaComprovante(this.id);
        if (comprovante) {
            const w = window.open();
            w.document.write(comprovante);
        }
    }
    async openHistoricoContaBancaria(contaId) {
        this.$router.push({
            name: 'main-contas-bancarias-edit',
            params: { id: contaId },
        });
    }
    async openHistoricoCaixa(caixaId) {
        this.$router.push({ name: 'main-caixas-edit', params: { id: caixaId } });
    }
    async onCreateMovimentacao() {
        this.loading = true;
        const createdMovimentacao = newFinanceiroParcelaMovimentacao();
        createdMovimentacao.data_pagamento = new Date(this.dataMovimentacao).toISOString();
        createdMovimentacao.company_id = mainStore.userProfile.company.id;
        createdMovimentacao.financeiro_parcela_id =
            this.financeiroParcelas[this.selectedParcela.parcela_numero - 1].id;
        createdMovimentacao.observacao = this.movimentacaoObservacao;
        createdMovimentacao.valor_pago = this.valorPagoMovimentacao;
        createdMovimentacao.financeiro_id = this.id;
        await financeiroParcelaMovimentacaoStore.createFinanceiroParcelaMovimentacao(createdMovimentacao);
        await financeiroParcelaStore.getFinanceiroParcelas([
            `financeiro.id = ${this.id}`,
        ]);
        await financeiroParcelaMovimentacaoStore.getFinanceiroParcelaMovimentacoesByParcelaId(this.selectedParcela.id);
        this.loading = false;
    }
    async onRedoMovimentacao(item) {
        this.$swal({
            title: 'Confirma a reversão da movimentação?',
            text: 'Será inserido um novo registro de valor oposto ao selecionado!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.loading = true;
                const createdMovimentacao = {
                    ...item,
                };
                createdMovimentacao.valor_pago = -createdMovimentacao.valor_pago;
                createdMovimentacao.observacao = 'Movimentação revertida';
                createdMovimentacao.data_pagamento = new Date().toISOString();
                createdMovimentacao.financeiro_id = this.id;
                await financeiroParcelaMovimentacaoStore.createFinanceiroParcelaMovimentacao(createdMovimentacao);
                await financeiroParcelaStore.getFinanceiroParcelas([
                    `financeiro.id = ${this.id}`,
                ]);
                await financeiroParcelaMovimentacaoStore.getFinanceiroParcelaMovimentacoesByParcelaId(this.selectedParcela.id);
                this.loading = false;
            }
        });
    }
    async onDeleteMovimentacao(item) {
        this.$swal({
            title: 'Confirma a exclusão da movimentação?',
            text: 'Após exclusão a movimentação não pode ser recuperada!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.loading = true;
                await financeiroParcelaMovimentacaoStore.deleteFinanceiroParcelaMovimentacao(item.id);
                await financeiroParcelaStore.getFinanceiroParcelas([
                    `financeiro.id = ${this.id}`,
                ]);
                await financeiroParcelaMovimentacaoStore.getFinanceiroParcelaMovimentacoesByParcelaId(this.selectedParcela.id);
                this.loading = false;
            }
        });
    }
    async onCancel() {
        this.baixarDialog = false;
        this.dialogCancelarTitulos = false;
        this.loading = true;
        const arr = await financeiroParcelaStore.getFinanceiroParcelas([
            `financeiro_parcela.id = ${this.id}`,
        ]);
        this.entityFinanceiroParcela = arr[0];
        if (arr[0].financeiro_parcela_fornecedor_id) {
            this.entidadeSelected = {
                id: arr[0].financeiro_parcela_fornecedor_id,
                nome: arr[0].financeiro_parcela_fornecedor_nome_fantasia,
                tipo: 'Fornecedor',
            };
        }
        else if (arr[0].financeiro_parcela_colaborador_id) {
            this.entidadeSelected = {
                id: arr[0].financeiro_parcela_colaborador_id,
                nome: arr[0].financeiro_parcela_colaborador_full_name,
                tipo: 'Colaborador',
            };
        }
        else if (arr[0].aluno_id) {
            this.entidadeSelected = {
                id: arr[0].aluno_id,
                nome: arr[0].aluno_nome,
                tipo: 'Aluno',
            };
        }
        this.entidadeInDb = this.entidadeSelected;
        this.entityObject = await financeiroStore.getFinanceiro(parseInt(arr[0].financeiro_id, 0));
        this.entityObject.vencimento = arr[0].vencimento;
        this.entityObject.financeiro_parcela_status_id = arr[0].financeiro_parcela_status_id;
        await financeiroParcelaMovimentacaoStore.getFinanceiroParcelaMovimentacoesByParcelaId(this.entityFinanceiroParcela.id);
        this.loading = false;
    }
    quitarVoltar() {
        this.valorPago = 0;
        this.contaBancariaSelected = {};
        this.quitarDialog = false;
    }
    async onChangeOcorrencia(val) {
        this.parcelasParaCriar = [];
    }
    async onChangeParcelas(val) {
        this.parcelasParaCriar = [];
    }
    async confirmaParcelasCriar() {
        let possuiErros = false;
        _.forEach(this.parcelasParaCriar, (el) => {
            if (!el.data_vencimento || !el.valor) {
                possuiErros = true;
            }
        });
        if (possuiErros) {
            mainStore.addErrorToasted({
                // @ts-ignore
                toasted: this.$toasted,
                errors: ['Informações inválidas, voltando para parcelas padrão'],
            });
            await this.gerarParcelas();
        }
        else {
            this.editParcelas = false;
        }
    }
    async gerarParcelas() {
        const isValid = await this.$refs.observer.validate();
        if (!isValid) {
            return;
        }
        this.parcelasParaCriar = [];
        let periodicidadeId = this.ocorrencia.id;
        for (let i = 0; i < this.entityObject.numero_parcelas; i++) {
            let vencimento = new Date(this.entityFinanceiroParcela.vencimento);
            switch (periodicidadeId) {
                case 1: // Único
                    break;
                case 2: // Diariamente
                    vencimento = addDays(vencimento, i);
                    break;
                case 3: // Semanalmente
                    vencimento = addDays(vencimento, 7 * i);
                    break;
                case 4: // Mensalmente
                    vencimento = addMonths(vencimento, i);
                    break;
                case 5: // Bimestralmente
                    vencimento = addMonths(vencimento, 2 * i);
                    break;
                case 6: // Trimestralmente
                    vencimento = addMonths(vencimento, 3 * i);
                    break;
                case 7: // Semestralmente
                    vencimento = addMonths(vencimento, 6 * i);
                    break;
                case 8: // Anualmente
                    vencimento = addYears(vencimento, i);
                    break;
                default:
                    throw new Error('Periodicidade inválida');
            }
            const vencimento_formatted = vencimento.toISOString().split('T')[0];
            this.parcelasParaCriar.push(
            // @ts-ignore
            { data_vencimento: vencimento_formatted, valor: this.entityObject.valor_integral });
        }
    }
    async quitar() {
        this.valorPago = this.entityFinanceiroParcela.valor_atualizado;
        this.descontarValorSelected = [
            this.descontarValorOptions[0].value,
            this.descontarValorOptions[0],
        ];
        this.quitarDialog = true;
    }
    async quitarPagar() {
        if (this.loading) {
            return;
        }
        this.loading = true;
        await dashboardStore.getDashboardCaixa();
        const obj = {
            financeiro_parcela_id: this.entityFinanceiroParcela.id,
            valor_pago: this.valorPago + this.valorJuros + this.valorMulta,
            valor_juros_pago: this.valorJuros,
            valor_multa_pago: this.valorMulta,
            entidade: this.entidadeSelected,
            conta_bancaria: null,
            caixa_id: null,
            observacao: this.observacaoPagar,
            anexos: null
        };
        // @ts-ignore
        if (this.descontarValorSelected[1].id == 1) {
            obj.conta_bancaria = this.contaBancariaSelected;
            // @ts-ignore
        }
        else if (this.descontarValorSelected[1].id == 3) {
            // @ts-ignore
            obj.caixa_id = this.dashboardCaixa.caixa_id;
        }
        if (this.comprovantesUploadFiles) {
            obj.anexos = this.comprovantesUploadFiles;
        }
        this.loading = false;
        await financeiroParcelaStore.quitarParcelaContaAPagar(obj);
        const arr = await financeiroParcelaStore.getFinanceiroParcelas([
            `financeiro_parcela.id = ${this.id}`,
        ]);
        this.entityFinanceiroParcela = arr[0];
        if (arr[0].financeiro_parcela_fornecedor_id) {
            this.entidadeSelected = {
                id: arr[0].financeiro_parcela_fornecedor_id,
                nome: arr[0].financeiro_parcela_fornecedor_nome_fantasia,
                tipo: 'Fornecedor',
            };
        }
        else if (arr[0].financeiro_parcela_colaborador_id) {
            this.entidadeSelected = {
                id: arr[0].financeiro_parcela_colaborador_id,
                nome: arr[0].financeiro_parcela_colaborador_full_name,
                tipo: 'Colaborador',
            };
        }
        else if (arr[0].aluno_id) {
            this.entidadeSelected = {
                id: arr[0].aluno_id,
                nome: arr[0].aluno_nome,
                tipo: 'Aluno',
            };
        }
        this.entidadeInDb = this.entidadeSelected;
        this.entityObject = await financeiroStore.getFinanceiro(parseInt(arr[0].financeiro_id, 0));
        this.entityObject.vencimento = arr[0].vencimento;
        this.entityObject.financeiro_parcela_status_id = arr[0].financeiro_parcela_status_id;
        // @ts-ignore
        const categoriaFinded = _.find(this.financeiroType
            ? this.FinanceiroCategoriasDespesas
            : this.FinanceiroCategoriasReceber, (item) => 
        // @ts-ignore
        item.financeiro_categoria_id === arr[0].financeiro_categoria_id);
        this.entityObject.financeiro_categoria_id = arr[0].financeiro_categoria_id;
        await financeiroParcelaMovimentacaoStore.getFinanceiroParcelaMovimentacoesByParcelaId(this.entityFinanceiroParcela.id);
        this.loading = false;
        this.quitarDialog = false;
    }
    async redirect() {
        this.$router.push('/main/FinanceiroContasapagar/all');
    }
    async submit() {
        this.isLoading = true;
        const isValid = await this.$refs.observer.validate();
        if (!isValid) {
            // @ts-ignore
            this.$refs.saveButton.changeLoadingStatus(false);
            return;
        }
        if (this.id) {
            let cancelSubmit = false;
            if (this.isEntidadeChanged) {
                await this.$swal({
                    title: 'Confirma a alteração de entidade?',
                    text: 'Após a alteração da entidade, outras parcelas deste financeiro serão atualizadas para a nova entidade',
                    icon: 'warning',
                    showCancelButton: true,
                    cancelButtonText: 'Voltar',
                    confirmButtonText: 'Sim, eu confirmo!',
                }).then(async (result) => {
                    if (!result.isConfirmed) {
                        cancelSubmit = true;
                    }
                });
            }
            if (cancelSubmit) {
                // @ts-ignore
                this.isLoading = false;
                return;
            }
            const toUpdateFinanceiroParcela = { ...this.entityFinanceiroParcela };
            const toUpdateFinanceiro = { ...this.entityObject };
            toUpdateFinanceiro.aluno_id = null;
            toUpdateFinanceiro.fornecedor_id = null;
            toUpdateFinanceiro.colaborador_id = null;
            toUpdateFinanceiroParcela.aluno_id = null;
            toUpdateFinanceiroParcela.fornecedor_id = null;
            toUpdateFinanceiroParcela.colaborador_id = null;
            if (this.entidadeSelected) {
                if (this.entidadeSelected.tipo === 'Aluno') {
                    toUpdateFinanceiro.aluno_id = this.entidadeSelected.id;
                    toUpdateFinanceiroParcela.aluno_id = this.entidadeSelected.id;
                }
                else if (this.entidadeSelected.tipo === 'Fornecedor') {
                    toUpdateFinanceiro.fornecedor_id = this.entidadeSelected.id;
                    toUpdateFinanceiroParcela.fornecedor_id = this.entidadeSelected.id;
                }
                else if (this.entidadeSelected.tipo === 'Colaborador') {
                    toUpdateFinanceiro.colaborador_id = this.entidadeSelected.id;
                    toUpdateFinanceiroParcela.colaborador_id = this.entidadeSelected.id;
                }
            }
            await financeiroStore.updateFinanceiro(toUpdateFinanceiro);
            await financeiroParcelaStore.updateFinanceiroParcela(
            // @ts-ignore
            toUpdateFinanceiroParcela);
        }
        else {
            let aluno_id = null;
            let fornecedor_id = null;
            let colaborador_id = null;
            if (this.entidadeSelected) {
                if (this.entidadeSelected.tipo === 'Aluno') {
                    aluno_id = this.entidadeSelected.id;
                }
                else if (this.entidadeSelected.tipo === 'Fornecedor') {
                    fornecedor_id = this.entidadeSelected.id;
                }
                else if (this.entidadeSelected.tipo === 'Colaborador') {
                    colaborador_id = this.entidadeSelected.id;
                }
            }
            const valor_integral = this.entityObject.valor_integral;
            const financeiroCreateComplete = {
                tipo: this.entityObject.tipo,
                data_emissao: this.entityObject.data_emissao,
                data_vencimento: this.entityFinanceiroParcela.vencimento,
                vencimento_inicial: this.entityFinanceiroParcela.vencimento,
                data_pagamento: this.entityObject.data_pagamento,
                numero_documento: this.entityObject.numero_documento,
                financeiro_categoria_id: this.entityObject.financeiro_categoria_id,
                conta_bancaria_id: this.entityObject.conta_bancaria_id,
                observacao: this.entityObject.observacao,
                valor_integral,
                total_pago: this.entityObject.total_pago,
                documento_fiscal_id: this.entityObject.documento_fiscal_id,
                valor_pago: this.entityObject.valor_pago,
                aluno_id,
                fornecedor_id,
                colaborador_id,
                company_id: this.entityObject.company_id,
                unidade_id: this.entityObject.unidade_id,
                numero_vezes: this.entityObject.numero_parcelas,
                numero_parcelas: 1,
                periodicidade_id: this.ocorrencia.id,
                status_id: this.entityObject.status_id,
                parcelas_custom: this.parcelasParaCriar,
            };
            await financeiroStore.createFinanceiroComplete(financeiroCreateComplete);
        }
        await financeiroParcelaStore.setFinanceiroParcelas([]);
        // @ts-ignore
        this.isLoading = false;
        this.$router.back();
    }
    async mounted() {
        this.loading = true;
        await dashboardStore.getDashboardCaixa();
        await contaBancariaStore.getContasBancarias(true);
        if (this.$router.currentRoute.params.id) {
            this.id = this.$router.currentRoute.params.id;
            const arr = await financeiroParcelaStore.getFinanceiroParcelas([
                `financeiro_parcela.id = ${this.id}`,
            ]);
            this.entityFinanceiroParcela = arr[0];
            if (arr[0].financeiro_parcela_fornecedor_id) {
                this.entidadeSelected = {
                    id: arr[0].financeiro_parcela_fornecedor_id,
                    nome: arr[0].financeiro_parcela_fornecedor_nome_fantasia,
                    tipo: 'Fornecedor',
                };
            }
            else if (arr[0].financeiro_parcela_colaborador_id) {
                this.entidadeSelected = {
                    id: arr[0].financeiro_parcela_colaborador_id,
                    nome: arr[0].financeiro_parcela_colaborador_full_name,
                    tipo: 'Colaborador',
                };
            }
            else if (arr[0].aluno_id) {
                this.entidadeSelected = {
                    id: arr[0].aluno_id,
                    nome: arr[0].aluno_nome,
                    tipo: 'Aluno',
                };
            }
            this.entidadeInDb = this.entidadeSelected;
            this.entityObject = await financeiroStore.getFinanceiro(parseInt(arr[0].financeiro_id, 0));
            this.entityObject.vencimento = arr[0].vencimento;
            this.entityObject.financeiro_parcela_status_id = arr[0].financeiro_parcela_status_id;
            // @ts-ignore
            const categoriaFinded = _.find(this.financeiroType
                ? this.FinanceiroCategoriasDespesas
                : this.FinanceiroCategoriasReceber, (item) => 
            // @ts-ignore
            item.financeiro_categoria_id === arr[0].financeiro_categoria_id);
            this.entityObject.financeiro_categoria_id = arr[0].financeiro_categoria_id;
        }
        else {
            if (this.financeiroType) {
                this.entityObject = newFinanceiroContaPagarComplete();
            }
            else {
                this.entityObject = newFinanceiroContaReceberComplete();
            }
            this.entityObject.financeiro_parcela_status_id = 1;
        }
        this.entityObject.company_id = mainStore.userProfile.company.id;
        this.entityObject.numero_documento = '';
        await financeiroParcelaMovimentacaoStore.getFinanceiroParcelaMovimentacoesByParcelaId(this.entityFinanceiroParcela.id);
        this.loading = false;
    }
    get entityObjectDataVencimento() {
        if (this.entityObject.data_vencimento) {
            const val = this.entityObject.data_vencimento.split('T');
            const date = val[0].split('-');
            return `${date[2]}/${date[1]}/${date[0]} ${val[1] ? val[1] : ''}`;
        }
    }
    get entityObjectDataEmissao() {
        if (this.entityObject.data_emissao) {
            const val = this.entityObject.data_emissao.split('T');
            const date = val[0].split('-');
            return `${date[2]}/${date[1]}/${date[0]} ${val[1] ? val[1] : ''}`;
        }
    }
};
__decorate([
    Prop(Boolean)
], Create.prototype, "financeiroType", void 0);
__decorate([
    Watch('descontarValorSelected')
], Create.prototype, "onChangedescontarValorSelected", null);
__decorate([
    Watch('financeiroType')
], Create.prototype, "onChangeFinanceiroType", null);
__decorate([
    Watch('selectedParcela', { deep: true })
], Create.prototype, "onChangeFinanceiroParcela", null);
__decorate([
    Watch('entidadeSelected')
], Create.prototype, "onChangeEntidade", null);
__decorate([
    Watch('ocorrencia', { deep: true })
], Create.prototype, "onChangeOcorrencia", null);
__decorate([
    Watch('entityObject.numero_parcelas', { deep: true })
], Create.prototype, "onChangeParcelas", null);
Create = __decorate([
    Component({
        components: {
            ClickableCard,
            ContaBancariaSelectComponent,
            DateFieldComponent,
            SaveButton,
            SearchEntidadeComponent,
            FinanceiroBaixarTituloComponent,
            FinanceiroEstornoTituloComponent,
            FinanceiroCancelarTituloComponent,
            ValidationObserver,
            ValidationProvider,
            UploadFilesComponent
        },
    })
], Create);
export default Create;
