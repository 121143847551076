import { __decorate } from "tslib";
import { contratoStore, mainStore, metaStore } from '@/store';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ProgressoMetasComponent from '@/components/ProgressoMetasComponent.vue';
let DashboardMetasAdmComponent = class DashboardMetasAdmComponent extends Vue {
    constructor() {
        super(...arguments);
        this.matriculas = [];
        this.performance = [];
        this.startDateCurrent = '';
        this.endDateCurrent = '';
        this.steps = [];
        this.step = 1;
        this.mensalidades = 0;
        this.inicioDesafio = 0;
        this.matriculaData = {
            current: 0,
            previous: 0,
            difference: 0,
            bestConsultor: '',
            bestConsultorMatriculas: 0,
        };
        this.visitaData = {
            current: 0,
            previous: 0,
            difference: 0,
            bestCDA: '',
            bestCDAVisitas: 0,
        };
    }
    stepClass(index, percentageClass) {
        if (index === 0 || index === 1) {
            return 'step-color-blue';
        }
        return percentageClass === 'text-success'
            ? 'step-color-green'
            : 'step-color-red';
    }
    get metaDescription() {
        return `Meta de&nbsp;<strong>${this.metas[0]?.qtd_mensalidades ||
            0} mensalidades até o dia ${this.endDateCurrent}</strong>.`;
    }
    get progressPercentage() {
        const totalProgress = this.metas[0]?.qtd_mensalidades || 0;
        const progressoAtual = this.mensalidades || 0;
        if (totalProgress === 0)
            return 0;
        return Math.min((progressoAtual / totalProgress) * 100, 100);
    }
    get currentMonthTitle() {
        const monthIndex = parseInt(this.selectedMonth.split('-')[1], 10) - 1;
        const months = [
            'Janeiro',
            'Fevereiro',
            'Março',
            'Abril',
            'Maio',
            'Junho',
            'Julho',
            'Agosto',
            'Setembro',
            'Outubro',
            'Novembro',
            'Dezembro',
        ];
        return months[monthIndex] || 'Mês inválido';
    }
    get formattedDifferenceMatricula() {
        const difference = this.matriculaData.difference;
        return difference > 0
            ? `+${difference.toFixed(2)}% em relação ao mês anterior`
            : `${difference.toFixed(2)}% em relação ao mês anterior`;
    }
    get formattedDifferenceVisita() {
        const difference = this.visitaData.difference;
        return difference > 0
            ? `+${difference.toFixed(2)}% em relação ao mês anterior`
            : `${difference.toFixed(2)}% em relação ao mês anterior`;
    }
    async getQtdMatricula() {
        if (!this.selectedMonth)
            return {
                current: 0,
                previous: 0,
                difference: 0,
                bestConsultor: '',
                bestConsultorMatriculas: 0,
            };
        const [year, month] = this.selectedMonth.split('-').map(Number);
        const startDateCurrent = new Date(year, month - 1, 1);
        const endDateCurrent = new Date(year, month, 1);
        this.startDateCurrent = `${startDateCurrent
            .getDate()
            .toString()
            .padStart(2, '0')}/${(startDateCurrent.getMonth() + 1)
            .toString()
            .padStart(2, '0')}`;
        this.endDateCurrent = `${endDateCurrent
            .getDate()
            .toString()
            .padStart(2, '0')}/${(endDateCurrent.getMonth() + 1)
            .toString()
            .padStart(2, '0')}`;
        const startDatePrevious = new Date(year, month - 2, 1);
        const endDatePrevious = new Date(year, month - 1, 1);
        const startDateCurrentFormatted = startDateCurrent
            .toISOString()
            .split('T')[0];
        const endDateCurrentFormatted = endDateCurrent.toISOString().split('T')[0];
        const startDatePreviousFormatted = startDatePrevious
            .toISOString()
            .split('T')[0];
        const endDatePreviousFormatted = endDatePrevious
            .toISOString()
            .split('T')[0];
        const filtrosCurrent = [
            `contrato.vigencia_inicio BETWEEN '${startDateCurrentFormatted}' AND '${endDateCurrentFormatted}'`,
            'contrato.matricula_tipo_id = 1',
        ];
        const filtrosPrevious = [
            `contrato.vigencia_inicio BETWEEN '${startDatePreviousFormatted}' AND '${endDatePreviousFormatted}'`,
            'contrato.matricula_tipo_id = 1',
        ];
        const currentMatriculas = await contratoStore.getContratos(filtrosCurrent);
        const previousMatriculas = await contratoStore.getContratos(filtrosPrevious);
        const currentCount = currentMatriculas.length;
        const previousCount = previousMatriculas.length;
        let difference = 0;
        if (previousCount > 0) {
            difference = ((currentCount - previousCount) / previousCount) * 100;
        }
        const consultorCounts = {};
        currentMatriculas.forEach((contrato) => {
            const consultor = contrato.consultor_nome || 'Desconhecido';
            consultorCounts[consultor] = (consultorCounts[consultor] || 0) + 1;
        });
        const [bestConsultor, bestConsultorMatriculas] = Object.entries(consultorCounts).reduce((prev, curr) => (curr[1] > prev[1] ? curr : prev), ['', 0]);
        return {
            current: currentCount,
            previous: previousCount,
            difference: parseFloat(difference.toFixed(2)),
            bestConsultor,
            bestConsultorMatriculas,
        };
    }
    async getQtdVisitas() {
        if (!this.selectedMonth)
            return {
                current: 0,
                previous: 0,
                difference: 0,
                bestCDA: '',
                bestCDAVisitas: 0,
            };
        const [year, month] = this.selectedMonth.split('-').map(Number);
        const startDateCurrent = new Date(year, month - 1, 1);
        const endDateCurrent = new Date(year, month, 1);
        const startDatePrevious = new Date(year, month - 2, 1);
        const endDatePrevious = new Date(year, month - 1, 1);
        const startDateCurrentFormatted = startDateCurrent
            .toISOString()
            .split('T')[0];
        const endDateCurrentFormatted = endDateCurrent.toISOString().split('T')[0];
        const startDatePreviousFormatted = startDatePrevious
            .toISOString()
            .split('T')[0];
        const endDatePreviousFormatted = endDatePrevious
            .toISOString()
            .split('T')[0];
        const currentVisitasRaw = await metaStore.getMetasVisitas({
            data_inicial: startDateCurrentFormatted,
            data_final: endDateCurrentFormatted,
        });
        const previousVisitasRaw = await metaStore.getMetasVisitas({
            data_inicial: startDatePreviousFormatted,
            data_final: endDatePreviousFormatted,
        });
        const currentVisitas = Object.values(currentVisitasRaw);
        const previousVisitas = Object.values(previousVisitasRaw);
        const currentCount = currentVisitas.length;
        const previousCount = previousVisitas.length;
        let difference = 0;
        if (previousCount > 0) {
            difference = ((currentCount - previousCount) / previousCount) * 100;
        }
        const cdaCounts = {};
        currentVisitas.forEach((visita) => {
            const cda = visita?.full_name?.trim() || 'Desconhecido';
            cdaCounts[cda] = (cdaCounts[cda] || 0) + 1;
        });
        const [bestCDA, bestCDAVisitas] = Object.entries(cdaCounts).reduce((prev, curr) => (curr[1] > prev[1] ? curr : prev), ['', 0]);
        return {
            current: currentCount,
            previous: previousCount,
            difference: parseFloat(difference.toFixed(2)),
            bestCDA,
            bestCDAVisitas,
        };
    }
    async populatePerformanceSteps() {
        const [year, month] = this.selectedMonth.split('-').map(Number);
        const startDateCurrent = new Date(year, month - 1, 1);
        const endDateCurrent = new Date(year, month, 1);
        const startDateCurrentFormatted = startDateCurrent
            .toISOString()
            .split('T')[0];
        const endDateCurrentFormatted = endDateCurrent.toISOString().split('T')[0];
        const startDatePrevious = new Date(year, month - 2, 1);
        const endDatePrevious = new Date(year, month - 1, 1);
        const startDatePreviousFormatted = startDatePrevious
            .toISOString()
            .split('T')[0];
        const endDatePreviousFormatted = endDatePrevious
            .toISOString()
            .split('T')[0];
        const currentPerformance = await metaStore.getMetasPerformance({
            data_inicial: startDateCurrentFormatted,
            data_final: endDateCurrentFormatted,
        });
        const previousPerformance = await metaStore.getMetasPerformance({
            data_inicial: startDatePreviousFormatted,
            data_final: endDatePreviousFormatted,
        });
        const categories = [
            { key: 'cupons', title: 'Quantidade de cupons' },
            { key: 'contatos', title: 'Total de contatos' },
            { key: 'sucesso_contato', title: 'Sucesso no contato' },
            { key: 'agendamentos', title: 'Agendamentos' },
            { key: 'visitas', title: 'Visitas' },
            { key: 'matriculas', title: 'Matrícula' },
            { key: 'mensalidade', title: 'Mensalidade' },
        ];
        this.steps = categories.map(({ key, title }) => {
            const current = currentPerformance[key] || 0;
            const previous = previousPerformance[key] || 0;
            const difference = previous > 0 ? ((current - previous) / previous) * 100 : 0;
            return {
                title,
                value: current.toLocaleString('pt-BR'),
                percentageClass: difference >= 0 ? 'text-success' : 'text-danger',
                percentageIcon: difference >= 0 ? 'mdi-arrow-up' : 'mdi-arrow-down',
                percentageText: difference > 0
                    ? `+${difference.toFixed(2)}%`
                    : `${difference.toFixed(2)}%`,
            };
        });
    }
    get matriculasRestantesPorDia() {
        const today = new Date();
        const totalDiasNoMes = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
        const diasRestantes = totalDiasNoMes - today.getDate();
        if (diasRestantes <= 0)
            return 0;
        const progressoAtual = this.mensalidades;
        const metaTotal = this.inicioDesafio + this.metas[0].qtd_mensalidades;
        const restanteParaMeta = Math.max(metaTotal - progressoAtual, 0);
        return Math.ceil(restanteParaMeta / diasRestantes);
    }
    async mensalidadesMensal() {
        const [year, month] = this.selectedMonth.split('-').map(Number);
        const startDateCurrent = new Date(year, month - 1, 1);
        const endDateCurrent = new Date(year, month, 1);
        const startDateCurrentFormatted = startDateCurrent
            .toISOString()
            .split('T')[0];
        const endDateCurrentFormatted = endDateCurrent.toISOString().split('T')[0];
        const currentMatricula = await metaStore.getMetasMensalidade({
            data_inicial: startDateCurrentFormatted,
            data_final: endDateCurrentFormatted,
        });
        this.mensalidades = currentMatricula[0].total;
    }
    async onSelectedMonthChange() {
        this.matriculaData = {
            current: 0,
            previous: 0,
            difference: 0,
            bestConsultor: '',
            bestConsultorMatriculas: 0,
        };
        this.visitaData = {
            current: 0,
            previous: 0,
            difference: 0,
            bestCDA: '',
            bestCDAVisitas: 0,
        };
        this.matriculaData = await this.getQtdMatricula();
        this.visitaData = await this.getQtdVisitas();
        await this.populatePerformanceSteps();
        await this.mensalidadesMensal();
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    async mounted() {
        this.matriculaData = await this.getQtdMatricula();
        this.visitaData = await this.getQtdVisitas();
        await this.populatePerformanceSteps();
        await this.mensalidadesMensal();
    }
};
__decorate([
    Prop({ type: Array, default: () => [] })
], DashboardMetasAdmComponent.prototype, "metas", void 0);
__decorate([
    Prop({ type: String, default: '' })
], DashboardMetasAdmComponent.prototype, "selectedMonth", void 0);
__decorate([
    Watch('selectedMonth')
], DashboardMetasAdmComponent.prototype, "onSelectedMonthChange", null);
DashboardMetasAdmComponent = __decorate([
    Component({
        components: {
            ProgressoMetasComponent,
        },
    })
], DashboardMetasAdmComponent);
export default DashboardMetasAdmComponent;
