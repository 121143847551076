import { __decorate } from "tslib";
import _ from 'lodash';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { atividadeStore, companyConfiguracaoStore, livroStore, mainStore, reposicaoStore, turmaAlunoAtividadeStore, turmaStore, userActionStore, } from '@/store';
import SaveButton from '@/components/SaveButton.vue';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
import { newReposicao } from '@/interfaces/reposicao';
import ClickableCard from '@/components/ClickableCard.vue';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
import EntidadeLinkComponent from '@/components/EntidadeLinkComponent.vue';
import LogShowComponent from '@/components/LogShowComponent.vue';
import NotaGestaoButton from './NotaGestaoButton.vue';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => !!value,
});
let FichaFrequenciaComponent = class FichaFrequenciaComponent extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            {
                text: 'Data',
                sortable: false,
                value: 'data_atividade',
                align: 'left',
                filterable: true,
            },
            {
                text: 'Aula Prevista pelo Cronograma',
                sortable: false,
                value: 'atividade_prevista',
                align: 'left',
                filterable: true,
            },
            {
                text: 'Aula Planejada para turma',
                sortable: false,
                value: 'atividade',
                align: 'left',
                filterable: true,
            },
            {
                sortable: false,
                text: '',
                value: 'actions',
            },
        ];
        this.id = null;
        this.turmaAtividadeItems = [];
        this.entityObject = null;
        this.selectedFichaFrequenciaAlunos = [];
        this.requisicaoReforcoObservacao = '';
        this.observacaoAulaExtra = '';
        this.selectedReforcoLivro = {};
        this.selectedReforcoAtividade = null;
        this.selectedReforcoMotivo = null;
        this.atividadesOptions = [];
        this.dialog = false;
        this.fichaNotaHomeworkItems = [];
        this.fichaNotaEscritaItems = [];
        this.fichaNotaAudicaoItems = [];
        this.fichaNotaLeituraItems = [];
        this.fichaNotaPreparacaoItems = [];
        this.fichaNotaParticipacaoItems = [];
        this.rodandoMounted = false;
        this.loadingImprimirFichaFrequencia = false;
        this.fichaFrequenciaEditOptions = [
            'Presente',
            'Falta',
            'Reposição agendada',
            'Reposição concluída',
            'Não irá repor',
            'N/D',
        ];
        this.fichaFrequenciaReposicoesEditOptions = [
            'Falta',
            'Reposição concluída',
        ];
        this.fichaNotaFalaItems = [];
        this.fichaFrequenciaAlunosHeaders = [
            {
                text: '',
                sortable: false,
                value: 'idx',
                align: 'left',
                filterable: true,
            },
            {
                text: 'Aluno',
                sortable: false,
                value: 'aluno_nome',
                align: 'left',
                filterable: true,
            },
            {
                text: 'Status',
                sortable: false,
                value: 'status_do_aluno_na_turma_id',
                align: 'left',
                filterable: true,
            },
        ];
        this.fichaFrequenciaHeaders = [];
        this.fichaNotaAtividadeHeader = [];
        this.items = [];
        this.curso = {};
        this.livro = {};
        this.fichaFrequenciaItems = [];
        this.fichaDeNotasMenus = {};
        this.fichaFrequenciaReposicoes = {};
        this.exibirSomenteVigente = false;
        this.selectDataFichaFrequencia = null;
        this.loadingTurma = false;
        this.loadingVersoFichaFrequencia = false;
    }
    get permitirAlteracaoDaFichaDeFrequenciaDeAlunoNaoVigente() {
        return userActionStore.permitirAlteracaoDaFichaDeFrequenciaDeAlunoNaoVigente;
    }
    get showTabela() {
        const item = this.dadosTurma;
        const filterData = {};
        let select = this.selectDataFichaFrequencia;
        if (Object.values(select).length > 0) {
            select = select.turma_atividade_id;
        }
        if (item) {
            _.forIn(item, (v, k) => {
                if (!['reposicoes', 'gestao'].includes(k) &&
                    Object.values(v).length > 0) {
                    filterData[k] = v.filter((i) => {
                        if (i.turma_atividade_id) {
                            return i.turma_atividade_id == select;
                        }
                    });
                }
            });
        }
        return filterData;
    }
    formatName(name) {
        const names = name.toLowerCase().split(' ');
        const formattedNames = names.map((x) => {
            return x.charAt(0).toUpperCase() + x.slice(1);
        });
        return formattedNames.join(' ');
    }
    get dadosTurma() {
        return turmaStore.turmaFicha;
    }
    get itemsFiltered() {
        if (this.exibirSomenteVigente) {
            return this.items.filter((item) => item.status_do_aluno_na_turma_id === 1);
        }
        return this.items;
    }
    get livrosVigentes() {
        return livroStore.livrosVigentes;
    }
    get livros() {
        return livroStore.livros;
    }
    get fichaFrequenciaTableAlunos() {
        if (!this.items) {
            return [];
        }
        return this.items.map((item, idx) => {
            const index = idx + 1;
            return 'aluno_' + index;
        });
    }
    get fichaDeNotasTableAlunos() {
        if (!this.items) {
            return [];
        }
        return this.items.map((item, idx) => {
            const index = idx + 1;
            return 'aluno_' + index;
        });
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    validateNota(value) {
        return (value >= 0 && value <= 10) || 'Nota inválida';
    }
    async fichaFrequenciaEditOptionsClick(item, status, aluno) {
        let id = item[aluno];
        id = id.split('-');
        const oldValue = item[aluno];
        const obj = {
            id: id[4],
            atividade_presenca: null,
            reposicao_agendada: null,
            reposicao_concluida: null,
            reposicao_nao_repor: null,
            atividade_data_executada: null,
        };
        switch (status) {
            case 'Presente':
                obj.atividade_presenca = true;
                item[aluno] = 't-f-f-f-' + id[4];
                break;
            case 'Falta':
                obj.atividade_presenca = false;
                item[aluno] = 'f-f-f-f-' + id[4];
                break;
            case 'Reposição agendada':
                obj.reposicao_agendada = true;
                item[aluno] = 'null-f-f-t-' + id[4];
                break;
            case 'Reposição concluída':
                obj.reposicao_concluida = true;
                item[aluno] = 'null-t-f-f-' + id[4];
                break;
            case 'Não irá repor':
                obj.reposicao_nao_repor = true;
                item[aluno] = 'null-f-t-f-' + id[4];
                break;
            case 'N/D':
                item[aluno] = 'null-f-f-f-' + id[4];
                break;
        }
        if (!(await turmaAlunoAtividadeStore.turmaAlunoAtividadeUpdate(obj))) {
            item[aluno] = oldValue;
        }
    }
    getEdicaoDesabilitada(items, aluno) {
        const isAlunoVigente = items[+aluno.split('_')[1] - 1].status_do_aluno_na_turma_id == 1;
        // tem permissao para editar inpendente o status
        if (this.permitirAlteracaoDaFichaDeFrequenciaDeAlunoNaoVigente) {
            return false;
        }
        // se esta vigente pode alterar
        if (isAlunoVigente) {
            return false;
        }
        else {
            return true;
        }
    }
    async fichaFrequenciaReposicoesEditOptionsClick(item, status) {
        let id = item.dados;
        id = id.split('-');
        const oldValue = item.dados;
        const obj = {
            id: id[4],
            atividade_presenca: null,
            reposicao_agendada: null,
            reposicao_concluida: null,
            reposicao_nao_repor: null,
            atividade_data_executada: null,
        };
        switch (status) {
            case 'Falta':
                obj.atividade_presenca = false;
                item.dados = 'f-f-f-f-' + id[4];
                break;
            case 'Reposição concluída':
                obj.reposicao_concluida = true;
                item.dados = 'null-t-f-f-' + id[4];
                break;
        }
        if (!(await turmaAlunoAtividadeStore.turmaAlunoAtividadeUpdate(obj))) {
            item.dados = oldValue;
        }
    }
    async fichaDeNotasEditOptionsClick(item, nota, aluno, tipo) {
        if (nota && (nota === '' || nota.substr(0, 1) === '-')) {
            this.fichaDeNotasMenus[`${item.turma_atividade_id}_${aluno}_${tipo}`] =
                false;
            return;
        }
        if (parseFloat(nota) < 0 || parseFloat(nota) > 10) {
            this.fichaDeNotasMenus[`${item.turma_atividade_id}_${aluno}_${tipo}`] =
                false;
            return;
        }
        const id = item[aluno].split('-')[1];
        const oldValue = item[aluno].split('-')[0];
        const obj = {
            id,
        };
        switch (tipo) {
            case 'homework':
                // @ts-ignore
                obj.atividade_nota = nota;
                item[aluno] = (nota !== null ? nota : '') + '-' + id;
                break;
            case 'fala':
                // @ts-ignore
                obj.atividade_nota_fala = nota;
                item[aluno] = (nota !== null ? nota : '') + '-' + id;
                break;
            case 'leitura':
                // @ts-ignore
                obj.atividade_nota_leitura = nota;
                item[aluno] = (nota !== null ? nota : '') + '-' + id;
                break;
            case 'audicao':
                // @ts-ignore
                obj.atividade_nota_audicao = nota;
                item[aluno] = (nota !== null ? nota : '') + '-' + id;
                break;
            case 'escrita':
                // @ts-ignore
                obj.atividade_nota_escrita = nota;
                item[aluno] = (nota !== null ? nota : '') + '-' + id;
                break;
            case 'preparacao':
                // @ts-ignore
                obj.atividade_nota_preparacao = nota;
                item[aluno] = (nota !== null ? nota : '') + '-' + id;
                break;
            case 'participacao':
                // @ts-ignore
                obj.atividade_nota_participacao = nota;
                item[aluno] = (nota !== null ? nota : '') + '-' + id;
                break;
        }
        if (!(await turmaAlunoAtividadeStore.turmaAlunoAtividadeUpdate(obj))) {
            item[aluno] = oldValue + '-' + id;
        }
        this.fichaDeNotasMenus[`${item.turma_atividade_id}_${aluno}_${tipo}`] =
            false;
    }
    async onChangeExibirSomenteVigente(val) {
        this.fichaNotaAtividadeHeader = [
            { text: 'Unidade', sortable: false, value: 'atividade' },
        ];
        this.fichaFrequenciaHeaders = [
            { text: '', sortable: false, value: 'idx', width: '10px' },
            { text: 'Data da atividade', sortable: false, value: 'data_atividade' },
            { text: 'Atividade', sortable: false, value: 'atividade' },
            { text: 'Reposições', sortable: false, value: 'reposicoes' },
        ];
        let idx = 0;
        this.items.forEach((item) => {
            let add = false;
            // Somente ativos
            if (val) {
                if (item.status_do_aluno_na_turma_id == 1) {
                    add = true;
                }
            }
            else {
                add = true;
            }
            idx++;
            if (add) {
                const formattedName = this.formatName(item.aluno_nome);
                const obj = {
                    text: this.propIsMobile ? formattedName : String(idx),
                    sortable: false,
                    value: 'aluno_' + idx,
                    align: 'center',
                    filterable: true,
                };
                this.fichaFrequenciaHeaders.push(obj);
                this.fichaNotaAtividadeHeader.push(obj);
            }
        });
        if (this.propIsMobile) {
            const reposicoes = this.fichaFrequenciaHeaders.find((item) => item.text === 'Reposições');
            if (reposicoes) {
                const reposicoesIndex = this.fichaFrequenciaHeaders.lastIndexOf(reposicoes);
                // Remova o elemento "Reposições" de sua posição original
                this.fichaFrequenciaHeaders.splice(reposicoesIndex, 1);
                // Adicione o elemento "Reposições" ao final do array
                this.fichaFrequenciaHeaders.push({
                    text: 'Reposições',
                    sortable: false,
                    value: 'reposicoes',
                });
            }
        }
    }
    async onChangeSelectedLivro(val, oldVal) {
        if (val) {
            this.atividadesOptions = await atividadeStore.getAtividades(val.id);
        }
        else {
            this.atividadesOptions = [];
        }
    }
    async requisicaoReposicao() {
        const isValid = await this.$refs.reforcoObserver.validate();
        if (!isValid) {
            return;
        }
        const reposicaoCreate = newReposicao();
        reposicaoCreate.tipo = 1;
        reposicaoCreate.observacao = this.requisicaoReforcoObservacao;
        reposicaoCreate.descricao = this.observacaoAulaExtra;
        reposicaoCreate.company_id = this.userProfile.company.id;
        reposicaoCreate.professor_id = null;
        reposicaoCreate.professor_turma_id = this.userProfile.id;
        reposicaoCreate.aprovada = false;
        reposicaoCreate.status = 1;
        reposicaoCreate.atividade_id = this.selectedReforcoAtividade;
        reposicaoCreate.sala_id = null;
        reposicaoCreate.motivo_id = this.selectedReforcoMotivo;
        const reposicaoId = await reposicaoStore.createReposicao(reposicaoCreate);
        if (reposicaoId) {
            const payload = this.selectedFichaFrequenciaAlunos.map((item) => {
                return {
                    aluno_id: item.aluno_id,
                    reposicao_id: reposicaoId,
                    turma_id: this.id,
                    status: 0,
                    turma_aluno_atividade_id: null,
                    valor: 0,
                    turma_aluno_id: item.turma_aluno_id,
                };
            });
            await reposicaoStore.createReposicoesAlunos(payload);
        }
        this.dialog = false;
    }
    fichaFrequenciaActions(actions) {
        if (!actions) {
            return { text: 'N/D', color: '' };
        }
        const arr = actions.split('-');
        const options = {
            0: {
                t: { text: 'P', color: 'green' },
                f: { text: 'F', color: '#EB5406' },
            },
            2: { text: 'R', color: 'blue' },
            3: { text: 'R', color: '#EB5406' },
            4: { text: 'R', color: 'orange' },
        };
        for (const [index, item] of arr.entries()) {
            const valBool = item === 't';
            if (valBool) {
                if (index === 0) {
                    return options[index].t;
                }
                return options[index + 1];
            }
            else if (item === 'f' && index === 0) {
                return options[index].f;
            }
        }
        return { text: 'N/D', color: '' };
    }
    fichaDeNotasActions(actions) {
        if (!actions) {
            return { text: 'N/D', color: '' };
        }
        const arr = actions.split('-');
        if (arr[0].length > 0) {
            const value = parseFloat(arr[0]);
            if (Number.isNaN(value)) {
                if (this.userProfile.company.escola_id === 1) {
                    switch (arr[0]) {
                        case 'Ótimo':
                            return { text: arr[0], color: 'green' };
                        case 'Bom':
                            return { text: arr[0], color: 'green' };
                        case 'Regular':
                            return { text: arr[0], color: 'orange' };
                        case 'Insatisfatório':
                            return { text: arr[0], color: '#EB5406' };
                    }
                }
                else {
                    switch (arr[0]) {
                        case 'Excelente':
                            return { text: arr[0], color: 'green' };
                        case 'Bom':
                            return { text: arr[0], color: 'green' };
                        case 'Aprimorar':
                            return { text: arr[0], color: 'orange' };
                        case 'Observar':
                            return { text: arr[0], color: '#EB5406' };
                    }
                }
            }
            if (value >= 8) {
                return { text: arr[0], color: 'green' };
            }
            else if (value >= 7) {
                return { text: arr[0], color: 'orange' };
            }
            else {
                return { text: arr[0], color: '#EB5406' };
            }
        }
        return { text: 'N/D', color: '' };
    }
    async fichaFrequenciaVerso() {
        this.loadingVersoFichaFrequencia = true;
        await turmaStore.versoFichaFrequencia({ id: this.id, exibirSomenteVigente: this.exibirSomenteVigente });
        this.loadingVersoFichaFrequencia = false;
    }
    async fichaFrequenciaImprimir() {
        this.loadingImprimirFichaFrequencia = true;
        await turmaStore.imprimirFichaFrequencia(this.id);
        this.loadingImprimirFichaFrequencia = false;
    }
    get filterDateFichaFrequenciaItems() {
        const today = new Date();
        const item = this.fichaFrequenciaItems;
        const filter = item?.filter((i) => new Date(i.data_atividade) <= today);
        return filter;
    }
    async mounted() {
        this.rodandoMounted = true;
        await companyConfiguracaoStore.getCompanyConfiguracoes();
        if (this.propTurmaId) {
            this.id = this.propTurmaId;
            this.loadingTurma = true;
            await turmaStore.getTurmaAlunosFicha(this.id);
            this.loadingTurma = false;
            // @ts-ignore
            this.items = this.dadosTurma.gestao;
            // @ts-ignore
            this.fichaFrequenciaItems = this.dadosTurma.ficha_frequencia;
            // @ts-ignore
            this.fichaFrequenciaReposicoes = this.dadosTurma.reposicoes;
            // @ts-ignore
            this.fichaNotaHomeworkItems = this.dadosTurma.ficha_nota_homework;
            // @ts-ignore
            this.fichaNotaEscritaItems = this.dadosTurma.ficha_nota_escrita;
            // @ts-ignore
            this.fichaNotaAudicaoItems = this.dadosTurma.ficha_nota_audicao;
            // @ts-ignore
            this.fichaNotaFalaItems = this.dadosTurma.ficha_nota_fala;
            // @ts-ignore
            this.fichaNotaLeituraItems = this.dadosTurma.ficha_nota_leitura;
            // @ts-ignore
            this.fichaNotaPreparacaoItems = this.dadosTurma.ficha_nota_preparacao;
            // @ts-ignore
            this.fichaNotaParticipacaoItems = this.dadosTurma.ficha_nota_participacao;
            this.selectDataFichaFrequencia = this.filterDateFichaFrequenciaItems
                ? this.filterDateFichaFrequenciaItems[this.filterDateFichaFrequenciaItems.length - 1]
                : null;
            this.fichaNotaAtividadeHeader = [
                { text: 'Unidade', sortable: false, value: 'atividade' },
            ];
            this.fichaFrequenciaHeaders = [
                { text: '', sortable: false, value: 'idx', width: '10px' },
                { text: 'Data da atividade', sortable: false, value: 'data_atividade' },
                { text: 'Atividade', sortable: false, value: 'atividade' },
                { text: 'Reposições', sortable: false, value: 'reposicoes' },
            ];
            if (this.items) {
                this.items.forEach((item, idx) => {
                    const formattedName = this.formatName(item.aluno_nome);
                    const index = idx + 1;
                    const obj = {
                        text: this.propIsMobile ? formattedName : String(index),
                        sortable: false,
                        value: 'aluno_' + index,
                        align: 'center',
                        filterable: true,
                    };
                    this.fichaFrequenciaHeaders.push(obj);
                    this.fichaNotaAtividadeHeader.push(obj);
                });
                if (this.propIsMobile) {
                    const reposicoes = this.fichaFrequenciaHeaders.find((item) => item.text === 'Reposições');
                    if (reposicoes) {
                        const reposicoesIndex = this.fichaFrequenciaHeaders.lastIndexOf(reposicoes);
                        // Remova o elemento "Reposições" de sua posição original
                        this.fichaFrequenciaHeaders.splice(reposicoesIndex, 1);
                        // Adicione o elemento "Reposições" ao final do array
                        this.fichaFrequenciaHeaders.push({
                            text: 'Reposições',
                            sortable: false,
                            value: 'reposicoes',
                        });
                    }
                }
            }
            this.entityObject = await turmaStore.getTurma(parseInt(this.propTurmaId, 0));
            this.curso = this.entityObject.livro.curso;
            this.livro = this.entityObject.livro;
            this.turmaAtividadeItems = await turmaStore.getCronograma(this.id);
            this.exibirSomenteVigente =
                !(
                // @ts-ignore
                companyConfiguracaoStore.companyConfiguracoes.turma_exibir_todos_alunos);
        }
        this.rodandoMounted = false;
    }
    showLogTurmaAlunoAtividade(ref, id) {
        const form = ref;
        form.show(id);
    }
};
__decorate([
    Prop({ type: Number, default: '' })
], FichaFrequenciaComponent.prototype, "propTurmaId", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], FichaFrequenciaComponent.prototype, "propIsMobile", void 0);
__decorate([
    Watch('exibirSomenteVigente')
], FichaFrequenciaComponent.prototype, "onChangeExibirSomenteVigente", null);
__decorate([
    Watch('selectedReforcoLivro')
], FichaFrequenciaComponent.prototype, "onChangeSelectedLivro", null);
FichaFrequenciaComponent = __decorate([
    Component({
        components: {
            ClickableCard,
            DateFieldComponent,
            EntidadeLinkComponent,
            LogShowComponent,
            NotaGestaoButton,
            SaveButton,
            ValidationObserver,
            ValidationProvider,
        },
    })
], FichaFrequenciaComponent);
export default FichaFrequenciaComponent;
