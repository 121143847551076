import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { alunoStore, turmaAlunoAtividadeStore, userActionStore, reposicaoStore, } from '@/store';
import _ from 'lodash';
import PedagogicoNaoReporComponent from '@/components/PedagogicoNaoReporComponent.vue';
import PedagogicoMarcarReposicaoComponent from '@/components/PedagogicoMarcarReposicaoComponent.vue';
import TurmaEvadirAlunoComponent from '@/components/TurmaEvadirAlunoComponent.vue';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import TextExpandableComponent from './TextExpandableComponent.vue';
let PedagogicoAlunoComponent = class PedagogicoAlunoComponent extends Vue {
    constructor() {
        super(...arguments);
        this.id = null;
        this.dialogNaoRepor = false;
        this.dialogMarcarReposicao = false;
        this.dialogMarcarAulaExtra = false;
        this.dialogTurmaEvadirAluno = false;
        this.dialogTurmaEvadirAlunoTurmaAlunoId = null;
        this.loading = false;
        this.turmaAlunoAtividade = [];
        this.opcoesReposicao = [];
        this.turmas = [];
        this.turmaItems = [];
        this.historicoReposicaoAluno = [];
        this.atribuidasVoceSearch = '';
        this.aluno_id = 0;
        this.turma_id = 0;
        this.atividade_id = 0;
        this.reposicao_id = 0;
        this.idioma = '';
        this.loadingBoletim = false;
        this.headersHistoricoReposicaoAluno = [
            { text: 'Data', value: 'created_at' },
            { text: 'Livro', value: 'livro_nome' },
            { text: 'Unidade', value: 'atividade_descricao' },
            { text: 'Descrição', value: 'observacao' },
            { text: 'Observação', value: 'descricao' },
            { text: 'Professor', value: 'professor_name' },
            { text: 'Data/Hora', value: 'data_hora' },
            { text: 'Status', value: 'status' },
            { text: 'Ações', value: 'actions' },
        ];
    }
    get userActionsEvadirAlunoTurmaAllow() {
        return userActionStore.evadirAlunoTurma;
    }
    routeEdit(item) {
        const aprovado = item.status === 2;
        const agendado = item.status === 4;
        if ((aprovado || agendado) === false) {
            return;
        }
        this.aluno_id = item.aluno_id;
        this.turma_id = item.turma_id;
        this.atividade_id = item.atividade_id;
        this.reposicao_id = item.reposicao_id;
        this.idioma = item.idioma;
        this.dialogMarcarAulaExtra = true;
    }
    isHighlighted(item) {
        return 'selectedColor';
    }
    getFaltas(turma_aluno_id) {
        const atividades = this.getAtividadesByTurmaAluno(turma_aluno_id);
        const contagem = _.countBy(atividades, 'presenca');
        if (contagem.Falta) {
            return contagem.Falta;
        }
        else {
            return 0;
        }
    }
    pedagogicoHeaders(item) {
        const h = [
            { text: '', value: 'checked' },
            { text: 'Atividade', value: 'atividade_descricao' },
            { text: 'Executada', value: 'atividade_data_executada' },
            // @ts-ignore
            { text: `${this.getNomeHomework()}`, value: 'atividade_nota' },
        ];
        if (item.nota_preparacao) {
            h.push({ text: 'Nota - Preparação', value: 'atividade_nota_preparacao' });
        }
        if (item.nota_audicao) {
            h.push({ text: 'Nota - Audição', value: 'atividade_nota_audicao' });
        }
        if (item.nota_leitura) {
            h.push({ text: 'Nota - Leitura', value: 'atividade_nota_leitura' });
        }
        if (item.nota_fala) {
            h.push({ text: 'Nota - Fala', value: 'atividade_nota_fala' });
        }
        if (item.nota_escrita) {
            h.push({ text: 'Nota - Escrita', value: 'atividade_nota_escrita' });
        }
        if (item.nota_participacao) {
            h.push({
                text: 'Nota - Participação',
                value: 'atividade_nota_participacao',
            });
        }
        if (item.nota_revisao) {
            h.push({ text: 'Nota - Revisão', value: 'atividade_nota_revisao' });
        }
        h.push({ text: 'Presença', value: 'presenca' });
        h.push({ text: 'Observação', value: 'atividade_observacao_adicional' });
        return h;
    }
    get turmaAlunoItems() {
        if (this.turmaAlunoAtividade.length === 0) {
            return [];
        }
        if (this.propModoCobranca) {
            const data = _.filter(this.turmaAlunoAtividade, (item) => item.status_do_aluno_na_turma_id === 1 // && item.turma_status_id === 2
            );
            return _.uniqBy(this.turmaAlunoAtividade, 'turma_aluno_id');
        }
        else {
            return _.uniqBy(this.turmaAlunoAtividade, 'turma_aluno_id');
        }
    }
    get getAtividadesSelecionadas() {
        return _.filter(this.turmaAlunoAtividade, (item) => item.checked);
    }
    getAtividadesByTurmaAluno(turmaAlunoId) {
        return _.filter(this.turmaAlunoAtividade, (item) => item.turma_aluno_id === turmaAlunoId);
    }
    async imprimirBoletim(turmaAlunoId) {
        if (this.loadingBoletim) {
            return;
        }
        this.loadingBoletim = true;
        const res = await alunoStore.getBoletim(turmaAlunoId);
        if (res.data) {
            if (res.headers['content-type'].includes('html')) {
                const html = res.data;
                const w = window.open();
                w.document.write(html);
                setTimeout(() => {
                    w.print();
                }, 1000);
            }
            else {
                const uri = 'data:image/png;base64,' + res.data;
                const a = document.createElement('a');
                a.href = uri;
                a.download = 'Boletim.png';
                a.click();
            }
        }
        this.loadingBoletim = false;
    }
    async evadirAluno(turmaAlunoId) {
        this.dialogTurmaEvadirAlunoTurmaAlunoId = turmaAlunoId;
        this.dialogTurmaEvadirAluno = true;
    }
    async marcarReposicao() {
        this.opcoesReposicao =
            await turmaAlunoAtividadeStore.getTurmaAlunoAtividadeOpcoesReposicao(this.getAtividadesSelecionadas[0].turma_aluno_atividade_id);
        this.dialogMarcarReposicao = true;
    }
    async onCancel() {
        this.loading = true;
        this.dialogNaoRepor = false;
        this.dialogMarcarReposicao = false;
        this.dialogTurmaEvadirAluno = false;
        setTimeout(async () => {
            this.turmas = await alunoStore.getPedagogicoTurmas(this.id);
            this.turmaAlunoAtividade = await alunoStore.getPedagogico(this.id);
            this.loading = false;
        }, 1000);
        this.$emit('on-cancel');
    }
    async updateGridAulaExtra() {
        this.historicoReposicaoAluno = await reposicaoStore.getReposicoesByAluno(this.id);
        this.dialogMarcarAulaExtra = false;
        this.onCancel();
    }
    async created() {
        this.loading = true;
        this.id = parseInt(this.propAlunoId, 0);
        this.turmas = await alunoStore.getPedagogicoTurmas(this.id);
        this.turmaAlunoAtividade = await alunoStore.getPedagogico(this.id);
        this.historicoReposicaoAluno = await reposicaoStore.getReposicoesByAluno(this.id);
        this.loading = false;
    }
};
__decorate([
    Prop({ type: Number, default: '' })
], PedagogicoAlunoComponent.prototype, "propAlunoId", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], PedagogicoAlunoComponent.prototype, "propModoCobranca", void 0);
PedagogicoAlunoComponent = __decorate([
    Component({
        components: {
            OpenNewTabComponent,
            PedagogicoMarcarReposicaoComponent,
            PedagogicoNaoReporComponent,
            TurmaEvadirAlunoComponent,
            TextExpandableComponent
        },
    })
], PedagogicoAlunoComponent);
export default PedagogicoAlunoComponent;
