import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { contratoStore, mainStore } from '@/utils/store-accessor';
import _ from 'lodash';
import EntidadeLinkComponent from '@/components/EntidadeLinkComponent.vue';
import TextExpandableComponent from '@/components/TextExpandableComponent.vue';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import LivroSolicitadoDiretoFranchiseButton from '@/components/LivroSolicitadoDiretoFranchiseButton.vue';
import AulaLocalShowButton from '@/components/AulaLocalShowButton.vue';
import PeriodSelectorUpdatedComponent from '@/components/PeriodSelectorUpdatedComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import CustomTabsComercialComponent from '@/components/CustomTabsComercialComponent.vue';
let Matricula = class Matricula extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            { text: 'Assinatura do contrato', value: 'vigencia_inicio' },
            { text: 'Consultor', value: 'consultor_nome' },
            { text: 'Curso', value: 'curso_nome' },
            { text: 'Aluno', value: 'aluno_nome' },
            { text: 'Responsável', value: 'responsavel_nome' },
            { text: 'Contatos', value: 'aluno_contatos' },
            { text: 'Como conheceu', value: 'como_conheceu_knn' },
            {
                text: 'Primeira parcela do curso',
                value: 'curso_primeira_parcela_status_id'
            },
            {
                text: 'Primeira parcela do material',
                value: 'material_didatico_primeira_parcela_status_id'
            },
            { text: 'Taxa de Matricula', value: 'taxa_matricula_valor' },
            {
                text: 'Forma pagamento',
                value: 'material_didatico_forma_pagamento'
            },
            { text: 'Desconto', value: 'curso_desconto_percentual' },
            { text: 'Turma', value: 'turma_id' },
            { text: 'Aluno que indicou', value: 'indicou_aluno_id' }
        ];
        this.headersTotalizadores = [
            { text: 'Nome', value: 'name' },
            { text: 'Qtd Matrículas', value: 'value' },
            { text: '1ª Parc. Em aberto', value: 'em_aberto' },
            { text: '1ª Parc. Paga', value: 'pago' },
            { text: '1ª Parc. Cancelada', value: 'cancelado' },
            { text: '1ª Parc. Renegociada', value: 'renegociado' },
            { text: '1ª Parc. Protestada', value: 'protestado' },
            { text: 'Taxa de Matrícula', value: 'taxa_matricula_valor' }
        ];
        this.publicItems = [
            { title: 'Driver', route: '/main/Comercial/all' },
            { title: 'Parcerias', route: '/main/ComercialParcerias/all' },
            { title: 'Campanhas', route: '/main/ComercialCampanhas/all' },
            { title: 'Driving MKT', route: '/main/DrivingMarketing/all' },
            {
                title: 'Quadro de planej.',
                route: '/main/QuadrosPlanejamentocomercial/all'
            },
            { title: 'Cupons', route: '/main/ComercialCupons/all' },
            { title: 'Saldo de parcerias', route: '/main/CadernosCadernodecupom/all' },
            { title: 'CDA', route: '/main/ComercialCDA/all' },
            { title: 'Prospex', route: '/main/prospex/all' },
            {
                title: 'Caderno de matrícula',
                route: '/main/CadernosCadernodematriculas/matricula'
            },
            { title: 'Check-up comercial', route: '/main/checkup/all' }
        ];
        this.search = '';
        this.loading = false;
        this.items = [];
        this.selectedConsultor = [];
        this.selectPeriodDate = null;
        this.totais = {};
        this.tipoFiltro = 'C';
        this.selectedTab = 9;
        this.menu = false;
    }
    get filteredItems() {
        const dataToReturn = [];
        this.items.forEach(i => {
            if (this.selectedConsultor
                .map(filterData => filterData.name)
                .includes(i.consultor_nome)) {
                dataToReturn.push(i);
            }
            else if (this.selectedConsultor
                .map(filterData => filterData.name)
                .includes('Não informado') &&
                !i.consultor_nome) {
                dataToReturn.push(i);
            }
        });
        return dataToReturn;
    }
    get totalAlunosComTurma() {
        return this.filteredItems.filter(item => item.turma_id).length;
    }
    get totalPorConsultor() {
        const countedByName = _(this.items)
            .countBy('consultor_nome')
            .value();
        return Object.keys(countedByName).map(i => {
            // const consultor = i;
            // usar somente se habilitar o card comentado
            // const total = _.sumBy(this.items, (i) => {
            //   if (i.consultor_nome == consultor) {
            //     return 1
            //   } else {
            //     return 0
            //   }
            // })
            this.totais = {};
            _.forEach(this.items, item => {
                let consultor_nome = item.consultor_nome;
                if (consultor_nome == null) {
                    consultor_nome = 'Não informado';
                }
                if (!this.totais[consultor_nome]) {
                    this.totais[consultor_nome] = {};
                    this.totais[consultor_nome][0] = 0;
                    this.totais[consultor_nome][1] = 0;
                    this.totais[consultor_nome][2] = 0;
                    this.totais[consultor_nome][3] = 0;
                    this.totais[consultor_nome][4] = 0;
                    this.totais[consultor_nome].taxa_matricula_valor = 0;
                }
                this.totais[consultor_nome][item.curso_primeira_parcela_status_id]++;
                this.totais[consultor_nome].taxa_matricula_valor +=
                    item.taxa_matricula_valor;
            });
            if (i != 'null') {
                return { name: i, value: countedByName[i] };
            }
            else {
                return { name: 'Não informado', value: countedByName[i] };
            }
        });
    }
    get totalPorStatusPrimeiraParcela() {
        const countedByName = _(this.items)
            .countBy('curso_primeira_parcela_status_id')
            .value();
        return Object.keys(countedByName).map(i => {
            if (i != 'null') {
                return { name: i, value: countedByName[i] };
            }
            else {
                return { name: 'Não informado', value: countedByName[i] };
            }
        });
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    async buscar() {
        if (!this.selectPeriodDate[0] || !this.selectPeriodDate[1]) {
            return;
        }
        this.loading = true;
        const filtros = [];
        if (this.tipoFiltro === 'C') {
            filtros.push(`contrato.vigencia_inicio BETWEEN '${this.selectPeriodDate[0]}' AND '${this.selectPeriodDate[1]}'`);
            filtros.push('contrato.matricula_tipo_id = 1');
        }
        else if (this.tipoFiltro === 'P') {
            // Pagamento
            filtros.push('POR DATA PAGAMENTO');
            filtros.push(` BETWEEN '${this.selectPeriodDate[0]}' AND '${this.selectPeriodDate[1]}'`);
            filtros.push('contrato.matricula_tipo_id = 1');
        }
        this.items = await contratoStore.getContratos(filtros);
        this.selectedConsultor = this.totalPorConsultor;
        this.loading = false;
    }
    mounted() {
        if (this.userProfile.company.aula_online_allow) {
            this.headers.push({
                text: 'Local aula',
                value: 'local_aula_id'
            });
        }
    }
};
Matricula = __decorate([
    Component({
        components: {
            EntidadeLinkComponent,
            LivroSolicitadoDiretoFranchiseButton,
            OpenNewTabComponent,
            TextExpandableComponent,
            AulaLocalShowButton,
            PeriodSelectorUpdatedComponent,
            ButtonComponent,
            CustomTabsComercialComponent
        }
    })
], Matricula);
export default Matricula;
