var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-0 pt-0",attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-1 pa-3 rounded-xl mb-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Relatório de faltas")])]),_c('v-card-text',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('period-selector-component',{attrs:{"prop-title":"Data da falta"},model:{value:(_vm.selectPeriodDate),callback:function ($$v) {_vm.selectPeriodDate=$$v},expression:"selectPeriodDate"}})],1),_c('v-col',[_c('v-btn',{staticClass:"primary",attrs:{"loading":_vm.isLoading},on:{"click":_vm.buscar}},[_vm._v(" Buscar ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"headline primary--text text-h6"},[_vm._v(" Faltas consecutivas ")])]),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"type":"number","min":"1","label":"Intervalo inicial","rules":[_vm.validarFaltaInicial]},model:{value:(_vm.faltaInicial),callback:function ($$v) {_vm.faltaInicial=$$v},expression:"faltaInicial"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"type":"number","min":"1","label":"Intervalo final","rules":[_vm.validarFaltaFinal]},model:{value:(_vm.faltaFinal),callback:function ($$v) {_vm.faltaFinal=$$v},expression:"faltaFinal"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-row',[(_vm.totals.por_professor)?_c('v-col',[_c('GenericListTotalCardComponent',{attrs:{"prop-title":"Por professor","prop-items":_vm.totals.por_professor},scopedSlots:_vm._u([{key:"item.temp_percentual_de_falta",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPriceValue")(((item.Faltas / (item.Faltas + item.Presenças)) * 100)))+" % ")]}}],null,false,3037430285)})],1):_vm._e()],1)],1),(_vm.totals.por_livro)?_c('v-col',[_c('GenericListTotalCardComponent',{attrs:{"prop-title":"Por livro","prop-items":_vm.totals.por_livro},scopedSlots:_vm._u([{key:"item.temp_percentual_de_falta",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPriceValue")(((item.Faltas / (item.Faltas + item.Presenças)) * 100)))+" % ")]}}],null,false,3037430285)})],1):_vm._e()],1),(_vm.totals.por_professor)?_c('v-row',[_c('v-col',[_c('GenericListItemsCardComponent',{attrs:{"propTableName":"list_relatorio_faltas"},scopedSlots:_vm._u([{key:"item.aluno_nome",fn:function(ref){
var item = ref.item;
return [_c('open-new-tab-component',{attrs:{"propObjectLabel":item.aluno_nome,"propObjectId":item.aluno_id,"propToolName":"Aluno"}})]}},{key:"item.codigo_referencia",fn:function(ref){
var item = ref.item;
return [_c('open-new-tab-component',{attrs:{"propObjectLabel":("" + (item.codigo_referencia)),"propObjectId":item.turma_id,"propToolName":"Turma"}})]}},{key:"item.atividade_data_executada",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(value))+" ")]}},{key:"item.atividade_presenca",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":_vm.atividadeStatusOptions(item).color}},[_vm._v(" "+_vm._s(_vm.atividadeStatusOptions(item).text)+" ")])]}}],null,false,4119493786)})],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }