import { __decorate } from "tslib";
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { userToolStore } from '@/utils/store-accessor';
import { mainStore } from '@/store';
let CustomTabsDriverMetasComponent = class CustomTabsDriverMetasComponent extends Vue {
    constructor() {
        super(...arguments);
        this.publicItems = [
            { title: 'Driver Metas', route: '/main/DriverMetas/all', toolName: '', value: 0 },
            {
                title: 'Comercial',
                route: '/main/DriverMetas/comercial',
                toolName: 'Metas',
                value: 1,
            },
        ];
        this.internalSelectedTab = 0;
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    get filteredItems() {
        return this.publicItems.filter((item) => this.allowView(item.toolName));
    }
    allowView(toolName) {
        if (!toolName)
            return true;
        const tool = userToolStore.userToolsIndexed[toolName];
        return tool ? tool[0].allow_view : false;
    }
    onSelectedTabChange(newTab) {
        const index = this.filteredItems.findIndex((item) => item.value === newTab);
        this.internalSelectedTab = index !== -1 ? index : 0;
    }
    onRouteChange() {
        const tabIndex = this.getTabIndexFromRoute(this.$route.path);
        this.internalSelectedTab = tabIndex;
        this.$emit('update:selectedTab', this.filteredItems[tabIndex]?.value || 0);
    }
    handleTabClick(item, index) {
        if (this.internalSelectedTab !== index) {
            this.internalSelectedTab = index;
            this.$emit('update:selectedTab', item.value);
        }
        this.redirectToRoute(item.route);
    }
    redirectToRoute(route) {
        if (this.$route.path !== route) {
            this.$router.push(route);
        }
    }
    mounted() {
        const tabIndex = this.getTabIndexFromRoute(this.$route.path);
        this.internalSelectedTab = tabIndex;
    }
    getTabIndexFromRoute(route) {
        const itemIndex = this.filteredItems.findIndex((item) => item.route === route);
        return itemIndex !== -1 ? itemIndex : 0;
    }
};
__decorate([
    Prop({ type: Number, default: 0 })
], CustomTabsDriverMetasComponent.prototype, "selectedTab", void 0);
__decorate([
    Watch('selectedTab', { immediate: true })
], CustomTabsDriverMetasComponent.prototype, "onSelectedTabChange", null);
__decorate([
    Watch('$route')
], CustomTabsDriverMetasComponent.prototype, "onRouteChange", null);
CustomTabsDriverMetasComponent = __decorate([
    Component
], CustomTabsDriverMetasComponent);
export default CustomTabsDriverMetasComponent;
