import { render, staticRenderFns } from "./Comercial.vue?vue&type=template&id=3545ecb2&scoped=true&"
import script from "./Comercial.vue?vue&type=script&lang=ts&"
export * from "./Comercial.vue?vue&type=script&lang=ts&"
import style0 from "./Comercial.vue?vue&type=style&index=0&id=3545ecb2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3545ecb2",
  null
  
)

export default component.exports