import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { financeiroParcelaStore, analyticsStore, userToolStore, userActionStore, mainStore } from '@/store';
import _ from 'lodash';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
import EntidadeLinkComponent from '@/components/EntidadeLinkComponent.vue';
import SearchEntidadeComponent from '@/components/SearchEntidadeComponent.vue';
import FinanceiroValorPagoTextComponent from '@/components/FinanceiroValorPagoTextComponent.vue';
import GenericListTotalCardComponent from '@/components/GenericListTotalCardComponent.vue';
import GenericListItemsCardComponent from '@/components/GenericListItemsCardComponent.vue';
import TextExpandableComponent from '@/components/TextExpandableComponent.vue';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import SaveButton from '@/components/SaveButton.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
let List = class List extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            {
                show: true,
                table: 'financeiro_categoria',
                text: 'Categoria',
                type: '',
                value: 'financeiro_categoria_nome',
                field: 'nome'
            },
            {
                show: true,
                table: 'tmp',
                text: 'Entidade - Nome',
                value: 'entidade_nome'
            },
            {
                show: true,
                table: 'tmp',
                text: 'Entidade - Tipo',
                value: 'entidade_tipo'
            },
            {
                table: 'aluno',
                text: 'Aluno - Celular',
                value: 'aluno_celular'
            },
            {
                show: true,
                table: 'aluno',
                text: 'Telefone',
                value: 'aluno_contatos'
            },
            {
                show: true,
                table: 'aluno',
                text: 'Responsável Financeiro',
                value: 'responsavel_nome'
            },
            {
                table: 'aluno',
                text: 'Responsável - Celular',
                value: 'responsavel_celular'
            },
            {
                show: true,
                table: 'tmp',
                text: 'Parcela',
                type: '',
                value: 'parcela'
            },
            {
                show: true,
                table: 'financeiro',
                text: 'Emissão',
                type: '',
                value: 'data_emissao'
            },
            {
                show: true,
                table: 'financeiro_parcela',
                text: 'Vencimento',
                type: '',
                value: 'vencimento'
            },
            {
                show: true,
                table: 'financeiro_parcela',
                text: 'Valor Integral',
                type: '',
                value: 'valor_integral'
            },
            ...(this.currentUser.company.escola_id === 1
                ? [
                    {
                        show: true,
                        table: 'financeiro_parcela',
                        text: 'Desconto Club',
                        type: '',
                        value: 'valor_desconto'
                    }
                ]
                : []),
            {
                show: true,
                table: 'tmp',
                text: 'Valor com desconto',
                type: '',
                value: 'valor_original'
            },
            {
                show: true,
                table: 'tmp',
                text: 'Valor Atualizado',
                type: '',
                value: 'valor_atualizado'
            },
            {
                show: true,
                table: 'financeiro_parcela',
                text: 'Valor Pago',
                type: '',
                value: 'valor_pago'
            },
            {
                show: true,
                table: 'financeiro_parcela',
                text: 'Observação',
                type: '',
                value: 'observacao'
            },
            {
                show: true,
                table: 'financeiro_parcela_status',
                text: 'Status',
                type: '',
                value: 'financeiro_parcela_status_nome',
                field: 'nome'
            },
            {
                show: true,
                table: 'contrato',
                text: 'Status do contrato',
                type: '',
                value: 'contrato_status_id',
                field: 'status_id'
            },
            {
                show: true,
                text: 'Ações',
                value: 'actions'
            }
        ];
        this.headerVencido = [
            {
                text: 'Categoria',
                value: 'Categoria'
            },
            {
                text: 'A receber - Valor integral (Sem desconto)',
                value: 'temp_receber_valor_integral_sem_desconto'
            },
            {
                text: 'A receber - Valor com desconto',
                value: 'temp_receber_valor_com_desconto'
            },
            {
                text: 'A receber - Valor atualizado',
                value: 'temp_receber_valor_atualizado'
            },
            {
                text: 'Valor recebido',
                value: 'temp_valor_recebido'
            }
        ];
        this.excluirParcelasHeaders = [
            { text: '', value: 'checked' },
            { text: 'Número da parcela', value: 'parcela_numero' },
            { text: 'Vencimento', value: 'vencimento' },
            { text: 'Valor Integral', value: 'valor_integral', align: 'left' },
            ...(this.currentUser.company.escola_id === 1
                ? [{ text: 'Desconto Club', value: 'valor_desconto', align: 'left' }]
                : []),
            { text: 'Valor Atualizado', value: 'valor_atualizado', align: 'left' },
            { text: 'Valor Pago', value: 'valor_pago', align: 'left' },
            { text: 'Status', value: 'status', align: 'left' }
        ];
        this.selectPeriodDate = [];
        this.excluirParcelasDialog = false;
        this.excluirParcelasOptions = [];
        this.excluirParcelaSelected = null;
        this.dialogFiltrosAdicionais = false;
        this.menu = false;
        this.rodando_mounted = true;
        this.valores_totalizadores = [];
    }
    get currentUser() {
        return mainStore.userProfile;
    }
    get allowView() {
        if (userToolStore.userToolsIndexed['Contas a receber']) {
            return userToolStore.userToolsIndexed['Contas a receber'][0].allow_view;
        }
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed['Contas a receber']) {
            return userToolStore.userToolsIndexed['Contas a receber'][0].allow_create;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed['Contas a receber']) {
            return userToolStore.userToolsIndexed['Contas a receber'][0].allow_edit;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed['Contas a receber']) {
            return userToolStore.userToolsIndexed['Contas a receber'][0].allow_delete;
        }
    }
    get userActionsVisualizarTotalizadoresAllow() {
        return userActionStore.visualizarTotalizadoresContasReceber;
    }
    get totals() {
        return analyticsStore.totals;
    }
    get isClearFiltersDisabled() {
        return this.selectPeriodDate.length === 0;
    }
    get formattedDate() {
        if (this.selectPeriodDate.length === 2) {
            const [start, end] = this.selectPeriodDate;
            return `${this.formatDate(start)} - ${this.formatDate(end)}`;
        }
        else if (this.selectPeriodDate.length === 1) {
            return `${this.formatDate(this.selectPeriodDate[0])}`;
        }
        return '';
    }
    clearFilters() {
        this.selectPeriodDate = [];
        this.$forceUpdate();
    }
    async selecionarTudo() {
        for (const key in this.totals) {
            if (this.totals[key].items) {
                this.totals[key].items.forEach(item => {
                    item.selected = true;
                });
            }
        }
    }
    async limparSelecionados() {
        for (const key in this.totals) {
            if (this.totals[key].items) {
                this.totals[key].items.forEach(item => {
                    item.selected = false;
                });
            }
        }
    }
    async refazerBusca(val) {
        if (val && val.length === 2 && !this.rodando_mounted) {
            await this.buscar();
        }
    }
    async deleteItem(item) {
        this.excluirParcelaSelected = item;
        this.excluirParcelasOptions = await financeiroParcelaStore.getFinanceiroParcelasByFinanceiro(item.financeiro_id);
        _.forEach(this.excluirParcelasOptions, el => {
            if (el.id === item.id) {
                el.checked = true;
            }
        });
        this.excluirParcelasDialog = true;
    }
    async confirmDeleteItem() {
        const itemsReq = [];
        _.forEach(this.excluirParcelasOptions, item => {
            if (item.checked) {
                itemsReq.push(item.id);
            }
        });
        const stringSwal = itemsReq.length > 0
            ? 'Confirma a exclusão das Contas?'
            : 'Confirma a exclusão da Conta?';
        // @ts-ignore
        this.$swal({
            title: stringSwal,
            text: 'Após exclusão as informações não podem ser recuperadas!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.excluirParcelasDialog = false;
                // @ts-ignore
                await financeiroParcelaStore.deleteConta(itemsReq);
                // @ts-ignore
                this.buscar();
            }
        });
    }
    async buscar() {
        const filters = {};
        filters['financeiro.tipo'] = {
            type: 'int',
            value: 0
        };
        if (this.selectPeriodDate && this.selectPeriodDate.length === 2) {
            filters['tmp.vencimento'] = {
                type: 'timestamp',
                value: [this.selectPeriodDate[0], this.selectPeriodDate[1]]
            };
        }
        analyticsStore.setDefaultFilters(filters);
        await this.doSearchTotals();
        await analyticsStore.executeSearchItems();
        // @ts-ignore
        // await this.$refs.loadingButton.changeLoadingStatus(false);
    }
    async doSearchItems(queryIn) {
        return await financeiroParcelaStore.getFinanceiroParcelasQuery(queryIn);
    }
    async doSearchTotals() {
        await analyticsStore.getAnalyticsFinanceiroParcela({
            data_inicial: this.selectPeriodDate[0],
            data_final: this.selectPeriodDate[1],
            tipo: 0,
            data_campo: 'financeiro_parcela.vencimento'
        });
        this.doSearchValorTotalizador();
    }
    async doSearchValorTotalizador() {
        // @ts-ignore
        const categorias = this.totals.por_categoria.items.filter((el) => !!el.selected).map((el) => el.id);
        // @ts-ignore
        const status_contrato = this.totals.por_status_contrato.items.filter((el) => !!el.selected).map((el) => el.id);
        this.valores_totalizadores = await analyticsStore.getAnalyticsValorTotalFinanceiroParcela({
            data_inicial: this.selectPeriodDate[0],
            data_final: this.selectPeriodDate[1],
            categorias: categorias,
            status_contrato: status_contrato,
            tipo: 0,
            data_campo: 'financeiro_parcela.vencimento'
        });
    }
    routeEdit(id) {
        const rota = this.$router.resolve({
            name: 'main-contasareceber-edit',
            params: { id }
        });
        window.open(rota.href, '_blank');
    }
    async mounted() {
        analyticsStore.initAnalytics();
        analyticsStore.setFnSearchItems(financeiroParcelaStore.getFinanceiroParcelasQuery);
        analyticsStore.setHeaders(this.headers);
        // tela que tem defaultFilter nao precisa ter o buscar() aqui
        const date = new Date();
        this.selectPeriodDate = [
            new Date(date.getFullYear(), date.getMonth(), 1).toISOString().split('T')[0],
            new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().split('T')[0]
        ];
        await this.buscar();
        this.rodando_mounted = false;
    }
};
__decorate([
    Prop(Boolean)
], List.prototype, "financeiroType", void 0);
__decorate([
    Watch('selectPeriodDate')
], List.prototype, "refazerBusca", null);
List = __decorate([
    Component({
        components: {
            PeriodSelectorComponent,
            EntidadeLinkComponent,
            SearchEntidadeComponent,
            FinanceiroValorPagoTextComponent,
            GenericListTotalCardComponent,
            TextExpandableComponent,
            OpenNewTabComponent,
            SaveButton,
            ButtonComponent,
            GenericListItemsCardComponent
        }
    })
], List);
export default List;
