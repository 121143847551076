import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { mainStore, metaStore } from '@/store';
import CustomTabsDriverMetasComponent from '@/components/CustomTabsDriverMetasComponent.vue';
import MetasCreateComponent from '@/components/MetasCreateComponent.vue';
import DashboardMetasComponent from '@/components/DashboardMetasComponent.vue';
import DashboardMetasAdmComponent from '@/components/DashboardMetasAdmComponent.vue';
import EditMetaComponent from '@/components/EditMetaComponent.vue';
let List = class List extends Vue {
    constructor() {
        super(...arguments);
        this.selectedTab = 11;
        this.dialogCriarMetas = false;
        this.dialogEditMetas = false;
        this.menuGeral = false;
        this.selectedMonth = new Date().toISOString().slice(0, 7);
        this.metas = [];
    }
    getMonthRange(month) {
        const [year, monthNumber] = month.split('-').map(Number);
        const firstDay = new Date(year, monthNumber - 1, 1);
        const data_inicial = firstDay.toISOString().split('T')[0];
        return { data_inicial };
    }
    get formattedMonth() {
        return this.formatDateToDisplay(this.selectedMonth);
    }
    formatDateToDisplay(date) {
        const meses = [
            'Janeiro',
            'Fevereiro',
            'Março',
            'Abril',
            'Maio',
            'Junho',
            'Julho',
            'Agosto',
            'Setembro',
            'Outubro',
            'Novembro',
            'Dezembro',
        ];
        const [year, month] = date.split('-');
        const monthIndex = parseInt(month, 10) - 1;
        return `${meses[monthIndex]} de ${year}`;
    }
    async onMonthSelect(month) {
        this.selectedMonth = month;
        this.menuGeral = false;
        const metas = await metaStore.getMetasByMonth({
            mesGeral: this.getMonthRange(month).data_inicial,
        });
        this.metas = metas;
    }
    get hasMetasInDb() {
        return this.metas.length > 0;
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    preencherMetas() {
        this.dialogCriarMetas = true;
    }
    async onCancel() {
        this.dialogCriarMetas = false;
    }
    async onReload() {
        window.location.reload();
    }
    editMetas() {
        this.dialogEditMetas = true;
    }
    async onCancelEdit() {
        this.dialogEditMetas = false;
    }
    async getMetasInDb() {
        const metas = await metaStore.getMetas();
        this.metas = metas || [];
        if (this.metas.length > 0) {
            const metaGeral = await metaStore.getMetasByMonth({
                mesGeral: this.getMonthRange(this.selectedMonth).data_inicial,
            });
            this.metas = metaGeral;
        }
    }
    async mounted() {
        await this.getMetasInDb();
    }
};
List = __decorate([
    Component({
        components: {
            CustomTabsDriverMetasComponent,
            MetasCreateComponent,
            DashboardMetasComponent,
            DashboardMetasAdmComponent,
            EditMetaComponent,
        },
    })
], List);
export default List;
