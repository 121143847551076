import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { analyticsStore, contratoStore } from '@/store';
import GenericListTotalCardComponent from '@/components/GenericListTotalCardComponent.vue';
import GenericListItemsCardComponent from '@/components/GenericListItemsCardComponent.vue';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
let Contrato = class Contrato extends Vue {
    constructor() {
        super(...arguments);
        this.loading = true;
        this.objecaoSelected = null;
        this.search = '';
        this.headers = [
            {
                text: 'Nome do aluno',
                value: 'aluno_nome',
                table: 'aluno',
                type: 'str',
                show: true,
            },
            {
                text: 'Idade do aluno',
                // table: 'aluno', nao precisa table
                value: 'idade',
                show: false,
            },
            {
                text: 'Ultimo livro',
                table: 'tmp',
                value: 'ultimo_livro',
                show: true,
            },
            {
                text: 'Status do contrato',
                value: 'contrato_status_nome',
                table: 'contrato_status',
                type: 'str',
                show: true,
                field: 'nome',
            },
            {
                text: 'Curso',
                value: 'curso_nome',
                table: 'curso',
                type: 'str',
                show: true,
                field: 'nome',
            },
            {
                text: 'Vigência - Inicio',
                value: 'vigencia_inicio',
                table: 'contrato',
                show: true,
            },
            {
                text: 'Vigência - Fim',
                value: 'vigencia_fim',
                table: 'contrato',
                show: true,
            },
            {
                text: 'Vigência - Fim (Previsão)',
                value: 'vigencia_fim_previsao',
                table: 'contrato',
                show: true,
            },
            {
                text: 'Preferência - Dia da semana',
                value: 'preferencia_dia_semana',
                table: 'contrato',
            },
            {
                text: 'Preferência - Período',
                value: 'preferencia_periodo',
                table: 'contrato',
            },
            {
                text: 'Taxa de matricula',
                value: 'taxa_matricula_valor',
                table: 'contrato',
            },
            {
                text: 'Material didático - Dia do vencimento',
                value: 'material_didatico_dia_vencimento',
                table: 'contrato',
            },
            {
                text: 'Material didático - Qtd de parcelas',
                value: 'material_quantidade_parcelas',
                table: 'contrato',
            },
            {
                text: 'Curso - Dia do vencimento ',
                value: 'curso_dia_vencimento',
                table: 'contrato',
            },
            {
                text: 'Curso - Qtd de parcelas',
                value: 'curso_quantidade_parcelas',
                table: 'contrato',
            },
            {
                text: 'Curso - Valor integral',
                value: 'curso_valor_integral',
                table: 'contrato',
            },
            {
                text: 'Curso - Desconto perc.',
                value: 'curso_desconto_percentual',
                table: 'contrato',
            },
            {
                text: 'Modalidade',
                value: 'modalidade_id',
                table: 'contrato',
            },
            {
                text: 'Transporte - Foi requisitado',
                value: 'requisitou_transporte',
                table: 'contrato',
            },
            {
                text: 'Transporte - Ponto de parada ida',
                value: 'ponto_parada_ida_nome',
                field: 'bairro',
                table: 'ponto_parada_ida',
            },
            {
                text: 'Transporte - Ponto de parada volta',
                value: 'ponto_parada_volta_nome',
                field: 'bairro',
                table: 'ponto_parada_volta',
            },
            {
                text: 'Cancelado - Data/Hora',
                value: 'cancelado_data_hora',
                table: 'contrato',
            },
            {
                text: 'Cancelado - Motivo',
                value: 'cancelado_motivo',
                table: 'contrato',
            },
            {
                text: 'Cancelado - Notif. Assinada',
                value: 'cancelado_notificacao_assinada_pelo_aluno',
                table: 'contrato',
            },
            {
                text: 'Cancelado - Usuário efetuou cancelamento',
                field: 'full_name',
                value: 'cancelado_usuario_efetuou_nome',
                table: 'cancelado_usuario_efetuou',
            },
            {
                text: 'Consultor',
                value: 'consultor_nome',
                field: 'full_name',
                table: 'consultor',
            },
            {
                text: 'Trancado - Previsão de retorno',
                value: 'trancado_previsao_retorno',
                table: 'contrato',
            },
            {
                text: 'Trancado - Data/Hora',
                value: 'trancado_data_hora',
                table: 'contrato',
            },
            {
                text: 'Trancado - Usuário efetuou trancamento',
                value: 'trancado_por',
                field: 'full_name',
                table: 'trancado_user',
            },
            {
                text: 'Valor da Parcela Integral',
                value: 'valor_parcela_integral',
                table: 'tmp',
            },
        ];
    }
    get totals() {
        return analyticsStore.totals;
    }
    async buscar() {
        await this.doSearchTotals();
        analyticsStore.executeSearchItems();
    }
    async doSearchItems(queryIn) {
        return await contratoStore.getContratoQuery(queryIn);
    }
    async doSearchTotals() {
        // return await analyticsStore.getContrato();
        return await analyticsStore.getAlunoPorContrato(true);
    }
    async mounted() {
        analyticsStore.initAnalytics();
        analyticsStore.setFnSearchItems(contratoStore.getContratoQuery);
        analyticsStore.setHeaders(this.headers);
        // tela que tem defaultFilter nao precisa ter o buscar() aqui
        this.buscar();
    }
};
Contrato = __decorate([
    Component({
        components: {
            OpenNewTabComponent,
            GenericListTotalCardComponent,
            GenericListItemsCardComponent,
        },
    })
], Contrato);
export default Contrato;
