export function newReposicao() {
    return {
        atividade_id: 0,
        company_id: 0,
        data_hora: null,
        professor_id: null,
        professor_turma_id: null,
        sala_id: 0,
        status: null,
        observacao: null,
        descricao: null,
        tipo: 0,
        valor: 0,
        aprovada: null,
        motivo_id: null,
    };
}
