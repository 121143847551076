import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { financeiroParcelaStore } from '@/store';
import PixIntegradorPagamentoDialog from '@/components/PixIntegradorPagamentoDialog.vue';
import PlugnotasConsultaEmiteButton from '@/components/PlugnotasConsultaEmiteButton.vue';
import FormasPagamentoComponent from '@/components/FormasPagamentoComponent.vue';
import ShowDialog from '@/components/ShowDialog.vue';
let FinanceiroList = class FinanceiroList extends Vue {
    constructor() {
        super(...arguments);
        this.financeiroParcelaHeaders = [
            { text: 'Vencimento', value: 'vencimento' },
            { text: 'Valor', value: 'valor_original' },
            { text: '', value: 'status' },
            { text: 'Observação', value: 'financeiro_observacao' },
            { text: 'Nota', value: 'nota' },
        ];
        this.loading = true;
        this.selectedItem = {};
        this.dialogFormasPagamento = null;
        this.financeiroParcelaId = null;
        this.valorOriginal = null;
    }
    get financeiroParcelasItems() {
        return financeiroParcelaStore.financeiroParcelasDrive;
    }
    async showPix(item) {
        // @ts-ignore
        const form = this.$refs.showPagamentoDialog;
        form.show(item);
    }
    async handleOpenFormasPagamento(financeiroParcelaId, valorOriginal) {
        const refs = this.$refs.dialogFormasPagamento;
        refs.dialog = true;
        this.financeiroParcelaId = financeiroParcelaId;
        this.valorOriginal = valorOriginal;
    }
    async mounted() {
        this.loading = true;
        await financeiroParcelaStore.getFinanceiroParcelasDrive();
        this.loading = false;
    }
};
FinanceiroList = __decorate([
    Component({
        components: {
            PixIntegradorPagamentoDialog,
            PlugnotasConsultaEmiteButton,
            FormasPagamentoComponent,
            ShowDialog
        },
    })
], FinanceiroList);
export default FinanceiroList;
