import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ocorrenciaStore, mainStore, analyticsStore, userToolStore } from '@/store';
import SearchEntidadeComponent from '@/components/SearchEntidadeComponent.vue';
import UploadFilesComponent from '@/components/UploadFilesComponent.vue';
import ClickableCard from '@/components/ClickableCard.vue';
import TarefaChangeStatusComponent from '@/components/TarefaChangeStatusComponent.vue';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
import EntidadeLinkComponent from '@/components/EntidadeLinkComponent.vue';
import GenericListTotalCardComponent from '@/components/GenericListTotalCardComponent.vue';
import GenericListItemsCardComponent from '@/components/GenericListItemsCardComponent.vue';
import TextExpandableComponent from '@/components/TextExpandableComponent.vue';
import CriarOcorrenciaComponent from '@/components/CriarOcorrenciaComponent.vue';
import { ValidationObserver, ValidationProvider, Validator } from 'vee-validate';
Validator.extend('required', {
    getMessage: field => `${field} deve ser preenchido!`,
    validate: async (value) => !!value
});
let OcorrenciaComponent = class OcorrenciaComponent extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            {
                text: 'Data',
                value: 'created_at',
                table: 'ocorrencia',
                show: true,
                type: 'date'
            },
            {
                text: 'Criado por',
                table: 'ocorrencia',
                value: 'created_by_id',
                show: true
                // type: 'none',
            },
            {
                text: 'Departamento',
                table: 'ocorrencia',
                value: 'departamento_id',
                show: true
                // type: 'int4',
            },
            {
                text: 'Contato',
                table: 'objecao',
                value: 'objecao_nome',
                field: 'nome',
                show: true
            },
            {
                text: 'Assunto',
                table: 'objecao',
                value: 'assunto',
                show: true
            },
            {
                text: 'Entidade',
                table: 'ocorrencia',
                value: 'entidade_id',
                type: 'date',
                show: true
            },
            {
                text: 'Observação',
                table: 'ocorrencia',
                value: 'observacao',
                type: 'str',
                show: true
            },
            {
                text: 'Ações',
                value: 'actions',
                show: true
            }
        ];
        this.e1 = 1;
        this.dialogCriarOcorrencia = false;
        this.entidadeLabel = '';
        this.limit = 30;
        this.skip = 0;
        this.page = 1;
        this.qtdPages = 0;
        this.loading = true;
        this.showAll = true;
        this.filtroTipoEntidadeSelected = [];
        this.filtroEntidadeSelected = {};
        this.selectPeriodDate = [];
        this.renderSearchComponent = true;
        this.ocorrenciasBy = [];
        this.ocorrenciaItems = [];
        this.ocorrenciaUploadFiles = [];
        this.etapaAtual = 1;
        this.taxaMatricula = 0;
        this.renderComponent = 0;
        this.percentualDescontoSelected = null;
        this.cobrarTaxaMatriculaSelected = null;
        this.periodoSelected = [];
        this.ultimoPeriodo = [];
        this.turmaSelected = null;
        this.transporteItems = [];
        this.tarefaRealizadaDialog = false;
        this.tarefaCanceladaDialog = false;
        this.ocorrenciaTarefaId = null;
        this.dialogAnexos = false;
        this.loadingAnexos = false;
        this.carregandoTotals = false;
        this.anexosSelected = [];
        this.selectedOcorrenciaShowAnexos = null;
        this.passouBuscaInicial = false;
        this.fezBuscaComData = false;
        this.alterarResponsavelTarefaDialog = false;
        this.alterarResponsavelTarefaSelected = {};
        this.alterarResponsavelTarefaTargetSelected = {};
        this.anexosHeaders = [
            { text: 'Nome', value: 'blob.name' },
            { text: 'Tamanho', value: 'tamanho' },
            { text: 'Ações', value: 'actions' }
        ];
        this.tarefas = [];
        // @ts-ignore
        this.options = {
            duration: 200,
            offset: 67,
            easing: 'linear'
        };
    }
    get escolaId() {
        return mainStore.userProfile.company.escola_id;
    }
    get totals() {
        return analyticsStore.totals;
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed['Gestão de ocorrências']) {
            return userToolStore.userToolsIndexed['Gestão de ocorrências'][0]
                .allow_delete;
        }
    }
    async deleteItem(item) {
        this.$swal({
            title: 'Deseja realmente excluir a ocorrência?',
            text: 'Ao deletar uma ocorrência, as tarefas e anexos a ela atrelados serão perdidos!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            confirmButtonText: 'Sim, eu confirmo!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await ocorrenciaStore.deleteOcorrencia(item.id);
                this.buscar();
            }
        });
    }
    alterarResponsavel(tarefa_item) {
        this.alterarResponsavelTarefaSelected = tarefa_item;
        this.alterarResponsavelTarefaDialog = true;
    }
    async atualizarResponsavel() {
        this.alterarResponsavelTarefaDialog = false;
        // @ts-ignore
        if (!this.alterarResponsavelTarefaTargetSelected.id) {
            return;
        }
        const obj = {
            // @ts-ignore
            id: this.alterarResponsavelTarefaSelected.tarefa.id,
            // @ts-ignore
            responsavel_id: this.alterarResponsavelTarefaTargetSelected.id,
        };
        await ocorrenciaStore.updateOcorrenciaTarefa(obj);
        this.buscar();
    }
    async excluirAnexo(item) {
        const payload = {
            full_name: item.blob.full_name,
            entidade_tipo: this.selectedOcorrenciaShowAnexos.entidade_tipo
        };
        await ocorrenciaStore.deleteOcorrenciaAnexo(payload);
        await this.onClickMostrarAnexos(this.selectedOcorrenciaShowAnexos);
    }
    async onClickMostrarAnexos(item) {
        this.loadingAnexos = true;
        this.dialogAnexos = true;
        this.selectedOcorrenciaShowAnexos = item;
        let anexos = [];
        anexos = await ocorrenciaStore.getOcorrenciaAnexos(item);
        this.anexosSelected = anexos;
        this.loadingAnexos = false;
    }
    getAnexoSize(size) {
        if (size / 1000 < 1000) {
            const value = (size / 1000).toFixed(2);
            return `${value} kB`;
        }
        else if (size / 1000 >= 1000) {
            const value = (size / 1000000).toFixed(2);
            return `${value} MB`;
        }
    }
    async tarefaRealizada(id) {
        this.ocorrenciaTarefaId = id;
        this.tarefaRealizadaDialog = true;
    }
    async tarefaCancelada(id) {
        this.ocorrenciaTarefaId = id;
        this.tarefaCanceladaDialog = true;
    }
    async baixarAnexo(item) {
        /**
         * codigo abaixo não utilizado pois o carregamento da tela já traz a url do arquivo.
         * issue: https://github.com/drive-tech-lab/drive/issues/179
         */
        // const clickedBlob = await ocorrenciaStore.getOcorrenciaAnexo({
        //   id: 810926,
        //   entidade_tipo: 'aluno',
        //   full_name: 'ocorrencia-810926-Ana Luisa Muller ficha financeira 1.pdf',
        // });
        window.open(item.download_url, '_blank').focus();
    }
    async onCancel() {
        this.dialogCriarOcorrencia = false;
        if (Object.keys(this.totals).length > 0) {
            await this.buscarTotals();
        }
        this.buscar();
    }
    async doSearchTotals() {
        const payload = {
            data_inicial: null,
            data_final: null,
            entidade_id: this.propEntidadeId ? this.propEntidadeId : null,
            entidade_tipo: this.propEntidadeTipo ? this.propEntidadeTipo : null
        };
        if (this.selectPeriodDate.length > 0 &&
            !this.propShowAll &&
            !this.propEntidadeId &&
            this.fezBuscaComData) {
            payload.data_inicial = this.selectPeriodDate[0];
            payload.data_final = this.selectPeriodDate[1];
        }
        if (this.filtroEntidadeSelected) {
            if (Object.keys(this.filtroEntidadeSelected).length > 0) {
                // @ts-ignore
                payload.entidade_id = this.filtroEntidadeSelected.id;
            }
        }
        return await analyticsStore.getAnalyticsOcorrencia(payload);
    }
    async buscar(mounted = false) {
        if (this.selectPeriodDate.length === 0 &&
            !mounted &&
            !this.propShowAll &&
            !this.propEntidadeId) {
            // usando esse timeout para caso o componente de período não tenha dado um valor,
            // a função é chamada de volta depois de 10ms, para dar tempo de ter um valor no período
            this.passouBuscaInicial = true;
            setTimeout(() => {
                this.buscar(mounted);
            }, 10);
            return;
        }
        const filters = {};
        if (this.selectPeriodDate.length > 0 &&
            !mounted &&
            !this.propShowAll &&
            !this.propEntidadeId) {
            filters['ocorrencia.created_at'] = {
                type: 'timestamp',
                value: [this.selectPeriodDate[0], this.selectPeriodDate[1]]
            };
            this.fezBuscaComData = true;
        }
        if (this.propEntidadeId) {
            filters['ocorrencia.entidade_id'] = {
                type: 'int',
                value: [this.propEntidadeId]
            };
        }
        if (this.filtroEntidadeSelected) {
            if (Object.keys(this.filtroEntidadeSelected).length > 0) {
                filters['ocorrencia.entidade_id'] = {
                    type: 'int',
                    // @ts-ignore
                    value: [this.filtroEntidadeSelected.id]
                };
            }
        }
        if (this.propEntidadeTipo) {
            filters['ocorrencia.entidade_tipo'] = {
                type: 'str',
                value: [this.propEntidadeTipo]
            };
        }
        if (this.propEntidadeTipo == 'company') {
            filters['ocorrencia_relacionamento_unidade.unidade_id'] = {
                type: 'int',
                value: [this.propEntidadeId]
            };
        }
        analyticsStore.setDefaultFilters(filters);
        await analyticsStore.executeSearchItems();
        if (this.fezBuscaComData) {
            this.buscarTotals();
        }
    }
    async doSearchItems(queryIn) {
        return await ocorrenciaStore.getOcorrenciasQuery(queryIn);
    }
    async buscarTotals() {
        this.carregandoTotals = true;
        await this.doSearchTotals();
        this.carregandoTotals = false;
    }
    async mounted() {
        if (this.propEntidadeLabel) {
            this.entidadeLabel = this.propEntidadeLabel;
        }
        else {
            this.entidadeLabel = '';
        }
        analyticsStore.initAnalytics();
        analyticsStore.setHeaders(this.headers);
        analyticsStore.setFnSearchItems(ocorrenciaStore.getOcorrenciasQuery);
        this.buscar(true);
        this.loading = false;
    }
};
__decorate([
    Prop()
], OcorrenciaComponent.prototype, "propEntidadeId", void 0);
__decorate([
    Prop()
], OcorrenciaComponent.prototype, "propEntidadeTipo", void 0);
__decorate([
    Prop({ type: Number, default: null })
], OcorrenciaComponent.prototype, "propToolId", void 0);
__decorate([
    Prop({ type: String, default: '' })
], OcorrenciaComponent.prototype, "propEntidadeLabel", void 0);
__decorate([
    Prop({ type: Array, default: () => [] })
], OcorrenciaComponent.prototype, "propDepartamentoOcorrenciaId", void 0);
__decorate([
    Prop()
], OcorrenciaComponent.prototype, "readonly", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], OcorrenciaComponent.prototype, "propShowAll", void 0);
OcorrenciaComponent = __decorate([
    Component({
        components: {
            SearchEntidadeComponent,
            UploadFilesComponent,
            ValidationObserver,
            ValidationProvider,
            ClickableCard,
            TarefaChangeStatusComponent,
            DateFieldComponent,
            EntidadeLinkComponent,
            PeriodSelectorComponent,
            GenericListItemsCardComponent,
            GenericListTotalCardComponent,
            TextExpandableComponent,
            CriarOcorrenciaComponent
        }
    })
], OcorrenciaComponent);
export default OcorrenciaComponent;
