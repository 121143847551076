import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
import _ from 'lodash';
let UserActionModule = class UserActionModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.userActions = [];
        this.alteracaoDeTitulosEmLoteValorDescontoVencimentoAllow = false;
        this.alteracaoDeTitulosEmLoteValorPagoStatusAllow = false;
        this.baixarTituloAllow = false;
        this.quitadoAMenorAllow = false;
        this.cancelarTituloAllow = false;
        this.renegociarTituloAllow = false;
        this.alterarVencimentoDeTituloObject = null;
        this.alterarVencimentoDeTituloAllow = false;
        this.alterarVencimentoDeTituloRequestPermission = false;
        this.protestarTituloAllow = false;
        this.lancamentoDeBaixaManualAllow = false;
        this.incluirTransporteNoContratoAllow = false;
        this.criarContratoAllow = false;
        this.encerrarContratoAllow = false;
        this.dashboardFinanceiroAllow = false;
        this.dashboardMeuCaixaAllow = false;
        this.dashboardReposiçãoAllow = false;
        this.dashboardReforcoAllow = false;
        this.dashboardCobrancaAllow = false;
        this.dashboardPedagogicoAllow = false;
        this.dashboardRecepcaoAllow = false;
        this.dashboardRetencaoAllow = false;
        this.dashboardRematriculaAllow = false;
        this.dashboardEntregaLivroAllow = false;
        this.dashboardTarefasEReunioesPendentesAllow = false;
        this.dashboardProfessorAllow = false;
        this.distribuirCuponsEntreCdasAllow = false;
        this.conferirCaixaAllow = false;
        this.fazerEntradaCaixaAllow = false;
        this.fazerRetiradaCaixaAllow = false;
        this.estornarBaixaObject = null;
        this.estornarBaixaAllow = false;
        this.estornarBaixaRequestPermission = false;
        this.estornarBaixaTituloCaixaConferidoObject = null;
        this.estornarBaixaTituloCaixaConferidoAllow = false;
        this.estornarBaixaTituloCaixaConferidoRequestPermission = false;
        this.rescindirContratoAllow = false;
        this.trancarContratoAllow = false;
        this.adicionarEstagioContrato = false;
        this.excluirContratoAllow = false;
        this.excluirContratoObject = null;
        this.excluirContratoRequestPermission = false;
        this.excluirContratoComMovimentacaoObject = null;
        this.excluirContratoComMovimentacaoAllow = false;
        this.excluirContratoComMovimentacaoRequestPermission = false;
        this.acessoAOLogDoSistema = false;
        this.removerAlunoTurma = false;
        this.evadirAlunoTurma = false;
        this.transferirAlunoTurma = false;
        this.editarCronogramaAllow = false;
        this.cancelarTurmaAllow = false;
        this.cancelarTurmaRequestPermission = false;
        this.cancelarTurmaObject = null;
        this.editarContratoAllow = false;
        this.editarCursoContratoAllow = false;
        this.visualizarFinanceiroAllow = false;
        this.visualizarTelefonesContatosAllow = false;
        this.visualizarContratosAllow = false;
        this.entregarLivroAllow = false;
        this.solicitarLivroFranchisingObject = null;
        this.solicitarLivroFranchisingAllow = false;
        this.solicitarLivroFranchisingRequestPermission = false;
        this.excluirEstagioAllow = false;
        this.alterarEstagioAllow = false;
        this.colocarAlunoTurmaAllow = false;
        this.visualizarInfoFinanceiraAlunoAbaGestaoAllow = false;
        this.visualizarAbaDadosCadastraisAllow = false;
        this.visualizarAdmnistrativoAlunoAbaGestaoAllow = false;
        this.permiteExportarDados = false;
        this.visualizarTarefasUsuariosMapaHorarios = false;
        this.excluirCuponsEmLote = false;
        this.permitirAlteracaoDaFichaDeFrequenciaDeAlunoNaoVigente = false;
        this.adquirirCreditosNotaFiscal = false;
        this.adicionarAnexos = false;
        this.excluirAnexos = false;
        this.visualizarTotalizadoresContasReceber = false;
        this.visualizarTotalizadoresContasPagar = false;
        this.excluirAlunoFinanceiroContrato = false;
        this.visualizarTodosTickets = false;
        this.permiteCompartilhamentoTurmasAtHome = false;
        this.visualizarAbaPedagogicoAllow = false;
        this.visualizarAbaFinanceiroAllow = false;
        this.visualizarAbaComercialAllow = false;
        this.visualizarDashboardPerformance = false;
        this.visualizarAnexosMovimentacoesCaixa = false;
        this.permiteAlterarJornadaTrabalhoColaborador = false;
        this.permiteAlterarPermissoesColaborador = false;
    }
    setUserActions(payload) {
        this.userActions = payload;
        this.acessoAOLogDoSistema = _.find(payload, (item) => item.name === 'Acesso ao log do sistema')?.allow;
        this.adicionarEstagioContrato = _.find(payload, (item) => item.name === 'Adicionar estágio no contrato')?.allow;
        this.alteracaoDeTitulosEmLoteValorDescontoVencimentoAllow = _.find(payload, (item) => item.name === 'Alteração de títulos em lote - Valor, desconto e vencimento')?.allow;
        this.alteracaoDeTitulosEmLoteValorPagoStatusAllow = _.find(payload, (item) => item.name === 'Alteração de títulos em lote - Valor pago e status')?.allow;
        this.alterarEstagioAllow = _.find(payload, (item) => item.name === 'Alterar estágio')?.allow;
        this.alterarVencimentoDeTituloAllow = _.find(payload, (item) => item.name === 'Alterar vencimento de título')?.allow;
        this.alterarVencimentoDeTituloObject = _.find(payload, (item) => item.name === 'Alterar vencimento de título');
        this.alterarVencimentoDeTituloRequestPermission = _.find(payload, (item) => item.name === 'Alterar vencimento de título')?.request_permission;
        this.quitadoAMenorAllow = _.find(payload, (item) => item.name === 'Quitado a menor')?.allow;
        this.baixarTituloAllow = _.find(payload, (item) => item.name === 'Baixar título')?.allow;
        this.cancelarTituloAllow = _.find(payload, (item) => item.name === 'Cancelar título')?.allow;
        this.colocarAlunoTurmaAllow = _.find(payload, (item) => item.name === 'Colocar aluno em turma')?.allow;
        this.conferirCaixaAllow = _.find(payload, (item) => item.name === 'Conferir caixa')?.allow;
        this.criarContratoAllow = _.find(payload, (item) => item.name === 'Criar contrato')?.allow;
        this.dashboardCobrancaAllow = _.find(payload, (item) => item.name === 'Dashboard Cobrança')?.allow;
        this.dashboardEntregaLivroAllow = _.find(payload, (item) => item.name === 'Dashboard Entrega de livro')?.allow;
        this.dashboardFinanceiroAllow = _.find(payload, (item) => item.name === 'Dashboard Financeiro')?.allow;
        this.dashboardMeuCaixaAllow = _.find(payload, (item) => item.name === 'Dashboard Meu caixa')?.allow;
        this.dashboardPedagogicoAllow = _.find(payload, (item) => item.name === 'Dashboard Pedagógico')?.allow;
        this.dashboardProfessorAllow = _.find(payload, (item) => item.name === 'Dashboard Professor')?.allow;
        this.dashboardRecepcaoAllow = _.find(payload, (item) => item.name === 'Dashboard Recepção')?.allow;
        this.dashboardRematriculaAllow = _.find(payload, (item) => item.name === 'Dashboard Rematrícula')?.allow;
        this.dashboardReposiçãoAllow = _.find(payload, (item) => item.name === 'Dashboard Reposição')?.allow;
        this.dashboardReforcoAllow = _.find(payload, (item) => item.name === 'Dashboard Reforço')?.allow;
        this.dashboardRetencaoAllow = _.find(payload, (item) => item.name === 'Dashboard Retenções')?.allow;
        this.dashboardTarefasEReunioesPendentesAllow = _.find(payload, (item) => item.name === 'Dashboard Tarefas e reuniões pendentes')?.allow;
        this.distribuirCuponsEntreCdasAllow = _.find(payload, (item) => item.name === 'Distribuir cupons entre CDAs')?.allow;
        this.editarContratoAllow = _.find(payload, (item) => item.name === 'Editar contrato')?.allow;
        this.editarCursoContratoAllow = _.find(payload, (item) => item.name === 'Editar curso contrato')?.allow;
        this.editarCronogramaAllow = _.find(payload, (item) => item.name === 'Editar cronograma')?.allow;
        this.encerrarContratoAllow = _.find(payload, (item) => item.name === 'Encerrar contrato')?.allow;
        this.entregarLivroAllow = _.find(payload, (item) => item.name === 'Entregar livro')?.allow;
        this.solicitarLivroFranchisingObject = _.find(payload, (item) => item.name === 'Solicitar livro para a Franchising');
        this.solicitarLivroFranchisingAllow = _.find(payload, (item) => item.name === 'Solicitar livro para a Franchising')?.allow;
        this.solicitarLivroFranchisingRequestPermission = _.find(payload, (item) => item.name === 'Solicitar livro para a Franchising')?.request_permission;
        this.evadirAlunoTurma = _.find(payload, (item) => item.name === 'Evadir aluno de turma')?.allow;
        this.estornarBaixaObject = _.find(payload, (item) => item.name === 'Estornar baixa de título que NÃO possua caixa conferido');
        this.estornarBaixaAllow = _.find(payload, (item) => item.name === 'Estornar baixa de título que NÃO possua caixa conferido')?.allow;
        this.estornarBaixaRequestPermission = _.find(payload, (item) => item.name === 'Estornar baixa de título que NÃO possua caixa conferido')?.request_permission;
        this.estornarBaixaTituloCaixaConferidoObject = _.find(payload, (item) => item.name === 'Estornar baixa de título MESMO que o caixa tenha sido conferido');
        this.estornarBaixaTituloCaixaConferidoAllow = _.find(payload, (item) => item.name === 'Estornar baixa de título MESMO que o caixa tenha sido conferido')?.allow;
        this.estornarBaixaTituloCaixaConferidoRequestPermission = _.find(payload, (item) => item.name === 'Estornar baixa de título MESMO que o caixa tenha sido conferido')?.request_permission;
        this.excluirContratoObject = _.find(payload, (item) => item.name === 'Excluir SOMENTE contrato que NÃO possui movimentação financeira/pedagógica');
        this.excluirContratoAllow = _.find(payload, (item) => item.name === 'Excluir SOMENTE contrato que NÃO possui movimentação financeira/pedagógica')?.allow;
        this.excluirContratoRequestPermission = _.find(payload, (item) => item.name === 'Excluir SOMENTE contrato que NÃO possui movimentação financeira/pedagógica')?.request_permission;
        this.excluirContratoComMovimentacaoObject = _.find(payload, (item) => item.name === 'Excluir contrato MESMO que ele POSSUA financeira/pedagógica');
        this.excluirContratoComMovimentacaoAllow = _.find(payload, (item) => item.name === 'Excluir contrato MESMO que ele POSSUA financeira/pedagógica')?.allow;
        this.excluirContratoComMovimentacaoRequestPermission = _.find(payload, (item) => item.name === 'Excluir contrato MESMO que ele POSSUA financeira/pedagógica')?.request_permission;
        this.cancelarTurmaObject = _.find(payload, (item) => item.name === 'Cancelar turma');
        this.cancelarTurmaAllow = _.find(payload, (item) => item.name === 'Cancelar turma')?.allow;
        this.cancelarTurmaRequestPermission = _.find(payload, (item) => item.name === 'Cancelar turma')?.request_permission;
        this.excluirEstagioAllow = _.find(payload, (item) => item.name === 'Excluir estágio')?.allow;
        this.fazerEntradaCaixaAllow = _.find(payload, (item) => item.name === 'Fazer entrada')?.allow;
        this.fazerRetiradaCaixaAllow = _.find(payload, (item) => item.name === 'Fazer retirada')?.allow;
        this.incluirTransporteNoContratoAllow = _.find(payload, (item) => item.name === 'Incluir transporte no contrato')?.allow;
        this.lancamentoDeBaixaManualAllow = _.find(payload, (item) => item.name === 'Lançamento de baixa manual')?.allow;
        this.protestarTituloAllow = _.find(payload, (item) => item.name === 'Protestar título')?.allow;
        this.removerAlunoTurma = _.find(payload, (item) => item.name === 'Remover aluno de turma')?.allow;
        this.renegociarTituloAllow = _.find(payload, (item) => item.name === 'Renegociar título')?.allow;
        this.rescindirContratoAllow = _.find(payload, (item) => item.name === 'Rescindir contrato')?.allow;
        this.trancarContratoAllow = _.find(payload, (item) => item.name === 'Trancar contrato')?.allow;
        this.transferirAlunoTurma = _.find(payload, (item) => item.name === 'Transferir aluno de turma')?.allow;
        this.visualizarContratosAllow = _.find(payload, (item) => item.name === 'Visualizar Aba - Contratos')?.allow;
        this.visualizarFinanceiroAllow = _.find(payload, (item) => item.name === 'Visualizar Aba - Financeiro')?.allow;
        this.visualizarTelefonesContatosAllow = _.find(payload, (item) => item.name === 'Visualizar Aba - Telefones e Contatos')?.allow;
        this.visualizarInfoFinanceiraAlunoAbaGestaoAllow = _.find(payload, (item) => item.name === 'Visualizar informações financeiras dos alunos na aba Gestão')?.allow;
        this.visualizarAbaDadosCadastraisAllow = _.find(payload, (item) => item.name === 'Visualizar Aba - Dados cadastrais')?.allow;
        this.visualizarAdmnistrativoAlunoAbaGestaoAllow = _.find(payload, (item) => item.name === 'Visualizar informações administrativas dos alunos na aba Gestão')?.allow;
        this.permiteExportarDados = _.find(payload, (item) => item.name === 'Permite exportar dados')?.allow;
        this.visualizarTarefasUsuariosMapaHorarios = _.find(payload, (item) => item.name === 'Visualizar tarefas de outros usuários no mapa de horários')?.allow;
        this.excluirCuponsEmLote = _.find(payload, (item) => item.name === 'Excluir cupons em lote')?.allow;
        this.permitirAlteracaoDaFichaDeFrequenciaDeAlunoNaoVigente = _.find(payload, (item) => item.name === 'Permitir alteração da ficha de frequência de aluno não vigente')?.allow;
        this.adquirirCreditosNotaFiscal = _.find(payload, (item) => item.name === 'Adquirir créditos para emissão de nota fiscal')?.allow;
        this.adicionarAnexos = _.find(payload, (item) => item.name === 'Adicionar anexos')?.allow;
        this.visualizarTotalizadoresContasReceber = _.find(payload, (item) => item.name === 'Visualizar os totalizadores' && item.action_id === 63)?.allow;
        this.visualizarTotalizadoresContasPagar = _.find(payload, (item) => item.name === 'Visualizar os totalizadores' && item.action_id === 64)?.allow;
        this.visualizarTodosTickets = _.find(payload, (item) => item.name === 'Visualizar todos os tickets')?.allow;
        this.excluirAlunoFinanceiroContrato = _.find(payload, (item) => item.name === 'Excluir aluno, financeiro, contrato' && item.action_id === 65)?.allow;
        this.permiteCompartilhamentoTurmasAtHome = _.find(payload, (item) => item.name === 'Permite compartilhamento turmas online' && item.action_id === 67)?.allow;
        this.visualizarAbaPedagogicoAllow = _.find(payload, (item) => item.name === 'Visualizar Aba - Pedagógico' && item.action_id === 68)?.allow;
        this.visualizarAbaFinanceiroAllow = _.find(payload, (item) => item.name === 'Visualizar Aba - Financeiro' && item.action_id === 69)?.allow;
        this.visualizarAbaComercialAllow = _.find(payload, (item) => item.name === 'Visualizar Aba - Comercial' && item.action_id === 70)?.allow;
        this.visualizarDashboardPerformance = _.find(payload, (item) => item.name === 'Visualizar dashboard - Performance' && item.action_id === 71)?.allow;
        this.visualizarAnexosMovimentacoesCaixa = _.find(payload, (item) => item.name === 'Visualizar anexos de movimentações do caixa')?.allow;
        this.permiteAlterarJornadaTrabalhoColaborador = _.find(payload, (item) => item.name === 'Permite alterar Jornada de Trabalho' && item.action_id === 74)?.allow;
        this.permiteAlterarPermissoesColaborador = _.find(payload, (item) => item.name === 'Permite alterar Permissões Colaborador' && item.action_id === 75)?.allow;
    }
    async getUserActions() {
        try {
            const response = await api.getUserActions(mainStore.token);
            if (response) {
                this.setUserActions(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], UserActionModule.prototype, "setUserActions", null);
__decorate([
    Action
], UserActionModule.prototype, "getUserActions", null);
UserActionModule = __decorate([
    Module({ name: 'userAction' })
], UserActionModule);
export default UserActionModule;
