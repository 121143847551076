import { __decorate } from "tslib";
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { userActionStore, utilsStore } from '@/store';
import _ from 'lodash';
let ImprimirTabelaGenericListComponent = class ImprimirTabelaGenericListComponent extends Vue {
    constructor() {
        super(...arguments);
        this.dialogImprimirTabela = false;
        this.pdfOptions = false;
        this.selected = 2;
        this.refs = [];
    }
    onChangePorpRef(item) {
        this.refs = item;
    }
    get permiteExportarDados() {
        return userActionStore.permiteExportarDados;
    }
    async imprimirTabela() {
        const modo = this.selected;
        const Arrayitems = [];
        const Arrayheaders = [];
        _.forEach(this.refs, (ref) => {
            if (ref) {
                const items = [];
                const headers = [];
                const html = ref.$el.innerHTML;
                const parser = new DOMParser();
                const doc = parser.parseFromString(html, 'text/html');
                const headersRows = doc.querySelectorAll('thead tr th');
                const headersFilterRow = doc.getElementById('tr-filter');
                const rows = doc.querySelectorAll('tbody tr td');
                const tempItems = [];
                const tempHeaders = [];
                rows.forEach((item) => {
                    // caso seja necessário futuramente, podemos achar caracteres de checkbox e icones assim:
                    // && !(item.getElementsByClassName('v-input--checkbox').length > 0 ||
                    // item.getElementsByClassName('v-icon').length > 0)
                    // @ts-ignore
                    let continua = false;
                    if (this.propDashboardFinanceiro) {
                        continua =
                            item.innerText ||
                                (item.innerText == '' && item.innerHTML !== '') ||
                                !item.innerHTML.includes('<!---->');
                    }
                    else {
                        continua =
                            item.innerText || (item.innerText == '' && item.innerHTML !== '');
                    }
                    if (continua) {
                        // remove o botao expandir
                        item.innerHTML = item.innerHTML.replace(/<button.*<\/button>/, '');
                        if (modo == 0 || modo == 1) {
                            tempItems.push(item.innerHTML);
                        }
                        else {
                            if (item.innerText.search(/\d\.\d{3},/gm)) {
                                // se for dinheiro
                                item.innerText = item.innerText.replace('.', '');
                                item.innerText = item.innerText.replace(',', '.');
                            }
                            tempItems.push(item.innerText);
                        }
                    }
                });
                let headersLen = 0;
                let headersInit = 0;
                let headersFinal = 0;
                headersRows.forEach((item) => {
                    // @ts-ignore
                    if (item.innerText) {
                        // @ts-ignore
                        tempHeaders.push(item.innerText);
                        headersLen += 1;
                        // @ts-ignore
                    }
                    else if (item.innerText == '') {
                        if (tempHeaders.length > 0) {
                            headersFinal += 1;
                        }
                        else {
                            headersInit += 1;
                        }
                    }
                });
                let i = 0;
                if (headersFilterRow) {
                    i = headersLen + headersInit + headersFinal;
                }
                for (i; i < tempItems.length; i = i + headersLen + headersInit + headersFinal) {
                    items.push(tempItems.slice(i + headersInit, i + headersLen + headersInit));
                }
                for (let idx = 0; idx < tempHeaders.length; idx = idx + headersLen) {
                    headers.push(tempHeaders.slice(idx, idx + headersLen));
                }
                Arrayitems.push(items);
                Arrayheaders.push(headers);
            }
        });
        if (modo == 0 || modo == 1) {
            const html2 = await utilsStore.utilsImprimirTabela({
                payload: {
                    title: this.propTitle,
                    headers: Arrayheaders,
                    table_items: Arrayitems,
                    orientacao: modo,
                },
            });
            if (html2) {
                const w = window.open();
                w.document.write(html2);
                setTimeout(() => {
                    w.print();
                }, 1000);
            }
        }
        else {
            const csvArray = [];
            _.forEach(Arrayheaders, (headers) => {
                csvArray.push([headers]);
                _.forEach(Arrayitems[Arrayheaders.indexOf(headers)], (items) => {
                    csvArray.push([items]);
                });
                csvArray.push([]);
            });
            const csvContent = 'data:text/csv;charset=utf-8,' +
                csvArray.map((e) => e.join(',')).join('\n');
            const encodedUri = encodeURI(csvContent);
            window.open(encodedUri);
        }
    }
};
__decorate([
    Prop()
], ImprimirTabelaGenericListComponent.prototype, "propRef", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], ImprimirTabelaGenericListComponent.prototype, "propDashboardFinanceiro", void 0);
__decorate([
    Prop({ type: String, default: '' })
], ImprimirTabelaGenericListComponent.prototype, "propTitle", void 0);
__decorate([
    Watch('propRef')
], ImprimirTabelaGenericListComponent.prototype, "onChangePorpRef", null);
ImprimirTabelaGenericListComponent = __decorate([
    Component
], ImprimirTabelaGenericListComponent);
export default ImprimirTabelaGenericListComponent;
