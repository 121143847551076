import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
let CardSemana = class CardSemana extends Vue {
    constructor() {
        super(...arguments);
        this.headersWeek = [
            {
                text: 'Professor',
                sortable: true,
                value: 'full_name'
            },
            {
                text: 'Total Turmas',
                value: 'numero_turmas'
            },
            {
                text: 'Total Alunos',
                value: 'total'
            },
            {
                text: 'Presença',
                value: 'presenca'
            },
            {
                text: 'Falta',
                value: 'falta'
            },
            {
                text: '% Falta',
                value: 'percentual'
            }
        ];
    }
    statusColors(index) {
        switch (index) {
            case 0:
                return '#EF9A9A';
            case 1:
                return '#F48FB1';
            case 2:
                return '#CE93D8';
            case 3:
                return '#B39DDB';
            case 4:
                return '#80CBC4';
        }
    }
    showWeekHeader(index) {
        switch (index) {
            case 0:
                if (this.propIntervalWeek[4]) {
                    return this.formatDate(this.propIntervalWeek[4][0]) + ' - ' + this.formatDate(this.propIntervalWeek[4][1]);
                }
                break;
            case 1:
                if (this.propIntervalWeek[3]) {
                    return this.formatDate(this.propIntervalWeek[3][0]) + ' - ' + this.formatDate(this.propIntervalWeek[3][1]);
                }
                break;
            case 2:
                if (this.propIntervalWeek[2]) {
                    return this.formatDate(this.propIntervalWeek[2][0]) + ' - ' + this.formatDate(this.propIntervalWeek[2][1]);
                }
                break;
            case 3:
                if (this.propIntervalWeek[1]) {
                    return this.formatDate(this.propIntervalWeek[1][0]) + ' - ' + this.formatDate(this.propIntervalWeek[1][1]);
                }
                break;
            case 4:
                if (this.propIntervalWeek[0]) {
                    return this.formatDate(this.propIntervalWeek[0][0]) + ' - ' + this.formatDate(this.propIntervalWeek[0][1]);
                }
                break;
        }
        return '';
    }
    get weeks() {
        const weeks = [];
        for (let i = 0; i < 5; i++) {
            const weekItems = this.items.map((item) => ({
                full_name: item.full_name,
                numero_turmas: item[`turmas_semana_${i + 1}`],
                total: item[`total_alunos_${i + 1}`],
                presenca: item[`presenca_semana_${i + 1}`],
                falta: item[`falta_semana_${i + 1}`],
                percentual: this.calculatePercentual(item, i + 1),
                professor_id: item.professor_id
            }));
            weeks.push(weekItems);
        }
        return weeks;
    }
};
__decorate([
    Prop({ type: String, default: '' })
], CardSemana.prototype, "propSearch", void 0);
__decorate([
    Prop({ type: Array, default: () => [] })
], CardSemana.prototype, "items", void 0);
__decorate([
    Prop({ type: Array, default: () => [] })
], CardSemana.prototype, "propHeaderMonth", void 0);
__decorate([
    Prop({ type: Array, default: () => [] })
], CardSemana.prototype, "propIntervalWeek", void 0);
__decorate([
    Prop({ type: Function })
], CardSemana.prototype, "propShow", void 0);
__decorate([
    Prop({ type: Function })
], CardSemana.prototype, "propNumberWeek", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], CardSemana.prototype, "propLoading", void 0);
__decorate([
    Prop({ type: Function })
], CardSemana.prototype, "calculatePercentual", void 0);
CardSemana = __decorate([
    Component
], CardSemana);
export default CardSemana;
