import { __decorate } from "tslib";
import { contratoStore, mainStore, metaIndividualStore, metaStore, prospexStore, } from '@/store';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
let DashboardMetasComponent = class DashboardMetasComponent extends Vue {
    constructor() {
        super(...arguments);
        this.headersConsultor = [
            { text: 'Assinatura do contrato', value: 'created_at' },
            { text: 'Curso', value: 'curso_nome' },
            { text: 'Aluno', value: 'aluno_nome' },
            { text: 'Responsável', value: 'responsavel_nome' },
            { text: 'Contatos', value: 'aluno_contatos' },
            {
                text: 'Primeira parcela do curso',
                value: 'curso_primeira_parcela_status_id',
            },
            { text: 'Taxa de matrícula', value: 'taxa_matricula_valor' },
            { text: 'Percentual de Desconto', value: 'curso_desconto_percentual' },
            { text: 'Turma', value: 'turma_id' },
        ];
        this.headersCDA = [
            {
                text: 'Criado em',
                value: 'created_at',
            },
            {
                text: 'Nome',
                value: 'nome',
            },
            {
                text: 'E-mail',
                value: 'email',
            },
            {
                text: 'Idade',
                value: 'idade',
            },
            {
                text: 'Telefone',
                value: 'telefone',
            },
            { text: 'Status atual', value: 'status_id' },
            { text: 'Ultimo contato', value: 'ultimo_contato' },
            {
                text: 'Campanha',
                value: 'campaign',
            },
            {
                text: 'Indicado por',
                value: 'indicador_nome',
            },
        ];
        this.currentMonth = new Date().getMonth();
        this.firstDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
            .toISOString()
            .split('T')[0];
        this.contratos = [];
        this.metasIndividuais = {};
        this.cursosDisponiveis = [];
        this.search = '';
        this.selectedCurso = null;
        this.showDetails = false;
        this.metasComercial = [];
        this.metasLocal = [];
        this.isLoading = false;
    }
    get currentRoute() {
        return this.$route.name || '';
    }
    get isMetasPage() {
        return this.currentRoute === 'metas';
    }
    get progressMessage() {
        const totalContratos = this.contratos.length;
        const cargo_id = this.userProfile.cargo_id;
        const cargoType = cargo_id === 5 ? 'visitas' : 'matrículas';
        for (let i = 0; i < this.availableMetas.length; i++) {
            if (totalContratos < this.availableMetas[i]) {
                if (i === 0) {
                    return `Começou! 🎯 Primeira meta: <strong>${this.availableMetas[i]} ${cargoType}.</strong> <br>Bora acelerar e alcançar resultados incríveis! 🚀`;
                }
                else {
                    return `🔥 Quase lá! <strong>Falta pouco</strong> para alcançar ${this.availableMetas[i]} ${cargoType}.<br>Continue no ritmo! 🙌`;
                }
            }
        }
        return `🏆 <strong>Parabéns! Todas as metas foram alcançadas.</strong> Você concluiu com sucesso todas as etapas. 🎉`;
    }
    get stepStatus() {
        const totalContratos = this.contratos.length;
        for (let i = 0; i < this.availableMetas.length; i++) {
            if (totalContratos < this.availableMetas[i]) {
                return i + 1;
            }
        }
        return this.availableMetas.length;
    }
    get availableMetas() {
        return [
            this.metasIndividuais.meta_01,
            this.metasIndividuais.meta_02,
            this.metasIndividuais.meta_03,
        ].filter((meta) => meta !== undefined && meta !== null && meta > 0);
    }
    async toggleDetails() {
        this.showDetails = !this.showDetails;
        if (this.showDetails &&
            this.contratos.length === 0 &&
            this.userProfile.cargo_id === 3) {
            const contratos = await contratoStore.getContratoByConsultorMetas({
                consultor_id: this.userProfile?.id,
                data: this.metas[0].mes_geral,
            });
            this.contratos = contratos;
        }
        else if (this.showDetails &&
            this.contratos.length === 0 &&
            this.userProfile.cargo_id === 5) {
            const [year, month] = this.selectedMonth.split('-').map(Number);
            const endDate = new Date(year, month, 1);
            const endDateFormatted = endDate.toISOString().split('T')[0];
            const visitas = await prospexStore.getCdaAtendimentos({
                data_inicial: this.metas[0].mes_geral,
                data_final: endDateFormatted,
            });
            if (Array.isArray(visitas)) {
                this.contratos = visitas.filter((visita) => visita.parceria_cupom.cda_id === this.userProfile.id);
                this.contratos = this.transformDataForCDA(this.contratos);
            }
            else {
                this.contratos = [];
            }
        }
    }
    transformDataForCDA(data) {
        return data.map((item) => ({
            created_at: item.prospex_id.created_at,
            nome: item.prospex_id.nome,
            email: item.prospex_id.email || 'Não informado',
            idade: item.prospex_id.idade,
            telefone: item.prospex_id.celular || 'Não informado',
            status_id: item.prospex_id.status_id,
            ultimo_contato: item.prospex_id.updated_at,
            campaign: item.parceria_cupom?.cda_full_name || 'Não informado',
            indicador_nome: item.prospex_id.responsavel || 'Não informado',
        }));
    }
    get currentMonthTitle() {
        if (!this.isMetasPage) {
            const currentDate = new Date();
            const month = currentDate.getMonth() + 1;
            const year = currentDate.getFullYear();
            const selectedMonth = `${year}-${month < 10 ? '0' : ''}${month}`;
            const monthIndex = parseInt(selectedMonth.split('-')[1], 10) - 1;
            const months = [
                'Janeiro',
                'Fevereiro',
                'Março',
                'Abril',
                'Maio',
                'Junho',
                'Julho',
                'Agosto',
                'Setembro',
                'Outubro',
                'Novembro',
                'Dezembro',
            ];
            return months[monthIndex] || 'Mês inválido';
        }
        else {
            const monthIndex = parseInt(this.selectedMonth.split('-')[1], 10) - 1;
            const months = [
                'Janeiro',
                'Fevereiro',
                'Março',
                'Abril',
                'Maio',
                'Junho',
                'Julho',
                'Agosto',
                'Setembro',
                'Outubro',
                'Novembro',
                'Dezembro',
            ];
            return months[monthIndex] || 'Mês inválido';
        }
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    navigateToMetas() {
        this.$router.push('/main/ComercialMetas/all');
    }
    async onMetasUpdate(newMetas) {
        if (newMetas.length > 0) {
            this.metasLocal = [...newMetas];
            const metasIndividuais = await metaIndividualStore.getMetasIndividualByMetaId(this.metasLocal[0].id);
            this.metasIndividuais = metasIndividuais;
            const contratos = await contratoStore.getContratoByConsultorMetas({
                consultor_id: this.userProfile.id,
                data: this.metasLocal[0].mes_geral,
            });
            this.contratos = contratos;
        }
    }
    async mounted() {
        if (this.isMetasPage) {
            if (this.metas.length > 0) {
                this.metasLocal = [...this.metas];
                const metasIndividuais = await metaIndividualStore.getMetasIndividualByMetaId(this.metasLocal[0].id);
                this.metasIndividuais = metasIndividuais;
            }
        }
        else {
            this.isLoading = true;
            const metas = await metaStore.getMetasByMonth({
                mesGeral: this.firstDayOfMonth,
            });
            this.metasComercial = metas;
            if (this.userProfile.cargo_id === 3) {
                const contratos = await contratoStore.getContratoByConsultorMetas({
                    consultor_id: this.userProfile.id,
                    data: this.metasComercial[0].mes_geral,
                });
                this.contratos = contratos;
            }
            else {
                const [year, month] = this.metasComercial[0].mes_geral
                    .split('-')
                    .map(Number);
                const endDate = new Date(year, month, 1);
                const endDateFormatted = endDate.toISOString().split('T')[0];
                const visitas = await prospexStore.getCdaAtendimentos({
                    data_inicial: this.metasComercial[0].mes_geral,
                    data_final: endDateFormatted,
                });
                this.contratos = Array.isArray(visitas) ? visitas : [];
            }
            const metasIndividuais = await metaIndividualStore.getMetasIndividualByMetaId(this.metasComercial[0].id);
            this.metasIndividuais = metasIndividuais;
            this.isLoading = false;
        }
    }
};
__decorate([
    Prop({ type: Boolean, default: true })
], DashboardMetasComponent.prototype, "showViewMore", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], DashboardMetasComponent.prototype, "showViewDetails", void 0);
__decorate([
    Prop({ type: Array, default: () => [] })
], DashboardMetasComponent.prototype, "metas", void 0);
__decorate([
    Prop({ type: String, default: '' })
], DashboardMetasComponent.prototype, "selectedMonth", void 0);
__decorate([
    Watch('metas')
], DashboardMetasComponent.prototype, "onMetasUpdate", null);
DashboardMetasComponent = __decorate([
    Component
], DashboardMetasComponent);
export default DashboardMetasComponent;
