import { __decorate } from "tslib";
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { api } from '@/api';
import { mainStore } from '@/utils/store-accessor';
let MetaIndividualModule = class MetaIndividualModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.metas_individual = [];
    }
    setMetasIndividual(payload) {
        this.metas_individual = payload;
    }
    addMetaIndividual(payload) {
        this.metas_individual.unshift(payload);
    }
    removeMetaIndividual(id) {
        this.metas_individual = this.metas_individual.filter((metas_individual) => metas_individual.id !== id);
    }
    updateMetaIndividualMutation(payload) {
        const index = this.metas_individual.findIndex((metas_individual) => metas_individual.id === payload.id);
        if (index !== -1) {
            this.metas_individual.splice(index, 1, payload);
        }
    }
    async getMetasIndividual() {
        try {
            const response = await api.getMetasIndividual(mainStore.token);
            if (response && response.data) {
                this.setMetasIndividual(response.data);
            }
            else {
                this.setMetasIndividual([]);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getMetasIndividualByMetaId(meta_id) {
        try {
            const response = await api.getMetasIndividualByMetaId(mainStore.token, meta_id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getAllMetasIndividualByMetaId(meta_id) {
        try {
            const response = await api.getAllMetasIndividualByMetaId(mainStore.token, meta_id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async createMetaIndividual(payload) {
        try {
            const response = await api.createMetaIndividual(mainStore.token, payload);
            if (response && response.data) {
                this.addMetaIndividual(response.data);
                mainStore.addNotification({
                    content: 'Meta criada com sucesso',
                    color: 'success',
                });
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
            return null; // Retorno explícito em caso de erro
        }
    }
    async updateMetaIndividual(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.updateMetaIndividual(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Meta Individual atualizado com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async deleteMetaIndividual(id) {
        try {
            await api.deleteMetaIndividual(mainStore.token, id);
            this.removeMetaIndividual(id);
            mainStore.addNotification({
                content: 'Meta excluída com sucesso',
                color: 'success',
            });
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], MetaIndividualModule.prototype, "setMetasIndividual", null);
__decorate([
    Mutation
], MetaIndividualModule.prototype, "addMetaIndividual", null);
__decorate([
    Mutation
], MetaIndividualModule.prototype, "removeMetaIndividual", null);
__decorate([
    Mutation
], MetaIndividualModule.prototype, "updateMetaIndividualMutation", null);
__decorate([
    Action
], MetaIndividualModule.prototype, "getMetasIndividual", null);
__decorate([
    Action
], MetaIndividualModule.prototype, "getMetasIndividualByMetaId", null);
__decorate([
    Action
], MetaIndividualModule.prototype, "getAllMetasIndividualByMetaId", null);
__decorate([
    Action
], MetaIndividualModule.prototype, "createMetaIndividual", null);
__decorate([
    Action
], MetaIndividualModule.prototype, "updateMetaIndividual", null);
__decorate([
    Action
], MetaIndividualModule.prototype, "deleteMetaIndividual", null);
MetaIndividualModule = __decorate([
    Module({ name: 'meta_individual' })
], MetaIndividualModule);
export default MetaIndividualModule;
