import { __decorate } from "tslib";
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { userStore, mainStore, metaIndividualStore, metaStore, userToolStore, } from '@/store';
import ConfigMetasComponent from '@/components/ConfigMetasComponent.vue';
let EditMetaComponent = class EditMetaComponent extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            { text: 'Colaborador', value: 'full_name', show: true },
            { text: 'Cargo', value: 'cargo_nome', show: true },
            { text: 'Metas', value: 'meta', show: true },
            { text: 'Bonificação (R$)', value: 'bonus', show: true },
            { text: 'Editar/Excluir', value: 'actions', show: true },
        ];
        this.metasIndividuais = [];
        this.search = '';
        this.loading = false;
        this.items = [];
        this.menuGeral = false;
        this.selectedTab = 0;
        this.selectedTabMetas = 0;
        this.editDialog = false;
        this.editDialogAddMetas = false;
        this.selectedItem = null;
        this.somenteAtivos = true;
        this.uniqueIdCounter = 0;
        this.metasLocal = this.metas;
        this.minDate = new Date().toISOString().substr(0, 7);
        this.date = new Date().toISOString().substr(0, 7);
        this.selectedDate = this.selectedMonth || new Date().toISOString().substr(0, 7);
        this.entityObject = {
            mes_geral: this.selectedMonth,
            formattedDateGeral: [],
            mensalidades: this.metasLocal[0].qtd_mensalidades,
            qtdCupons: this.metasLocal[0].qtd_cupons,
            selectedColaboradores: [],
            meta1: '',
            meta2: '',
            meta3: '',
            bonus1: '',
            bonus2: '',
            bonus3: '',
        };
        this.entityObjectAddMetas = {
            mes_geral: [],
            formattedDateGeral: [],
            mensalidades: '',
            qtdCupons: '',
            selectedColaboradores: [],
            meta1: 8,
            meta2: 12,
            meta3: 15,
            bonus1: '',
            bonus2: '',
            bonus3: '',
        };
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed[`Metas`]) {
            return userToolStore.userToolsIndexed[`Metas`][0].allow_edit;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed[`Metas`]) {
            return userToolStore.userToolsIndexed[`Metas`][0].allow_delete;
        }
    }
    handleDateInput(newDate) {
        if (newDate) {
            this.entityObject.mes_geral = [newDate];
            this.updateFormattedDateGeral();
        }
    }
    updateFormattedDateGeral() {
        this.entityObject.formattedDateGeral = this.entityObject.mes_geral.map((date) => this.formatDateToDisplay(date));
    }
    formatDateToDisplay(date) {
        const meses = [
            'Janeiro',
            'Fevereiro',
            'Março',
            'Abril',
            'Maio',
            'Junho',
            'Julho',
            'Agosto',
            'Setembro',
            'Outubro',
            'Novembro',
            'Dezembro',
        ];
        const [year, month] = date.split('-');
        const monthIndex = parseInt(month, 10) - 1;
        return `${meses[monthIndex]} de ${year}`;
    }
    formatCurrency(field) {
        const rawValue = this.entityObject[field].toString().replace(/[^\d]/g, '');
        const formattedValue = new Intl.NumberFormat('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: true,
        }).format(Number(rawValue) / 100);
        this.entityObject[field] = formattedValue;
    }
    preventNonNumeric(event) {
        const charCode = event.key.charCodeAt(0);
        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
        }
    }
    cleanCurrency(value) {
        if (!value)
            return '';
        return value.replace(/[^\d.,]/g, '').replace(',', '.');
    }
    get filteredItems() {
        if (this.selectedTab === 0) {
            return this.metasIndividuais;
        }
        else if (this.selectedTab === 1) {
            return this.metasIndividuais.filter((item) => item.cargo_nome === 'Consultor');
        }
        else if (this.selectedTab === 2) {
            return this.metasIndividuais.filter((item) => item.cargo_nome === 'CDA');
        }
        return [];
    }
    get isAddButtonEnabled() {
        return (this.entityObjectAddMetas.selectedColaboradores.length > 0 &&
            this.entityObjectAddMetas.meta1 !== '' &&
            this.entityObjectAddMetas.bonus1 !== '');
    }
    formatForApi(value) {
        const rawValue = value.replace(/[^\d,]/g, '').replace(',', '.');
        return rawValue || '';
    }
    async addMeta() {
        this.entityObjectAddMetas.selectedColaboradores.forEach((colaborador) => {
            const newItem = {
                id: ++this.uniqueIdCounter,
                full_name: colaborador.full_name,
                meta: `${this.entityObjectAddMetas.meta1} - ${this.entityObjectAddMetas.meta2} - ${this.entityObjectAddMetas.meta3}`,
                bonus: `${this.entityObjectAddMetas.bonus1} - ${this.entityObjectAddMetas.bonus2} - ${this.entityObjectAddMetas.bonus3}`,
                user_id: colaborador,
                meta_01: this.entityObjectAddMetas.meta1 || '',
                meta_02: this.entityObjectAddMetas.meta2 || '',
                meta_03: this.entityObjectAddMetas.meta3 || '',
                bonificacao_01: this.formatForApi(this.entityObjectAddMetas.bonus1),
                bonificacao_02: this.formatForApi(this.entityObjectAddMetas.bonus2),
                bonificacao_03: this.formatForApi(this.entityObjectAddMetas.bonus3),
            };
            this.items.push(newItem);
        });
    }
    clearMetas() {
        this.entityObjectAddMetas = {
            selectedColaboradores: [],
            meta1: '',
            meta2: '',
            meta3: '',
            bonus1: '',
            bonus2: '',
            bonus3: '',
        };
    }
    async deleteItem(id) {
        this.$swal({
            title: 'Confirma a exclusão da meta?',
            text: 'Após exclusão da meta, não pode ser recuperado!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await metaIndividualStore.deleteMetaIndividual(id);
                    await this.getMetasIndividuais();
                    this.$swal('Excluído!', 'A meta foi excluída com sucesso.', 'success');
                }
                catch (error) {
                    this.$swal('Erro!', 'Ocorreu um problema ao tentar excluir a meta. Por favor, tente novamente.', 'error');
                }
            }
        });
    }
    async deleteItemAddMetas(id) {
        this.$swal({
            title: 'Confirma a exclusão da meta?',
            text: 'Após exclusão da meta, não pode ser recuperado!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then((result) => {
            if (result.isConfirmed) {
                this.items = this.items.filter((item) => item.id !== id);
                this.$swal('Excluído!', 'A meta foi excluída com sucesso.', 'success');
            }
        });
    }
    updateItem(updatedItem) {
        const index = this.metasIndividuais.findIndex((item) => item.id === updatedItem.id);
        if (index !== -1) {
            const cleanBonus1 = this.cleanCurrency(updatedItem.bonus1);
            const cleanBonus2 = this.cleanCurrency(updatedItem.bonus2);
            const cleanBonus3 = this.cleanCurrency(updatedItem.bonus3);
            this.metasIndividuais[index] = {
                ...this.metasIndividuais[index],
                ...updatedItem,
                metas: `${updatedItem.meta_01 || ''} - ${updatedItem.meta_02 ||
                    ''} - ${updatedItem.meta_03 || ''}`,
                bonificacoes: `${cleanBonus1 || ''} - ${cleanBonus2 ||
                    ''} - ${cleanBonus3 || ''}`,
            };
            this.metasIndividuais = [...this.metasIndividuais];
        }
    }
    updateItemAddMetas(updatedItem) {
        const index = this.items.findIndex((item) => item.id === updatedItem.id);
        if (index !== -1) {
            updatedItem.meta = `${updatedItem.meta_01 ||
                ''} - ${updatedItem.meta_02 || ''} - ${updatedItem.meta_03 || ''}`;
            updatedItem.bonus = `${updatedItem.bonus1 || ''} - ${updatedItem.bonus2 ||
                ''} - ${updatedItem.bonus3 || ''}`;
            this.$set(this.items, index, updatedItem);
        }
    }
    openEditDialog(item) {
        this.selectedItem = { ...item };
        this.editDialog = true;
    }
    openEditDialogAddMetas(item) {
        this.selectedItem = { ...item };
        this.editDialogAddMetas = true;
    }
    closeEditDialog() {
        this.editDialog = false;
        this.selectedItem = null;
    }
    closeEditDialogAddMetas() {
        this.editDialogAddMetas = false;
        this.selectedItem = null;
    }
    onCancel() {
        this.$emit('on-cancel');
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    async formatMetasIndividuais() {
        this.metasIndividuais = await Promise.all(this.metasIndividuais.map(async (item) => {
            const user = await userStore.getUser(item.user_id);
            return {
                ...item,
                full_name: user ? user.full_name : `ID: ${item.user_id}`,
                cargo_nome: user ? (user.cargo_id === 5 ? 'CDA' : 'Consultor') : '',
                metas: `${item.meta_01} - ${item.meta_02} - ${item.meta_03}`,
                bonificacoes: `${item.bonificacao_01} - ${item.bonificacao_02} - ${item.bonificacao_03}`,
            };
        }));
    }
    async createMetas() {
        const metasPromises = this.entityObject.mes_geral.map(async (mes) => {
            if (this.metasLocal.length > 0) {
                const metasIndividualPromises = this.items.map((item) => {
                    const metaIndividualPayload = {
                        meta_01: item.meta_01,
                        meta_02: item.meta_02,
                        meta_03: item.meta_03,
                        bonificacao_01: item.bonificacao_01,
                        bonificacao_02: item.bonificacao_02,
                        bonificacao_03: item.bonificacao_03,
                        user_id: item.user_id.id,
                        company_id: this.userProfile.company.id,
                        meta_id: this.metasLocal[0].id,
                    };
                    return metaIndividualStore.createMetaIndividual(metaIndividualPayload);
                });
                return Promise.all(metasIndividualPromises);
            }
            else {
                const metaPayload = {
                    mes_geral: mes,
                    qtd_mensalidades: this.entityObject.mensalidades,
                    qtd_cupons: this.entityObject.qtdCupons,
                    company_id: this.userProfile.company.id,
                };
                const metaResponse = await metaStore.createMeta(metaPayload);
                const metaId = metaResponse.id;
                const metasIndividualPromises = this.items.map((item) => {
                    const metaIndividualPayload = {
                        meta_01: item.meta_01,
                        meta_02: item.meta_02,
                        meta_03: item.meta_03,
                        bonificacao_01: item.bonificacao_01,
                        bonificacao_02: item.bonificacao_02,
                        bonificacao_03: item.bonificacao_03,
                        user_id: item.user_id.id,
                        company_id: this.userProfile.company.id,
                        meta_id: metaId,
                    };
                    return metaIndividualStore.createMetaIndividual(metaIndividualPayload);
                });
                return Promise.all(metasIndividualPromises);
            }
        });
        await Promise.all(metasPromises);
        this.items = [];
        this.clearMetas();
        this.$emit('on-cancel');
    }
    async atualizarMetas() {
        const metaPayload = {
            id: this.metasLocal[0].id,
            mes_geral: this.entityObject.mes_geral[0] + '-01',
            qtd_mensalidades: this.entityObject.mensalidades,
            qtd_cupons: this.entityObject.qtdCupons,
            company_id: this.userProfile.company.id,
        };
        await metaStore.updateMeta(metaPayload);
        const updatePromises = this.metasIndividuais.map((item) => {
            const payload = {
                meta_01: item.meta_01,
                meta_02: item.meta_02,
                meta_03: item.meta_03,
                bonificacao_01: this.cleanCurrency(item.bonificacao_01),
                bonificacao_02: this.cleanCurrency(item.bonificacao_02),
                bonificacao_03: this.cleanCurrency(item.bonificacao_03),
                user_id: item.user_id,
                company_id: this.userProfile.company.id,
                id: item.id,
                meta_id: item.meta_id,
            };
            return metaIndividualStore.updateMetaIndividual(payload);
        });
        await Promise.all(updatePromises);
        await this.getMetasIndividuais();
        this.$swal('Sucesso', 'Metas atualizadas com sucesso!', 'success');
    }
    async getMetasIndividuais() {
        this.metasIndividuais = await metaIndividualStore.getAllMetasIndividualByMetaId(this.metasLocal[0].id);
        this.formatMetasIndividuais();
    }
    async getMetasGerais() {
        const response = await metaStore.getMetasByMonth({
            mesGeral: this.entityObject.mes_geral[0],
        });
        this.metasLocal = response;
        this.entityObject.mensalidades = response[0].qtd_mensalidades;
        this.entityObject.qtdCupons = response[0].qtd_cupons;
    }
    get colaboradorItems() {
        return userStore.users;
    }
    async buscarColaboradores() {
        await userStore.getUsers(this.somenteAtivos);
    }
    get filteredColaboradores() {
        const cargoId = this.selectedTab === 0 ? 3 : 5;
        return this.colaboradorItems.filter((colaborador) => colaborador.cargo_id === cargoId);
    }
    async onSelectedMonthChange() {
        this.entityObject.mes_geral = this.entityObject.mes_geral.map((mes) => (mes.includes('-') ? `${mes}-01` : mes));
        this.updateFormattedDateGeral();
        await this.getMetasGerais();
        if (!this.metasLocal || this.metasLocal.length === 0) {
            this.clearFieldsAndTable();
            this.$swal('Atenção', 'Nenhuma meta geral foi encontrada para o mês selecionado.', 'warning');
            return;
        }
        await this.getMetasIndividuais();
        await this.buscarColaboradores();
    }
    clearFieldsAndTable() {
        this.entityObject.mensalidades = '';
        this.entityObject.qtdCupons = '';
        this.metasIndividuais = [];
        this.items = [];
    }
    async mounted() {
        if (this.selectedMonth) {
            this.entityObject.mes_geral = [this.selectedMonth];
            this.updateFormattedDateGeral();
        }
        await this.getMetasIndividuais();
        this.buscarColaboradores();
    }
};
__decorate([
    Prop({ type: Array, default: () => [] })
], EditMetaComponent.prototype, "metas", void 0);
__decorate([
    Prop({ type: String, default: '' })
], EditMetaComponent.prototype, "selectedMonth", void 0);
__decorate([
    Watch('selectedDate')
], EditMetaComponent.prototype, "onSelectedMonthChange", null);
EditMetaComponent = __decorate([
    Component({
        components: {
            ConfigMetasComponent,
        },
    })
], EditMetaComponent);
export default EditMetaComponent;
