import { __decorate } from "tslib";
import { contratoStore, mainStore, metaIndividualStore, metaStore, userStore, } from '@/store';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
let ProgressoMetasComponent = class ProgressoMetasComponent extends Vue {
    constructor() {
        super(...arguments);
        this.headersColaborador = [
            { text: 'Assinatura do contrato', value: 'created_at' },
            { text: 'Curso', value: 'curso_nome' },
            { text: 'Aluno', value: 'aluno_nome' },
            { text: 'Responsável', value: 'responsavel_nome' },
            { text: 'Contatos', value: 'aluno_contatos' },
            {
                text: 'Primeira parcela do curso',
                value: 'curso_primeira_parcela_status_id',
            },
            { text: 'Taxa de matrícula', value: 'taxa_matricula_valor' },
            { text: 'Percentual de Desconto', value: 'curso_desconto_percentual' },
            { text: 'Turma', value: 'turma_id' },
        ];
        this.headersCDA = [
            {
                text: 'Criado em',
                value: 'created_at',
            },
            {
                text: 'Nome',
                value: 'nome',
            },
            {
                text: 'E-mail',
                value: 'email',
            },
            {
                text: 'Idade',
                value: 'idade',
            },
            {
                text: 'Telefone',
                value: 'telefone',
            },
            { text: 'Status atual', value: 'status_id' },
            { text: 'Ultimo contato', value: 'ultimo_contato' },
            {
                text: 'Campanha',
                value: 'campaign',
            },
            {
                text: 'Indicado por',
                value: 'indicador_nome',
            },
        ];
        this.headersGeral = [
            { text: 'Colaborador', value: 'full_name' },
            { text: 'Cargo', value: 'cargo.nome' },
            // { text: 'Performance', value: 'responsavel_nome' },
            { text: 'Total de visitas/matrículas', value: 'total' },
            {
                text: 'Valor R$',
                value: 'bonificacao',
            },
            { text: 'Etapa da meta', value: 'etapas_metas' },
        ];
        this.contratos = [];
        this.search = '';
        this.somenteAtivos = true;
        this.expanded = [];
        this.contratosPorColaborador = [];
        this.metasIndividuais = [];
        this.metasPorColaborador = {};
        this.totalVisitasMatriclas = [];
        this.selectedTab = 0;
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    async buscarColaboradores() {
        await userStore.getUsers(this.somenteAtivos);
    }
    get colaboradorItems() {
        return userStore.users;
    }
    get filteredColaboradores() {
        const colaboradoresFiltrados = this.colaboradorItems.filter((colaborador) => colaborador.cargo_id === 3 || colaborador.cargo_id === 5);
        if (this.selectedTab === 1) {
            return colaboradoresFiltrados.filter((colaborador) => colaborador.cargo_id === 3);
        }
        else if (this.selectedTab === 2) {
            return colaboradoresFiltrados.filter((colaborador) => colaborador.cargo_id === 5);
        }
        return colaboradoresFiltrados;
    }
    getHeaders(item) {
        return item.cargo_id === 3 ? this.headersColaborador : this.headersCDA;
    }
    getMeta(colaboradorId, metaKey) {
        if (this.metasPorColaborador[colaboradorId] &&
            this.metasPorColaborador[colaboradorId].length > 0) {
            return this.metasPorColaborador[colaboradorId][0][metaKey] || 'N/A';
        }
        return 'N/A';
    }
    getBonificacaoById(colaboradorId) {
        const metas = this.metasPorColaborador[colaboradorId]?.[0];
        if (!metas) {
            return 'R$ 0,00';
        }
        const totalVisitas = this.getTotalVisitasById(colaboradorId);
        if (totalVisitas >= metas.meta_03) {
            return `R$ ${metas.bonificacao_03}`;
        }
        else if (totalVisitas >= metas.meta_02) {
            return `R$ ${metas.bonificacao_02}`;
        }
        else if (totalVisitas >= metas.meta_01) {
            return `R$ ${metas.bonificacao_01}`;
        }
        return 'R$ 0,00';
    }
    isMetaConcluida(colaboradorId, metaKey) {
        const metas = this.metasPorColaborador[colaboradorId]?.[0];
        if (!metas) {
            return false;
        }
        const totalVisitas = this.getTotalVisitasById(colaboradorId);
        return totalVisitas >= metas[metaKey];
    }
    async getColaboradorDetalhado({ item }) {
        const [day, month] = this.startDateCurrent.split('/');
        const year = new Date().getFullYear();
        const formattedDate = `${year}-${month}-01`;
        const nextMonth = parseInt(month) === 12 ? 1 : parseInt(month) + 1;
        const nextYear = parseInt(month) === 12 ? year + 1 : year;
        const finalDate = `${nextYear}-${String(nextMonth).padStart(2, '0')}-01`;
        if (item.cargo_id === 3) {
            this.contratosPorColaborador = await contratoStore.getContratoByConsultorMetas({
                consultor_id: item.id,
                data: formattedDate,
            });
        }
        else {
            this.contratosPorColaborador = await metaStore.getMetasVisitas({
                cda_id: item.id,
                data_inicial: formattedDate,
                data_final: finalDate,
            });
        }
    }
    mapearMetasPorColaborador() {
        this.metasPorColaborador = this.filteredColaboradores.reduce((acc, colaborador) => {
            acc[colaborador.id] = [];
            return acc;
        }, {});
        this.metasIndividuais.forEach((meta) => {
            const colaboradorId = meta.user_id;
            if (this.metasPorColaborador[colaboradorId]) {
                this.metasPorColaborador[colaboradorId].push(meta);
            }
        });
    }
    async getTotalVisitas() {
        const [day, month] = this.startDateCurrent.split('/');
        const year = new Date().getFullYear();
        const formattedDate = `${year}-${month}-01`;
        const consultores = this.filteredColaboradores.map((colaborador) => ({
            consultor_id: colaborador.id,
            cargo_id: colaborador.cargo_id,
        }));
        const payload = {
            data: formattedDate,
            consultores,
        };
        const response = await metaStore.getContratosAndCda(payload);
        this.totalVisitasMatriclas = response;
    }
    getTotalVisitasById(colaboradorId) {
        if (!this.totalVisitasMatriclas ||
            this.totalVisitasMatriclas.length === 0) {
            return 0;
        }
        const visita = this.totalVisitasMatriclas.find((item) => item.consultor_id === colaboradorId);
        if (!visita) {
            return 0;
        }
        if (visita.cargo_id === 3) {
            return visita.contratos?.length || 0;
        }
        else {
            return visita.parceria_cupons?.length || 0;
        }
    }
    async onSelectedMonthChange() {
        this.mapearMetasPorColaborador();
        await this.getTotalVisitas();
    }
    async mounted() {
        await this.buscarColaboradores();
        this.metasIndividuais = await metaIndividualStore.getAllMetasIndividualByMetaId(this.metas[0].id);
        this.mapearMetasPorColaborador();
        await this.getTotalVisitas();
    }
};
__decorate([
    Prop({ type: String, default: '' })
], ProgressoMetasComponent.prototype, "startDateCurrent", void 0);
__decorate([
    Prop({ type: Array, default: () => [] })
], ProgressoMetasComponent.prototype, "metas", void 0);
__decorate([
    Watch('startDateCurrent')
], ProgressoMetasComponent.prototype, "onSelectedMonthChange", null);
ProgressoMetasComponent = __decorate([
    Component
], ProgressoMetasComponent);
export default ProgressoMetasComponent;
