var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.dialogGestaoAlunoReunioes)?_c('v-dialog',{attrs:{"width":"1200"},model:{value:(_vm.dialogGestaoAlunoReunioes),callback:function ($$v) {_vm.dialogGestaoAlunoReunioes=$$v},expression:"dialogGestaoAlunoReunioes"}},[_c('gestao-aluno-reunioes-component',{attrs:{"prop-reunioes":_vm.dialogGestaoAlunoReunioesDetails},on:{"on-cancel":function($event){_vm.dialogGestaoAlunoReunioes = false;
        _vm.dialogGestaoAlunoReunioesDetails = [];}}})],1):_vm._e(),(_vm.loading)?_c('v-card',{staticClass:"ma-1 pa-3"},[_c('v-skeleton-loader',{attrs:{"type":"card-avatar, article"}})],1):_c('div',{attrs:{"fluid":""}},[_c('log-show-component',{ref:"logTurmaAluno",attrs:{"prop-model":"TurmaAluno","prop-model-id":_vm.id}}),_c('v-card',{staticClass:"ma-1 pa-3 rounded-xl mb-3"},[_c('v-card-title',[_c('div',{staticClass:"headline primary--text"},[_vm._v("Alunos")])]),_c('ImprimirTabelaComponent',{staticStyle:{"float":"right"},attrs:{"propRef":[_vm.tabelaRef]}}),_c('v-card-text',[_c('v-checkbox',{attrs:{"label":"Exibir somente alunos vigentes","hide-details":"auto"},model:{value:(_vm.exibirSomenteVigente),callback:function ($$v) {_vm.exibirSomenteVigente=$$v},expression:"exibirSomenteVigente"}}),_c('v-data-table',{ref:"tabelaAlunos",attrs:{"dense":"","headers":_vm.alunoHeaders,"items":_vm.itemsFiltered,"footer-props":{
              itemsPerPageOptions: [-1]
            },"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.aluno_nome",fn:function(ref){
            var item = ref.item;
return [_c('span',[_c('entidade-link-component',{attrs:{"propEntidadeId":item.aluno_id,"propEntidadeNome":item.aluno_nome,"propEntidadeTipo":"aluno"}})],1)]}},{key:"item.aluno_celular",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPhone")(item.aluno_celular))+" ")]}},{key:"item.ultimo_contato",fn:function(ref){
            var item = ref.item;
return [_c('text-expandable-component',[(item.ultimo_contato)?_c('div',[_vm._v(" Ult. contato "),_c('b',[_vm._v(_vm._s(item.ultimo_contato.observacao))]),_c('br'),_vm._v(" Criado em "),_c('b',[_vm._v(_vm._s(_vm._f("formatDateTime")(item.ultimo_contato.created_at)))]),_c('br'),(item.ultimo_contato.created_by_full_name)?_c('span',[_vm._v(" Criado por "),_c('b',[_vm._v(_vm._s(item.ultimo_contato.created_by_full_name))]),_c('br')]):_vm._e(),(item.ultimo_contato.tarefa_data_hora_agendamento)?_c('div',[_vm._v(" Próx. contato "),_c('b',[_vm._v(_vm._s(_vm._f("formatDateTime")(item.ultimo_contato.tarefa_data_hora_agendamento)))]),_c('br'),_vm._v(" Resp. "),_c('b',[_vm._v(_vm._s(item.ultimo_contato.tarefa_responsavel_nome))])]):_vm._e()]):_vm._e()])]}},{key:"item.status_do_aluno_na_turma_id",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._.find( _vm.getStatusDoAlunoOptions(), function (s) { return s.id == item.status_do_aluno_na_turma_id; } ).name)+" ")]}},{key:"item.aluno_data_nascimento",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getAge(item.aluno_data_nascimento))+" ")]}},{key:"item.primeira_presenca",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.primeira_presenca))+" ")]}},{key:"item.livro_data_entrega",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.livro_data_entrega))+" ")]}},{key:"item.transporte_status",fn:function(ref){
            var item = ref.item;
return [(item.transporte_status == 'nao_requisitou_transporte')?_c('v-chip',{attrs:{"small":""}},[_vm._v(" Não utiliza ")]):_vm._e(),(item.transporte_status == 'inserido_transporte')?_c('v-chip',{attrs:{"small":"","color":"green"}},[_vm._v(" Ok ")]):_vm._e(),(item.transporte_status == 'nao_consta_transporte' &&
                  item.status_do_aluno_na_turma_id == 1
                  )?_c('v-chip',{attrs:{"small":"","color":"red"}},[_vm._v(" Colocar na Van ")]):_vm._e()]}},{key:"item.idx",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.items.findIndex(function (object) { return object.turma_aluno_id == item.turma_aluno_id; }) + 1)+" ")]}},{key:"item.ativo_desde",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.ativo_desde))+" ")]}},{key:"item.ativo_ate",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.ativo_ate))+" ")]}}],null,true)})],1)],1),_c('v-card',{staticClass:"ma-1 pa-3 rounded-xl mb-3"},[_c('v-card-title',[_c('div',{staticClass:"headline primary--text"},[_vm._v("Pedagógico")])]),_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.pedagogicoHeaders,"items":_vm.itemsFiltered,"footer-props":{
              itemsPerPageOptions: [-1]
            },"hide-default-footer":"","mobile-breakpoint":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
            var headers = ref.props.headers;
return [_c('thead',[_c('tr',[_c('th',{attrs:{"colspan":"1"}}),_c('th',{staticStyle:{"background":"#fbdcda"},attrs:{"colspan":"4"}},[_vm._v(" 1ª análise | "),_c('i',[_vm._v("3 meses após o início da turma")])]),_c('th',{staticStyle:{"background":"#dbede3"},attrs:{"colspan":"4"}},[_vm._v(" 2ª análise | "),_c('i',[_vm._v("4 meses após início")])]),_c('th',{staticStyle:{"background":"#cecade"},attrs:{"colspan":"4"}},[_vm._v(" 3ª análise | "),_c('i',[_vm._v("5 meses após início")])])])])]}},{key:"item.idx",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.items.findIndex(function (object) { return object.turma_aluno_id == item.turma_aluno_id; }) + 1)+" ")]}}],null,true)})],1)],1),(_vm.userActionsvisualizarInfoFinanceiraAlunoAbaGestaoAllow)?_c('v-card',{staticClass:"ma-1 pa-3"},[_c('v-card-title',[_c('div',{staticClass:"headline primary--text"},[_vm._v("Financeiro")])]),_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.financeiroHeaders,"items":_vm.itemsFiltered,"footer-props":{
              itemsPerPageOptions: [-1]
            },"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.idx",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.items.findIndex(function (object) { return object.turma_aluno_id == item.turma_aluno_id; }) + 1)+" ")]}},{key:"item.parcelas_material",fn:function(ref){
            var item = ref.item;
return [(item.pre_invoice_id)?_c('livro-solicitado-direto-franchise-button',{attrs:{"propPreInvoiceId":item.pre_invoice_id,"propPreInvoiceStatusId":item.pre_invoice_status_id}}):_c('v-row',{staticClass:"ma-0",staticStyle:{"flex-flow":"row","float":"left"}},_vm._l((item.parcelas_material),function(financeiro_parcela,idx){return _c('v-col',{key:idx,staticStyle:{"padding":"0 1px"}},[_c('v-chip',{attrs:{"label":"","small":"","color":_vm.financeiroParcelaStatusOptions()[financeiro_parcela.financeiro_parcela_status_id - 1].color}},[_c('span',{staticStyle:{"font-size":"1em"}},[_vm._v(_vm._s(financeiro_parcela.financeiro_parcela_parcela_numero)+"/"+_vm._s(financeiro_parcela.financeiro_numero_parcelas))])])],1)}),1)]}},{key:"item.parcelas_mensalidade",fn:function(ref){
            var value = ref.value;
return [_c('v-row',{staticClass:"ma-0",staticStyle:{"flex-flow":"row","float":"left"}},_vm._l((value),function(financeiro_parcela,idx){return _c('v-col',{key:idx,staticStyle:{"padding":"0 1px"}},[_c('v-chip',{attrs:{"label":"","small":"","color":_vm.financeiroParcelaStatusOptions()[financeiro_parcela.financeiro_parcela_status_id - 1].color}},[_c('span',{staticStyle:{"font-size":"1em"}},[_vm._v(_vm._s(financeiro_parcela.financeiro_parcela_parcela_numero)+"/"+_vm._s(financeiro_parcela.financeiro_numero_parcelas))])])],1)}),1)]}}],null,true)})],1)],1):_vm._e(),_c('v-card',{staticClass:"ma-1 pa-3 rounded-xl mb-3"},[_c('v-card-title',[_c('div',{staticClass:"headline primary--text"},[_vm._v("Retenção")])]),_c('v-card-text',[_c('v-data-table',{attrs:{"footer-props":{ itemsPerPageOptions: [-1] },"headers":_vm.retencaoHeaders,"items":_vm.itemsFiltered,"dense":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.idx",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.items.findIndex(function (object) { return object.turma_aluno_id == item.turma_aluno_id; }) + 1)+" ")]}},{key:"item.proxima_reuniao_data",fn:function(ref){
            var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTimeWithoutConvert")(value))+" ")]}},{key:"item.created_at",fn:function(ref){
            var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(value))+" ")]}},{key:"item.proxima_reuniao_status_id",fn:function(ref){
            var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getStatusOcorrenciaTarefa(value))+" ")]}},{key:"item.proxima_reuniao_tipo_id",fn:function(ref){
            var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getOcorrenciaTipoReuniao(value))+" ")]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [(item.proxima_reuniao_data)?_c('v-icon',{on:{"click":function($event){return _vm.openAlunoReunioesDetails(item.reunioes)}}},[_vm._v("mdi-eye")]):_vm._e()]}}],null,true)})],1)],1),(_vm.userActionsVisualizarAdmnistrativoAlunoAbaGestaoAllow)?_c('v-card',{staticClass:"ma-1 pa-3"},[_c('v-card-title',[_c('div',{staticClass:"headline primary--text"},[_vm._v("Administrativo")])]),_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.administrativoHeaders,"items":_vm.itemsFiltered,"footer-props":{
              itemsPerPageOptions: [-1]
            },"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.idx",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.items.findIndex(function (object) { return object.turma_aluno_id == item.turma_aluno_id; }) + 1)+" ")]}},{key:"item.aluno_colocado_nova_turma",fn:function(ref){
            var value = ref.value;
return [(!value)?_c('div',[_vm._v("Não")]):_vm._e(),(value)?_c('div',[_vm._v(" Sim "),(value)?_c('v-icon',{on:{"click":function($event){return _vm.openTurma(value)}}},[_vm._v("mdi-open-in-new")]):_vm._e()],1):_vm._e()]}},{key:"item.primeira_parcela_proximo_livro",fn:function(ref){
            var item = ref.item;
return [(item.proximo_contrato_livro_pre_invoice_id)?_c('livro-solicitado-direto-franchise-button',{attrs:{"propPreInvoiceId":item.proximo_contrato_livro_pre_invoice_id,"propPreInvoiceStatusId":item.proximo_contrato_livro_pre_invoice_status_id}}):(item.primeira_parcela_proximo_livro)?_c('span',[_vm._v(_vm._s(item.primeira_parcela_proximo_livro))]):_c('span',[_vm._v("Inexistente")])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [(item.status_do_aluno_na_turma_id !== 1)?_c('v-chip',{staticClass:"ma-1",attrs:{"small":"","label":"","color":_vm._.find(
                  _vm.getStatusDoAlunoOptions(),
                  function (s) { return s.id == item.status_do_aluno_na_turma_id; }
                ).color}},[_vm._v(" "+_vm._s(_vm._.find( _vm.getStatusDoAlunoOptions(), function (s) { return s.id == item.status_do_aluno_na_turma_id; } ).name)+" ")]):_vm._e(),((item.status_do_aluno_na_turma_id == 7 ||
                      item.status_do_aluno_na_turma_id == 8) &&
                    item.boletim_entregue === true
                    )?_c('v-chip',{staticClass:"ma-1",attrs:{"small":"","label":"","color":"success"}},[_vm._v(" Boletim entregue ")]):_vm._e(),(item.status_do_aluno_na_turma_id == 1)?_c('v-btn',{staticClass:"ma-1 success",attrs:{"small":""},on:{"click":function($event){return _vm.updateTurmaAluno(item, 7)}}},[_vm._v("Aprovar")]):_vm._e(),(item.status_do_aluno_na_turma_id == 1)?_c('v-btn',{attrs:{"color":"ma-1 error","small":""},on:{"click":function($event){return _vm.updateTurmaAluno(item, 8)}}},[_vm._v("Reprovar")]):_vm._e(),(item.status_do_aluno_na_turma_id == 7 ||
                  item.status_do_aluno_na_turma_id == 8
                  )?_c('v-btn',{attrs:{"color":"ma-1 warning","small":""},on:{"click":function($event){return _vm.updateTurmaAluno(item, 1)}}},[_vm._v("Retornar para status vigente")]):_vm._e(),((item.status_do_aluno_na_turma_id == 7 ||
                  item.status_do_aluno_na_turma_id == 8) &&
                  !item.boletim_entregue
                  )?_c('v-btn',{attrs:{"color":"ma-1 warning","small":""},on:{"click":function($event){return _vm.entregaBoletim(item)}}},[_vm._v("Entregar boletim")]):_vm._e()]}},{key:"item.log",fn:function(ref){
                  var item = ref.item;
return [_c('v-btn',{attrs:{"color":"ma-1","text":""},on:{"click":function($event){return _vm.showLogTurmaAluno(_vm.$refs.logTurmaAluno, item.turma_aluno_id)}}},[_c('v-icon',[_vm._v(" mdi-history ")])],1)]}}],null,true)})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }