import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { userToolStore, mainStore } from '@/store';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import FiltroPorTabela from '@/components/FiltrosPorTabela.vue';
import ImprimirTabelaComponent from '@/components/ImprimirTabelaComponent.vue';
import TextExpandableComponent from '@/components/TextExpandableComponent.vue';
import CustomTabsComercialComponent from '@/components/CustomTabsComercialComponent.vue';
import DashboardMetasComponent from '@/components/DashboardMetasComponent.vue';
let Comercial = class Comercial extends Vue {
    constructor() {
        super(...arguments);
        this.selectedTab = 0;
        this.publicItems = [
            {
                icon: 'handshake',
                title: 'Parcerias',
                subtitle: 'No menu externo, acesse campanhas nacionais para gerenciar informações, registrar tarefas e criar materiais digitais para captação de cupons no sistema drive.',
                chip: ['Criar parcerias', 'Ocorrências', 'Ações'],
                route: '/main/ComercialParcerias/all',
                toolName: 'Parcerias',
            },
            {
                icon: 'campaign',
                title: 'Campanhas',
                subtitle: 'No menu externo, acesse campanhas nacionais para gerenciar informações, registrar tarefas e criar materiais digitais para captação de cupons no sistema drive.',
                chip: ['Cartaz', 'Flyer', 'Landing Page'],
                route: '/main/ComercialCampanhas/all',
                toolName: 'Campanhas',
            },
            {
                icon: 'rocket_launch',
                title: this.getNomeMarketingDigital(),
                subtitle: `O ${this.getNomeMarketingDigital()} facilita o marketing digital na sua unidade, simplificando ações e potencializando resultados com a força da rede ${this.getNomeEscola()}.`,
                chip: ['Ferramenta de apoio', 'Agilidade', 'Integração'],
                route: '/main/DrivingMarketing/all',
                toolName: 'Driving Marketing',
            },
            {
                icon: 'assignment',
                title: 'Quadro de planej.',
                subtitle: 'Uma ferramenta visual no Drive para organizar e acompanhar negociações e ações da escola.',
                chip: ['Organização', 'Negociações', 'Ações Escolares'],
                route: '/main/QuadrosPlanejamentocomercial/all',
                toolName: 'Planejamento comercial',
            },
            {
                icon: 'confirmation_number',
                title: 'Cupons',
                subtitle: 'Acesso e gestão de cupons gerados por parcerias, campanhas e marketing digital. Permite registrar, corrigir, distribuir, redistribuir e visualizar o histórico de cupons.',
                chip: ['Gestão', 'Distribuição', 'Histórico'],
                route: '/main/ComercialCupons/all',
                toolName: 'Cupons',
            },
            {
                icon: 'menu_book',
                title: 'Saldo de parcerias',
                subtitle: 'Monitora o total de cupons gerados por parcerias, os distribuídos e os disponíveis dentro de um período.',
                chip: ['Monitoramento', 'Parcerias', 'Disponibilidade'],
                route: '/main/CadernosCadernodecupom/all',
                toolName: 'Caderno de cupom',
            },
            {
                icon: 'call',
                title: this.getNomeCDA(),
                subtitle: 'Etapa do comercial onde os cupons são qualificados via ligação ou mensagem, agendando demonstrações presenciais ou digitais.',
                chip: ['Qualificação', 'Agendamento', 'Contatos'],
                route: '/main/ComercialCDA/all',
                toolName: 'CDA',
            },
            {
                icon: 'person_add',
                title: this.getNomeProspex(),
                subtitle: 'Registro inicial dos interessados, coletando dados de visitantes físicos e digitais para aumentar a conversão em matrículas.',
                chip: ['Registro', 'Conversão', 'Interessados'],
                route: '/main/prospex/all',
                toolName: 'Prospex',
            },
            {
                icon: 'library_books',
                title: 'Caderno de matrícula',
                subtitle: 'Análise de contratos de matrícula por consultor, qualidade das matrículas e filtragem por data de pagamento para comissionamento.',
                chip: ['Contratos', 'Comissionamento', 'Qualidade'],
                route: '/main/CadernosCadernodematriculas/matricula',
                toolName: 'Caderno de matrículas',
            },
            {
                icon: 'fact_check',
                title: 'Check-up comercial',
                subtitle: `Avaliação da conversão de cupons em matrículas, com análise detalhada por ${this.getNomeCDA()} e parcerias.`,
                chip: ['Análise', 'Conversão', 'Performance'],
                route: '/main/checkup/all',
                toolName: 'Check-up',
            },
        ];
        this.items = [
            { title: 'Driver' },
            { title: 'Parcerias' },
            { title: 'Campanhas' },
            { title: 'Driving MKT' },
            { title: 'Quadro de planej.' },
            { title: 'Cupons' },
            { title: 'Saldo por parcerias' },
            { title: 'CDA' },
            { title: 'Prospex' },
            { title: 'Caderno de matrícula' },
            { title: 'Check-up comercial' },
            { title: 'Metas' },
        ];
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    get filteredPublicItems() {
        return this.publicItems.filter((item) => this.allowView(item.toolName));
    }
    allowView(toolName) {
        if (!toolName)
            return true;
        const tool = userToolStore.userToolsIndexed[toolName];
        return tool ? tool[0].allow_view : false;
    }
    redirectToRoute(title) {
        const item = this.publicItems.find((i) => i.title === title);
        if (item && item.route) {
            this.$router.push(item.route);
        }
    }
};
Comercial = __decorate([
    Component({
        components: {
            OpenNewTabComponent,
            FiltroPorTabela,
            ImprimirTabelaComponent,
            TextExpandableComponent,
            CustomTabsComercialComponent,
            DashboardMetasComponent,
        },
    })
], Comercial);
export default Comercial;
