import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { actionStore, cargoStore, departamentoStore, jornadaColaboradorStore, mainStore, toolStore, userActionStore, userStore, userToolStore } from '@/store';
// import { required, confirmed } from "vee-validate/dist/rules";
import { ValidationProvider, ValidationObserver, Validator } from 'vee-validate';
import { newUser } from '@/interfaces/user_profile';
import SaveButton from '@/components/SaveButton.vue';
import MigrarTarefasComponent from '@/components/MigrarTarefasComponent.vue';
import ParceriaCupomCrudComponent from '@/components/ParceriaCupomCrudComponent.vue';
import VDataTableCheckbox from '@/components/VDataTableCheckbox.vue';
import { isValid, parse } from 'date-fns';
import LogShowComponent from '@/components/LogShowComponent.vue';
import UpdatePasswordUserComponent from '@/components/UpdatePasswordUserComponent.vue';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
import _ from 'lodash';
const dict = {
    custom: {
        password: {
            required: 'Senha deve ser preenchido!'
        },
        password_confirmation: {
            required: 'Confirmar Senha deve ser preenchido!',
            confirmed: 'As senhas devem ser iguais!'
        }
    }
};
Validator.localize('pt', dict);
Validator.extend('required', {
    getMessage: field => `${field} deve ser preenchido!`,
    validate: async (value) => !!value
});
Validator.extend('time', {
    getMessage: () => `Hora inválida`,
    validate(value) {
        if (value === '') {
            return true;
        }
        const formatoHora = 'kk:mm';
        const horaParseada = parse(value, formatoHora, new Date());
        return isValid(horaParseada);
    }
});
let Create = class Create extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            { text: 'Módulo', value: 'name' },
            { text: 'Submódulo', value: 'group' },
            { text: 'Visualizar', value: 'allow_view' },
            { text: 'Editar', value: 'allow_edit' },
            { text: 'Deletar', value: 'allow_delete' },
            { text: 'Adicionar', value: 'allow_create' },
            { text: '', value: 'actions' }
        ];
        this.headersActions = [
            { text: 'Função', value: 'name' },
            { text: 'Módulo', value: 'tool_name' },
            { text: 'Permissão', value: 'allow' },
            { text: '', value: 'actions' }
        ];
        this.tab = 0;
        this.toolsOptions = [];
        this.entityObject = null;
        this.id = null;
        // usado para garantir que o usuario nao consiga editar um colaborador e inserir o cpf de um ja existente
        this.cpf = null;
        // usado para garantir que o usuario nao consiga editar um colaborador e inserir o email de um ja existente
        this.email = null;
        this.password1 = '';
        this.password2 = '';
        this.search = '';
        this.cargoSelected = null;
        this.departamentoSelected = null;
        this.loading = true;
        this.searchedUserTools = [];
        this.searchedUserActions = [];
        this.saveLoading = false;
        this.cargos = null;
        this.userToolsActions = [];
        this.alunoObj = null;
        this.jornadaColaborador = [];
        this.cargoPermissoes = [];
        this.loadingResetPassword = false;
        this.cpfSearchUser = {};
        this.cpfSearchDialog = false;
        this.reg = [
            v => !v ||
                /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                'E-mail deve ser válido!'
        ];
    }
    get visualizarRegistro() {
        if (!this.id) {
            return true;
        }
        if (this.entityObject && this.entityObject.company.id === this.userProfile.company.id) {
            return true;
        }
        return false;
    }
    get permiteAlterarJornadaTrabalhoColaborador() {
        return userActionStore.permiteAlterarJornadaTrabalhoColaborador;
    }
    get permiteAlterarPermissoesColaborador() {
        return userActionStore.permiteAlterarPermissoesColaborador;
    }
    get departamentoOptions() {
        return _.filter(departamentoStore.departamentos, item => {
            if (!this.cargoSelected) {
                return false;
            }
            // @ts-ignore
            if (this.departamentoByCargo(this.cargoSelected).includes(item.id)) {
                return true;
            }
        });
    }
    get cargoOptions() {
        return cargoStore.cargos;
    }
    async showLogUser(ref, item, model) {
        let payload = {};
        switch (model) {
            case 'UserTool':
                payload = { user_id: this.id, tool_id: item.id };
                break;
            case 'UserAction':
                payload = { user_id: this.id, action_id: item.id };
                break;
        }
        const form = ref;
        await form.setCustomParams(payload);
        form.show(item.id);
    }
    getActionItemName(item) {
        return (item.name
            // @ts-ignore
            .replace(/prospex/gi, this.getNomeProspex())
            // @ts-ignore
            .replace(/cda/gi, this.getNomeCDA())
            // @ts-ignore
            .replace(/reforço/gi, this.getNomeAulaExtraSingular()));
    }
    atualizarSenha() {
        // @ts-ignore
        const form = this.$refs.dialogUpdatePassword;
        form.show(true);
    }
    getActionItemToolName(item) {
        return (item.tool_name
            // @ts-ignore
            .replace(/prospex/gi, this.getNomeProspex())
            // @ts-ignore
            .replace(/cda/gi, this.getNomeCDA()));
    }
    showLog(ref) {
        const form = ref;
        form.show();
    }
    getActionPermission(item) {
        if (item.allow) {
            return '2';
        }
        if (item.request_permission) {
            return '3';
        }
        return '1';
        // return 4; retornar 4 quando precisar bloquear
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    get user() {
        return userStore.user;
    }
    get userToolsSchool() {
        return userToolStore.userToolsSchool.filter(menuItem => {
            if (menuItem.id === 41) {
                // Gestão de pedidos
                if (this.userProfile.company.venda_direta_allow) {
                    if (menuItem.name && menuItem.name === 'Driving Marketing') {
                        menuItem.name = this.getNomeMarketingDigital();
                    }
                    else if (menuItem.name === 'CDA') {
                        menuItem.name = this.getNomeCDA();
                    }
                    return menuItem;
                }
            }
            else {
                if (menuItem.name && menuItem.name === 'Driving Marketing') {
                    menuItem.name = this.getNomeMarketingDigital();
                }
                else if (menuItem.name === 'CDA') {
                    menuItem.name = this.getNomeCDA();
                }
                else if (menuItem.name === 'Prospex') {
                    // @ts-ignore
                    menuItem.name = this.getNomeProspex();
                }
                return menuItem;
            }
        });
    }
    get actions() {
        if (this.userProfile.company.aula_online_allow) {
            return actionStore.action;
        }
        return actionStore.action.filter(action => action.id !== 67); // 67 - Permite compartilhamento turmas online
    }
    async onChangeEmailCpf(val) {
        if (this.entityObject.cpf?.length < 14 && val === this.entityObject.cpf) {
            return;
        }
        if (val) {
            if (this.entityObject.cpf != this.cpf ||
                this.entityObject.email != this.email) {
                const user = await userStore.userVerificaDisponibilidade({
                    id: this.id,
                    cpf: this.entityObject.cpf,
                    email: this.entityObject.email
                });
                if (user && user.id != this.id) {
                    this.cpfSearchUser = user;
                    if (user.full_name.toLowerCase() !==
                        this.entityObject.full_name.toLowerCase()) {
                        this.entityObject.cpf = '';
                        this.entityObject.email = '';
                    }
                    this.cpfSearchDialog = true;
                }
                else {
                    this.cpfSearchUser = {};
                }
            }
        }
    }
    // if (val.length === 14 && val != this.cpf) {
    async onVisualizarCPFSearched() {
        // @ts-ignore
        const id = this.cpfSearchUser.id;
        await this.$router.push({
            name: 'main-colaboradores-edit',
            params: { id }
        });
        this.$router.go(0);
        this.cpfSearchDialog = false;
    }
    onChangeRadioGroup(evVal, item) {
        if (evVal === '1') {
            item.allow = false;
            item.request_permission = false;
            return;
        }
        if (evVal === '2') {
            item.allow = true;
            item.request_permission = null;
            return;
        }
        if (evVal === '3') {
            item.allow = false;
            item.request_permission = true;
        }
    }
    deleteItem(diaSemana, item) {
        let count = 0;
        this.jornadaColaborador = _.filter(this.jornadaColaborador, (i, idx) => {
            if (i.dia_semana === diaSemana + 1) {
                count++;
                return count - 1 !== item;
            }
            else {
                return true;
            }
        });
    }
    adicionarJornada(idx) {
        this.jornadaColaborador.push({
            hora_inicio: '',
            hora_fim: '',
            dia_semana: idx + 1
        });
    }
    replicar(idx) {
        const indexItems = [];
        _.forEach(this.jornadaColaborador, item => {
            if (item.dia_semana == idx + 1) {
                indexItems.push(item);
            }
        });
        this.jornadaColaborador = [];
        for (let i = 0; i <= 6; i++) {
            _.forEach(indexItems, item => {
                this.jornadaColaborador.push({
                    hora_inicio: item.hora_inicio,
                    hora_fim: item.hora_fim,
                    dia_semana: i + 1
                });
            });
        }
    }
    getJornadasDoDia(diaIndex) {
        const a = _.filter(this.jornadaColaborador, 
        // @ ts-ignore
        item => item.dia_semana === diaIndex + 1);
        return a;
    }
    async updateActions() {
        const optionsIds = _.map(this.departamentoOptions, item => item.id);
        if (this.cargoSelected &&
            this.departamentoSelected &&
            optionsIds.includes(this.departamentoSelected)) {
            const payload = {
                cargo_id: this.cargoSelected,
                departamento_id: this.departamentoSelected
            };
            this.searchedUserActions = await actionStore.getActionsDefault(payload);
            this.searchedUserTools = await toolStore.getToolsDefault(payload);
        }
        this.getEditUserTools();
        this.getEditUserActions();
    }
    async submit() {
        const isValid1 = await this.$refs.observer.validate();
        const isValid2 = await this.$validator.validateAll();
        if (!isValid1 || !isValid2) {
            if (!isValid2) {
                _.forEach(this.$validator.errors.items, item => {
                    mainStore.addErrorToasted({
                        // @ts-ignore
                        toasted: this.$toasted,
                        errors: [item.msg]
                    });
                });
            }
            // @ts-ignore
            this.$refs.saveButton.changeLoadingStatus(false);
            return;
        }
        if (this.entityObject.email === '') {
            this.entityObject.email = null;
        }
        this.entityObject.cargo_id = this.cargoSelected;
        this.entityObject.departamento_id = this.departamentoSelected;
        this.entityObject.password = this.password1;
        this.entityObject.tools = this.userToolsSchool;
        this.entityObject.actions = this.actions;
        this.entityObject.jornada_colaborador = this.jornadaColaborador;
        if (this.entityObject.cargo_id === 5) {
            const cdaIndex = _.findIndex(this.entityObject.tools, 
            // @ts-ignore
            item => item.name === 'CDA' || item.name === 'SDR');
            this.entityObject.tools[cdaIndex].allow_view = true;
        }
        let response;
        if (this.id) {
            response = await userStore.updateUser(this.entityObject);
        }
        else {
            response = await userStore.createUser(this.entityObject);
            if (response && response.status === 200 && response.data) {
                this.id = response.data.id;
                this.cpf = this.entityObject.cpf;
                this.email = this.entityObject.email;
            }
        }
        if (response != null) {
            await userActionStore.getUserActions();
            await userToolStore.getUserTools();
            // @ts-ignore
            await this.$refs.saveButton.changeLoadingStatus(false);
            await this.$router.push({ name: 'main-colaboradores' });
        }
        // @ts-ignore
        await this.$refs.saveButton.changeLoadingStatus(false);
    }
    get userToolUpdate() {
        return userToolStore.userToolUpdate;
    }
    get userToolsCreate() {
        return userToolStore.userToolsCreate;
    }
    get tools() {
        return toolStore.tools;
    }
    getEditUserTools() {
        _.forEach(this.userToolsSchool, item => {
            const permissions = _.find(this.searchedUserTools, obj => obj.tool_id === item.id);
            if (permissions) {
                item.allow_create = permissions.allow_create;
                item.allow_delete = permissions.allow_delete;
                item.allow_edit = permissions.allow_edit;
                item.allow_view = permissions.allow_view;
            }
            else {
                item.allow_create = false;
                item.allow_delete = false;
                item.allow_edit = false;
                item.allow_view = false;
            }
        });
    }
    getEditUserActions() {
        _.forEach(this.actions, item => {
            const permissions = _.find(this.searchedUserActions, obj => {
                return obj.action_id === item.id;
            });
            if (permissions) {
                item.allow = permissions.allow;
                item.request_permission = permissions.request_permission;
            }
            else {
                item.allow = false;
                item.request_permission = false;
            }
        });
    }
    async mounted() {
        this.loading = true;
        await userToolStore.getToolsSchool();
        await actionStore.getActions();
        await cargoStore.getCargos();
        // @ts-ignore
        if (this.$router.currentRoute.params.id) {
            // @ts-ignore
            this.id = this.$router.currentRoute.params?.id;
            await jornadaColaboradorStore.getJornadaColaborador(this.id);
            this.jornadaColaborador = jornadaColaboradorStore.jornadaColaborador;
            this.entityObject = await userStore.getUser(
            // @ts-ignore
            this.$router.currentRoute.params.id);
            this.cpf = this.entityObject.cpf;
            this.email = this.entityObject.email;
            this.departamentoSelected = this.entityObject.departamento_id;
            this.cargoSelected = this.entityObject.cargo_id;
            this.searchedUserTools = await userToolStore.getToolsByUserId(this.id);
            if (this.searchedUserTools.length > 0) {
                this.getEditUserTools();
            }
            this.searchedUserActions = await actionStore.getActionsByUserId(this.id);
            if (this.searchedUserActions.length > 0) {
                this.getEditUserActions();
            }
        }
        else {
            this.entityObject = newUser();
        }
        this.loading = false;
    }
};
Create = __decorate([
    Component({
        components: {
            ValidationObserver,
            ValidationProvider,
            SaveButton,
            MigrarTarefasComponent,
            ParceriaCupomCrudComponent,
            VDataTableCheckbox,
            LogShowComponent,
            UpdatePasswordUserComponent,
            DateFieldComponent
        }
    })
], Create);
export default Create;
