import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { financeiroParcelaStore, analyticsStore, userToolStore, userActionStore } from '@/store';
import _ from 'lodash';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
import EntidadeLinkComponent from '@/components/EntidadeLinkComponent.vue';
import SearchEntidadeComponent from '@/components/SearchEntidadeComponent.vue';
import FinanceiroValorPagoTextComponent from '@/components/FinanceiroValorPagoTextComponent.vue';
import GenericListTotalCardComponent from '@/components/GenericListTotalCardComponent.vue';
import GenericListItemsCardComponent from '@/components/GenericListItemsCardComponent.vue';
import TextExpandableComponent from '@/components/TextExpandableComponent.vue';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import SaveButton from '@/components/SaveButton.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
let List = class List extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            {
                show: true,
                table: 'financeiro_categoria',
                text: 'Categoria',
                type: 'str',
                value: 'financeiro_categoria_nome',
                field: 'nome',
            },
            {
                show: true,
                table: 'tmp',
                text: 'Entidade - Nome',
                value: 'entidade_nome',
            },
            {
                show: true,
                table: 'tmp',
                text: 'Entidade - Tipo',
                value: 'entidade_tipo',
            },
            {
                show: true,
                table: 'tmp',
                text: 'Parcela',
                type: '',
                value: 'parcela',
            },
            {
                show: true,
                table: 'financeiro',
                text: 'Emissão',
                type: '',
                value: 'data_emissao',
            },
            {
                show: true,
                table: 'financeiro_parcela',
                text: 'Vencimento',
                type: '',
                value: 'vencimento',
            },
            {
                show: true,
                table: 'financeiro_parcela',
                text: 'Valor Integral',
                type: '',
                value: 'valor_integral',
            },
            {
                show: true,
                table: 'tmp',
                text: 'Valor com desconto',
                type: '',
                value: 'valor_original',
            },
            {
                show: true,
                table: 'tmp',
                text: 'Valor Atualizado',
                type: '',
                value: 'valor_atualizado',
            },
            {
                show: true,
                table: 'financeiro_parcela',
                text: 'Valor Pago',
                type: '',
                value: 'valor_pago',
            },
            {
                show: true,
                table: 'financeiro_parcela',
                text: 'Observação',
                type: 'str',
                value: 'observacao',
            },
            {
                show: true,
                table: 'financeiro_parcela_status',
                text: 'Status',
                type: '',
                value: 'financeiro_parcela_status_nome',
                field: 'nome',
            },
            {
                show: true,
                table: 'tmp',
                text: 'Origem dos pagamentos',
                type: 'str',
                value: 'origem',
            },
            {
                show: true,
                text: 'Ações',
                value: 'actions',
            },
        ];
        this.excluirParcelasHeaders = [
            { text: '', value: 'checked' },
            { text: 'Número da parcela', value: 'parcela_numero' },
            { text: 'Vencimento', value: 'vencimento' },
            { text: 'Valor Integral', value: 'valor_integral', align: 'left' },
            { text: 'Valor Atualizado', value: 'valor_atualizado', align: 'left' },
            { text: 'Valor Pago', value: 'valor_pago', align: 'left' },
            { text: 'Status', value: 'status', align: 'left' },
        ];
        this.headerVencido = [
            {
                text: 'Categoria',
                value: 'Categoria',
            },
            {
                text: 'A pagar - Valor integral (Sem desconto)',
                value: 'temp_receber_valor_integral_sem_desconto',
            },
            {
                text: 'A pagar - Valor com desconto',
                value: 'temp_receber_valor_com_desconto',
            },
            {
                text: 'A pagar - Valor atualizado',
                value: 'temp_receber_valor_atualizado',
            },
            {
                text: 'Valor pago',
                value: 'temp_valor_recebido',
            },
        ];
        this.excluirParcelasDialog = false;
        this.excluirParcelasOptions = [];
        this.excluirParcelaSelected = null;
        this.selectPeriodDate = [];
        this.dialogAnexos = false;
        this.anexosSelected = [];
        this.dialogFiltrosAdicionais = false;
        this.menu = false;
        this.rodando_mounted = true;
        this.valores_totalizadores = [];
        this.anexosHeaders = [
            { text: 'Nome', value: 'nome_arquivo' },
            { text: 'Tamanho', value: 'blob_size' },
            { text: 'Ações', value: 'actions' },
        ];
    }
    get allowView() {
        if (userToolStore.userToolsIndexed['Contas a pagar']) {
            return userToolStore.userToolsIndexed['Contas a pagar'][0].allow_view;
        }
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed['Contas a pagar']) {
            return userToolStore.userToolsIndexed['Contas a pagar'][0].allow_create;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed['Contas a pagar']) {
            return userToolStore.userToolsIndexed['Contas a pagar'][0].allow_edit;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed['Contas a pagar']) {
            return userToolStore.userToolsIndexed['Contas a pagar'][0].allow_delete;
        }
    }
    get allowViewContaBancaria() {
        if (userToolStore.userToolsIndexed['Contas bancárias']) {
            return userToolStore.userToolsIndexed['Contas bancárias'][0].allow_view;
        }
    }
    get allowViewCaixa() {
        if (userToolStore.userToolsIndexed.Caixa) {
            return userToolStore.userToolsIndexed.Caixa[0].allow_view;
        }
    }
    get userActionsVisualizarTotalizadoresAllow() {
        return userActionStore.visualizarTotalizadoresContasPagar;
    }
    get totals() {
        return analyticsStore.totals;
    }
    get isClearFiltersDisabled() {
        return this.selectPeriodDate.length === 0;
    }
    get formattedDate() {
        if (this.selectPeriodDate.length === 2) {
            const [start, end] = this.selectPeriodDate;
            return `${this.formatDate(start)} - ${this.formatDate(end)}`;
        }
        else if (this.selectPeriodDate.length === 1) {
            return `${this.formatDate(this.selectPeriodDate[0])}`;
        }
        return '';
    }
    clearFilters() {
        this.selectPeriodDate = [];
        this.$forceUpdate();
    }
    async selecionarTudo() {
        for (const key in this.totals) {
            if (this.totals[key].items) {
                this.totals[key].items.forEach(item => {
                    item.selected = true;
                });
            }
        }
    }
    async limparSelecionados() {
        for (const key in this.totals) {
            if (this.totals[key].items) {
                this.totals[key].items.forEach(item => {
                    item.selected = false;
                });
            }
        }
    }
    async refazerBusca(val) {
        if (val && val.length === 2 && !this.rodando_mounted) {
            await this.buscar();
        }
    }
    async openCaixa(id) {
        if (id) {
            // @ts-ignore
            const rota = this.$router.resolve({
                name: 'main-caixas-edit',
                params: { id },
            });
            window.open(rota.href, '_blank');
        }
    }
    async openContaBancaria(id) {
        if (id) {
            // @ts-ignore
            const rota = this.$router.resolve({
                name: 'main-contas-bancarias-edit',
                params: { id },
            });
            window.open(rota.href, '_blank');
        }
    }
    async deleteItem(item) {
        this.excluirParcelaSelected = item;
        this.excluirParcelasOptions =
            await financeiroParcelaStore.getFinanceiroParcelasByFinanceiro(item.financeiro_id);
        _.forEach(this.excluirParcelasOptions, (el) => {
            if (el.id === item.id) {
                el.checked = true;
            }
        });
        this.excluirParcelasDialog = true;
    }
    async confirmDeleteItem() {
        const itemsReq = [];
        _.forEach(this.excluirParcelasOptions, (item) => {
            if (item.checked) {
                itemsReq.push(item.id);
            }
        });
        const stringSwal = itemsReq.length > 0
            ? 'Confirma a exclusão das Contas?'
            : 'Confirma a exclusão da Conta?';
        // @ts-ignore
        this.$swal({
            title: stringSwal,
            text: 'Após exclusão as informações não podem ser recuperadas!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.excluirParcelasDialog = false;
                // @ts-ignore
                await financeiroParcelaStore.deleteConta(itemsReq);
                // @ts-ignore
                this.buscar();
            }
        });
    }
    async openAnexoDialog(id) {
        this.anexosSelected = await financeiroParcelaStore.getFinanceiroParcelasAnexos(id);
        this.dialogAnexos = true;
    }
    getAnexoSize(size) {
        if (size / 1000 < 1000) {
            const value = (size / 1000).toFixed(2);
            return `${value} kB`;
        }
        else if (size / 1000 >= 1000) {
            const value = (size / 1000000).toFixed(2);
            return `${value} MB`;
        }
    }
    async buscar() {
        analyticsStore.setDefaultFilters({
            'financeiro.tipo': {
                type: 'int',
                value: 1,
            },
            'tmp.vencimento': {
                type: 'timestamp',
                value: [this.selectPeriodDate[0], this.selectPeriodDate[1]],
            },
        });
        await this.doSearchTotals();
        await analyticsStore.executeSearchItems();
    }
    async doSearchItems(queryIn) {
        return await financeiroParcelaStore.getFinanceiroParcelasQuery(queryIn);
    }
    async doSearchTotals() {
        await analyticsStore.getAnalyticsFinanceiroParcela({
            data_inicial: this.selectPeriodDate[0],
            data_final: this.selectPeriodDate[1],
            tipo: 1,
            data_campo: 'financeiro_parcela.vencimento',
        });
        this.doSearchValorTotalizador();
    }
    async doSearchValorTotalizador() {
        // @ts-ignore
        const categorias = this.totals.por_categoria.items.filter((el) => !!el.selected).map((el) => el.id);
        this.valores_totalizadores = await analyticsStore.getAnalyticsValorTotalFinanceiroParcela({
            data_inicial: this.selectPeriodDate[0],
            data_final: this.selectPeriodDate[1],
            categorias: categorias,
            status_contrato: [],
            tipo: 1,
            data_campo: 'financeiro_parcela.vencimento'
        });
    }
    routeEdit(id) {
        const rota = this.$router.resolve({
            name: 'main-contasapagar-edit',
            params: { id },
        });
        window.open(rota.href, '_blank');
    }
    async handleOpenAnexo(bucketArquivoId) {
        const responseData = await financeiroParcelaStore.getUrlBucketArquivo({ bucketArquivoId });
        const element = document.createElement('a');
        element.setAttribute('href', responseData);
        element.setAttribute('target', '_blank');
        element.click();
        element.remove();
    }
    customSort(items, index, isDesc) {
        items.sort((a, b) => {
            if (index[0] === 'origem') {
                const origemA = a.origem?.conta_bancaria_ids?.[0]?.nome || '';
                const origemB = b.origem?.conta_bancaria_ids?.[0]?.nome || '';
                if (!isDesc[0]) {
                    return origemA < origemB ? -1 : 1;
                }
                else {
                    return origemB < origemA ? -1 : 1;
                }
            }
            else {
                if (!isDesc[0]) {
                    return a[index[0]] < b[index[0]] ? -1 : 1;
                }
                else {
                    return b[index[0]] < a[index[0]] ? -1 : 1;
                }
            }
        });
        return items;
    }
    async mounted() {
        analyticsStore.initAnalytics();
        analyticsStore.setFnSearchItems(financeiroParcelaStore.getFinanceiroParcelasQuery);
        analyticsStore.setHeaders(this.headers);
        // tela que tem defaultFilter nao precisa ter o buscar() aqui
        const date = new Date();
        this.selectPeriodDate = [
            new Date(date.getFullYear(), date.getMonth(), 1).toISOString().split('T')[0],
            new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().split('T')[0]
        ];
        await this.buscar();
        this.rodando_mounted = false;
    }
};
__decorate([
    Prop(Boolean)
], List.prototype, "financeiroType", void 0);
__decorate([
    Watch('selectPeriodDate')
], List.prototype, "refazerBusca", null);
List = __decorate([
    Component({
        components: {
            PeriodSelectorComponent,
            EntidadeLinkComponent,
            SearchEntidadeComponent,
            FinanceiroValorPagoTextComponent,
            GenericListTotalCardComponent,
            GenericListItemsCardComponent,
            TextExpandableComponent,
            OpenNewTabComponent,
            SaveButton,
            ButtonComponent,
        },
    })
], List);
export default List;
