import { __decorate } from "tslib";
import _ from 'lodash';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { companyConfiguracaoStore, mainStore, turmaAlunoStore, turmaStore, userActionStore } from '@/store';
import { Validator } from 'vee-validate';
import ClickableCard from '@/components/ClickableCard.vue';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
import FichaFrequenciaComponent from './FichaFrequenciaComponent.vue';
import EntidadeLinkComponent from '@/components/EntidadeLinkComponent.vue';
import LogShowComponent from '@/components/LogShowComponent.vue';
import TextExpandableComponent from '@/components/TextExpandableComponent.vue';
import GestaoAlunoReunioesComponent from '@/components/GestaoAlunoReunioesComponent.vue';
import LivroSolicitadoDiretoFranchiseButton from '@/components/LivroSolicitadoDiretoFranchiseButton.vue';
import ImprimirTabelaComponent from '@/components/ImprimirTabelaComponent.vue';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => !!value
});
let GestaoComponent = class GestaoComponent extends Vue {
    constructor() {
        super(...arguments);
        this.items = null;
        this.loading = false;
        this.tabelaRef = null;
        this.pedagogicoHeaders = [
            { text: '', value: 'idx' },
            // tres primeiros
            { text: 'Faltas', value: 'tres_meses_faltas' },
            { text: 'Reposições', value: 'tres_meses_reposicoes' },
            {
                // @ts-ignore
                text: `${this.getNomeHomework()} não entregue`,
                value: 'tres_meses_homework_nao_entregue'
            },
            // @ts-ignore
            { text: this.getNomeAulaExtraPlural(), value: 'tres_meses_aula_extra' },
            // quarto mes
            { text: 'Faltas', value: 'quarto_mes_faltas' },
            { text: 'Reposições', value: 'quarto_mes_reposicoes' },
            {
                // @ts-ignore
                text: `${this.getNomeHomework()} não entregue`,
                value: 'quarto_mes_homework_nao_entregue'
            },
            // @ts-ignore
            { text: this.getNomeAulaExtraPlural(), value: 'quarto_mes_aula_extra' },
            // quinto mes ate o final
            { text: 'Faltas', value: 'quinto_mes_faltas' },
            { text: 'Reposições', value: 'quinto_mes_reposicoes' },
            {
                // @ts-ignore
                text: `${this.getNomeHomework()} não entregue`,
                value: 'quinto_mes_homework_nao_entregue'
            },
            // @ts-ignore
            { text: this.getNomeAulaExtraPlural(), value: 'quinto_mes_aula_extra' }
        ];
        this.financeiroHeaders = [
            { text: '', value: 'idx' },
            { text: 'Material', value: 'parcelas_material' },
            { text: 'Mensalidade', value: 'parcelas_mensalidade' }
        ];
        this.retencaoHeaders = [
            { text: '', value: 'idx' },
            { text: 'Tipo', value: 'proxima_reuniao_tipo_id' },
            { text: 'Criada em', value: 'created_at' },
            { text: 'Agedada/Realizada', value: 'proxima_reuniao_data' },
            { text: 'Status', value: 'proxima_reuniao_status_id' },
            { text: 'Ações', value: 'actions' }
        ];
        this.administrativoHeaders = [
            { text: '', value: 'idx' },
            {
                text: '1ª parcela do próximo livro',
                value: 'primeira_parcela_proximo_livro'
            },
            {
                text: 'Aluno colocado em nova turma',
                value: 'aluno_colocado_nova_turma'
            },
            { text: '', value: 'actions' },
            { text: '', value: 'log' }
        ];
        this.id = null;
        this.dialog = false;
        this.dialogGestaoAlunoReunioes = false;
        this.dialogGestaoAlunoReunioesDetails = [];
        this.exibirSomenteVigente = null;
    }
    get alunoHeaders() {
        const ret = [];
        ret.push({ text: '', value: 'idx' });
        ret.push({ text: 'Nome', value: 'aluno_nome' });
        ret.push({ text: 'Idade', value: 'aluno_data_nascimento' });
        ret.push({ text: 'Telefone', value: 'aluno_celular' });
        ret.push({ text: '1ª Presença', value: 'primeira_presenca' });
        ret.push({ text: 'Livro entregue', value: 'livro_data_entrega' });
        ret.push({ text: 'Último contato', value: 'ultimo_contato' });
        if (this.userProfile.company.escola_id == 1) {
            ret.push({ text: 'Transporte', value: 'transporte_status' });
        }
        ret.push({ text: 'Status', value: 'status_do_aluno_na_turma_id' });
        ret.push({ text: 'Ativo Desde', value: 'ativo_desde' });
        ret.push({ text: 'Ativo Até', value: 'ativo_ate' });
        return ret;
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    get userActionsvisualizarInfoFinanceiraAlunoAbaGestaoAllow() {
        return userActionStore.visualizarInfoFinanceiraAlunoAbaGestaoAllow;
    }
    get userActionsVisualizarAdmnistrativoAlunoAbaGestaoAllow() {
        return userActionStore.visualizarAdmnistrativoAlunoAbaGestaoAllow;
    }
    get dadosTurma() {
        return turmaStore.turmaFicha;
    }
    get itemsFiltered() {
        return _.filter(this.items, (item) => {
            if (this.exibirSomenteVigente) {
                if (item.status_do_aluno_na_turma_id == 1) {
                    return item;
                }
            }
            else {
                return item;
            }
        });
    }
    openAlunoReunioesDetails(items) {
        this.dialogGestaoAlunoReunioesDetails = items;
        this.dialogGestaoAlunoReunioes = true;
    }
    openTurma(id) {
        const routeData = this.$router.resolve({
            name: 'main-turmas-edit',
            params: { id }
        });
        window.open(routeData.href, '_blank');
    }
    updateTurmaAluno(aluno, statusId) {
        let texto = '';
        if (statusId == 7) {
            texto = 'Confirma a aprovação do aluno?';
        }
        else if (statusId == 1) {
            texto = 'Confirma o retorno do status do aluno para vigente?';
        }
        else {
            texto = 'Confirma a reprovação do aluno?';
        }
        this.$swal({
            title: texto,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            confirmButtonText: 'Sim, eu confirmo!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                aluno.status_do_aluno_na_turma_id = statusId;
                await turmaAlunoStore.updateTurmaAluno(aluno);
            }
        });
    }
    entregaBoletim(aluno) {
        this.$swal({
            title: 'Confirma a entrega do boletim ao aluno?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            confirmButtonText: 'Sim, eu confirmo!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                aluno.boletim_entregue = true;
                await turmaAlunoStore.updateTurmaAluno(aluno);
            }
        });
    }
    showLogTurmaAluno(ref, id) {
        const form = ref;
        form.show(id);
    }
    async mounted() {
        if (this.$router.currentRoute.params.id) {
            this.loading = true;
            this.id = parseInt(this.$router.currentRoute.params.id, 0);
            await turmaStore.getTurmaAlunosFicha(this.id);
            await companyConfiguracaoStore.getCompanyConfiguracoes();
            if (this.dadosTurma) {
                // @ts-ignore
                this.items = this.dadosTurma.gestao;
            }
            this.exibirSomenteVigente = !(
            // @ts-ignore
            companyConfiguracaoStore.companyConfiguracoes.turma_exibir_todos_alunos);
            this.loading = false;
        }
        setTimeout(() => {
            this.tabelaRef = this.$refs.tabelaAlunos;
        }, 2000);
    }
};
__decorate([
    Prop({ type: Number, default: '' })
], GestaoComponent.prototype, "propTurmaId", void 0);
GestaoComponent = __decorate([
    Component({
        components: {
            ClickableCard,
            DateFieldComponent,
            EntidadeLinkComponent,
            FichaFrequenciaComponent,
            GestaoAlunoReunioesComponent,
            LivroSolicitadoDiretoFranchiseButton,
            LogShowComponent,
            TextExpandableComponent,
            ImprimirTabelaComponent,
        }
    })
], GestaoComponent);
export default GestaoComponent;
