import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
import { queryNew } from '@/interfaces/query';
import _ from 'lodash';
let AnalyticsModule = class AnalyticsModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.totals = {};
        this.filtros = {};
        this.items = [];
        this.fnSearchItems = null;
        this.skip = 0;
        this.limit = 100;
        this.loading = false;
        this.headers = [];
        // Quando a tela possui filtros
        this.defaultFilters = {};
    }
    setTotals(payload) {
        this.totals = payload;
    }
    setHeaders(payload) {
        this.headers = payload;
    }
    setLimit(limit) {
        this.limit = limit;
    }
    setSkip(skip) {
        this.skip = skip;
    }
    setItems(items) {
        this.items = items;
    }
    setLoading(loading) {
        this.loading = loading;
    }
    setDefaultFilters(defaultFilters) {
        this.defaultFilters = defaultFilters;
    }
    setFiltros(payload) {
        this.filtros = payload;
    }
    initAnalytics() {
        this.filtros = [];
        this.totals = {};
        this.defaultFilters = {};
        this.items = [];
    }
    setFnSearchItems(fn) {
        this.fnSearchItems = fn;
    }
    async executeSearchItems(initialize = true) {
        this.setLoading(true);
        if (initialize) {
            // precisa desse sleep aqui para que os headers sejam atualizados com o local storage
            // o $nextTick não é o suficiente para que seja atualizado
            await new Promise((resolve) => setTimeout(resolve, 50));
            this.setSkip(0);
            this.setItems([]);
        }
        const queryIn = queryNew();
        queryIn.skip = this.skip;
        queryIn.limit = this.limit;
        // Percorre os filtros do totals (cabeçalhos)
        const totalsArray = Object.values(this.totals);
        totalsArray.forEach((total) => {
            const totalItems = total.items;
            totalItems.forEach((totalItem) => {
                if (totalItem.selected) {
                    if (queryIn.params.hasOwnProperty(total.field_ref)) {
                        queryIn.params[total.field_ref].value.push(totalItem.id);
                    }
                    else {
                        queryIn.params[total.field_ref] = {
                            type: total.type,
                            value: [totalItem.id],
                        };
                    }
                }
            });
        });
        // percorre os filtros do search (filtros do data-table items)
        Object.keys(this.filtros).forEach((field_ref) => {
            let filter = this.filtros[field_ref];
            if (filter == '') {
                return;
            }
            const header = _.find(this.headers, (i) => i.value == field_ref);
            let type = header.type;
            if (type == 'cpf') {
                // se for CPF trata igual str
                type = 'str';
                filter = filter.replaceAll('.', '');
                filter = filter.replaceAll('-', '');
            }
            const table = header.table;
            let queryInFieldName = '';
            if (header.field) {
                queryInFieldName = `${header.table}.${header.field}`;
            }
            else {
                queryInFieldName = `${header.table}.${header.value}`;
            }
            queryIn.params[queryInFieldName] = {
                type,
                value: [filter],
            };
        });
        // Adiciona os filtros default
        queryIn.params = { ...queryIn.params, ...this.defaultFilters };
        // monta o fields, que sao os campos que a consulta precisa retornar
        this.headers.forEach((header) => {
            if (header.show && 'table' in header) {
                if (header.field) {
                    // se tiver field é porque precisa passar um apelido na query, pois existem casos
                    // em que é consultado dois campos com o mesmo nome em tabelas diferentes
                    queryIn.fields.push(`${header.table}.${header.field} AS ${header.value}`);
                }
                else {
                    queryIn.fields.push(`${header.table}.${header.value}`);
                }
            }
        });
        const res = await this.fnSearchItems(queryIn);
        // // @ts-ignore
        let newItems = [...this.items, ...res];
        const filtroNome = this.filtros['origem'];
        if (filtroNome) {
            newItems = newItems.filter((item) => {
                return (item.origem &&
                    item.origem.conta_bancaria_ids &&
                    item.origem.conta_bancaria_ids.some((conta) => conta.nome.toLowerCase().includes(filtroNome.toLowerCase())));
            });
        }
        this.setItems(newItems);
        this.setSkip(this.skip + this.limit);
        // this.setLimit(this.limit);
        this.setLoading(false);
    }
    async getAlunoPorContrato(por_tipo_contrato = false) {
        try {
            this.setLoading(true);
            const response = await api.getAnalyticsAlunoPorContrato(mainStore.token, por_tipo_contrato);
            if (response) {
                this.setTotals(response.data);
            }
            this.setLoading(false);
        }
        catch (error) {
            await mainStore.checkApiError(error);
            this.setLoading(false);
            return [];
        }
    }
    async getAnalyticsCadernoDesistencia(payload) {
        try {
            this.setLoading(true);
            const response = await api.getAnalyticsCadernoDesistencia(mainStore.token, payload);
            if (response) {
                this.setTotals(response.data);
            }
            this.setLoading(false);
        }
        catch (error) {
            await mainStore.checkApiError(error);
            this.setLoading(false);
            return [];
        }
    }
    async getAnalyticsCadernoEvadidos(payload) {
        try {
            this.setLoading(true);
            const response = await api.getAnalyticsCadernoEvadidos(mainStore.token, payload);
            if (response) {
                this.setTotals(response.data);
            }
            this.setLoading(false);
        }
        catch (error) {
            await mainStore.checkApiError(error);
            this.setLoading(false);
            return [];
        }
    }
    async getAnalyticsCadernoAgendado(payload) {
        try {
            this.setLoading(true);
            const response = await api.getAnalyticsCadernoAgendado(mainStore.token, payload);
            if (response) {
                this.setTotals(response.data);
            }
            this.setLoading(false);
        }
        catch (error) {
            await mainStore.checkApiError(error);
            this.setLoading(false);
            return [];
        }
    }
    async getAnalyticsParceriaCupons(payload) {
        try {
            this.setLoading(true);
            const response = await api.getAnalyticsParceriaCupons(mainStore.token, payload.parceriaId, payload.cdaId);
            if (response) {
                this.setTotals(response.data);
            }
            this.setLoading(false);
        }
        catch (error) {
            await mainStore.checkApiError(error);
            this.setLoading(false);
            return [];
        }
    }
    async getAnalyticsTurmas(payload) {
        try {
            this.setLoading(true);
            const response = await api.getAnalyticsTurmas(mainStore.token, payload);
            if (response) {
                this.setTotals(response.data);
            }
            this.setLoading(false);
        }
        catch (error) {
            await mainStore.checkApiError(error);
            this.setLoading(false);
            return [];
        }
    }
    async getAnalyticsReposicao(payload) {
        try {
            this.setLoading(true);
            const response = await api.getAnalyticsReposicao(mainStore.token, payload);
            if (response) {
                this.setTotals(response.data);
            }
            this.setLoading(false);
        }
        catch (error) {
            await mainStore.checkApiError(error);
            this.setLoading(false);
            return [];
        }
    }
    async getAnalyticsAulaExtra(payload) {
        try {
            this.setLoading(true);
            const response = await api.getAnalyticsAulaExtra(mainStore.token, payload);
            if (response) {
                this.setTotals(response.data);
            }
            this.setLoading(false);
        }
        catch (error) {
            await mainStore.checkApiError(error);
            this.setLoading(false);
            return [];
        }
    }
    async getAnalyticsProspex(payload) {
        try {
            this.setLoading(true);
            const response = await api.getAnalyticsProspex(mainStore.token, payload);
            if (response) {
                this.setTotals(response.data);
            }
            this.setLoading(false);
        }
        catch (error) {
            await mainStore.checkApiError(error);
            this.setLoading(false);
            return [];
        }
    }
    async getAnalyticsCadernoExterno(payload) {
        try {
            this.setLoading(true);
            const response = await api.getAnalyticsCadernoExterno(mainStore.token, payload);
            if (response) {
                this.setTotals(response.data);
            }
            this.setLoading(false);
        }
        catch (error) {
            await mainStore.checkApiError(error);
            this.setLoading(false);
            return [];
        }
    }
    async getAnalyticsOcorrencia(payload) {
        try {
            this.setLoading(true);
            const response = await api.getAnalyticsOcorrencia(mainStore.token, payload);
            if (response) {
                this.setTotals(response.data);
            }
            this.setLoading(false);
        }
        catch (error) {
            await mainStore.checkApiError(error);
            this.setLoading(false);
            return [];
        }
    }
    async getAnalyticsSyngooHistoricoEnvio(payload) {
        try {
            this.setLoading(true);
            const response = await api.getAnalyticsSyngooHistoricoEnvio(mainStore.token, payload);
            if (response) {
                this.setTotals(response.data);
            }
            this.setLoading(false);
        }
        catch (error) {
            await mainStore.checkApiError(error);
            this.setLoading(false);
            return [];
        }
    }
    async getAnalyticsSyngooEnvioDeMensagem() {
        try {
            this.setLoading(true);
            const response = await api.getAnalyticsSyngooEnvioDeMensagem(mainStore.token);
            if (response) {
                this.setTotals(response.data);
            }
            this.setLoading(false);
        }
        catch (error) {
            await mainStore.checkApiError(error);
            this.setLoading(false);
            return [];
        }
    }
    async getAnalyticsRelatorioFaltas(payload) {
        try {
            this.setLoading(true);
            const response = await api.getAnalyticsRelatorioFaltas(mainStore.token, payload);
            if (response) {
                this.setTotals(response.data);
            }
            this.setLoading(false);
        }
        catch (error) {
            await mainStore.checkApiError(error);
            this.setLoading(false);
            return [];
        }
    }
    async getAnalyticsFinanceiroParcela(payload) {
        try {
            this.setLoading(true);
            const response = await api.getAnalyticsFinanceiroParcela(mainStore.token, payload);
            if (response) {
                this.setTotals(response.data);
            }
            this.setLoading(false);
        }
        catch (error) {
            await mainStore.checkApiError(error);
            this.setLoading(false);
            return [];
        }
    }
    async getAnalyticsValorTotalFinanceiroParcela(payload) {
        try {
            const response = await api.getAnalyticsValorTotalFinanceiroParcela(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
            return [];
        }
    }
    async getAnalyticsFinanceiroParcelaMovimentacao(payload) {
        try {
            this.setLoading(true);
            const response = await api.getAnalyticsFinanceiroParcelaMovimentacao(mainStore.token, payload);
            if (response) {
                this.setTotals(response.data);
            }
            this.setLoading(false);
        }
        catch (error) {
            await mainStore.checkApiError(error);
            this.setLoading(false);
            return [];
        }
    }
    async getAnalyticsValorTotalFinanceiroParcelaMovimentacao(payload) {
        try {
            const response = await api.getAnalyticsValorTotalFinanceiroParcelaMovimentacao(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
            this.setLoading(false);
            return [];
        }
    }
    async getAnalyticsEntregaLivro() {
        try {
            this.setLoading(true);
            const response = await api.getAnalyticsEntregaLivro(mainStore.token);
            if (response) {
                this.setTotals(response.data);
            }
            this.setLoading(false);
        }
        catch (error) {
            await mainStore.checkApiError(error);
            this.setLoading(false);
            return [];
        }
    }
    async getAnalyticsAniversariantes(mes_id) {
        try {
            this.setLoading(true);
            const response = await api.getAnalyticsAniversariantes(mainStore.token, mes_id);
            if (response) {
                this.setTotals(response.data);
            }
            this.setLoading(false);
        }
        catch (error) {
            await mainStore.checkApiError(error);
            this.setLoading(false);
            return [];
        }
    }
};
__decorate([
    Mutation
], AnalyticsModule.prototype, "setTotals", null);
__decorate([
    Mutation
], AnalyticsModule.prototype, "setHeaders", null);
__decorate([
    Mutation
], AnalyticsModule.prototype, "setLimit", null);
__decorate([
    Mutation
], AnalyticsModule.prototype, "setSkip", null);
__decorate([
    Mutation
], AnalyticsModule.prototype, "setItems", null);
__decorate([
    Mutation
], AnalyticsModule.prototype, "setLoading", null);
__decorate([
    Mutation
], AnalyticsModule.prototype, "setDefaultFilters", null);
__decorate([
    Mutation
], AnalyticsModule.prototype, "setFiltros", null);
__decorate([
    Mutation
], AnalyticsModule.prototype, "initAnalytics", null);
__decorate([
    Mutation
], AnalyticsModule.prototype, "setFnSearchItems", null);
__decorate([
    Action
], AnalyticsModule.prototype, "executeSearchItems", null);
__decorate([
    Action
], AnalyticsModule.prototype, "getAlunoPorContrato", null);
__decorate([
    Action
], AnalyticsModule.prototype, "getAnalyticsCadernoDesistencia", null);
__decorate([
    Action
], AnalyticsModule.prototype, "getAnalyticsCadernoEvadidos", null);
__decorate([
    Action
], AnalyticsModule.prototype, "getAnalyticsCadernoAgendado", null);
__decorate([
    Action
], AnalyticsModule.prototype, "getAnalyticsParceriaCupons", null);
__decorate([
    Action
], AnalyticsModule.prototype, "getAnalyticsTurmas", null);
__decorate([
    Action
], AnalyticsModule.prototype, "getAnalyticsReposicao", null);
__decorate([
    Action
], AnalyticsModule.prototype, "getAnalyticsAulaExtra", null);
__decorate([
    Action
], AnalyticsModule.prototype, "getAnalyticsProspex", null);
__decorate([
    Action
], AnalyticsModule.prototype, "getAnalyticsCadernoExterno", null);
__decorate([
    Action
], AnalyticsModule.prototype, "getAnalyticsOcorrencia", null);
__decorate([
    Action
], AnalyticsModule.prototype, "getAnalyticsSyngooHistoricoEnvio", null);
__decorate([
    Action
], AnalyticsModule.prototype, "getAnalyticsSyngooEnvioDeMensagem", null);
__decorate([
    Action
], AnalyticsModule.prototype, "getAnalyticsRelatorioFaltas", null);
__decorate([
    Action
], AnalyticsModule.prototype, "getAnalyticsFinanceiroParcela", null);
__decorate([
    Action
], AnalyticsModule.prototype, "getAnalyticsValorTotalFinanceiroParcela", null);
__decorate([
    Action
], AnalyticsModule.prototype, "getAnalyticsFinanceiroParcelaMovimentacao", null);
__decorate([
    Action
], AnalyticsModule.prototype, "getAnalyticsValorTotalFinanceiroParcelaMovimentacao", null);
__decorate([
    Action
], AnalyticsModule.prototype, "getAnalyticsEntregaLivro", null);
__decorate([
    Action
], AnalyticsModule.prototype, "getAnalyticsAniversariantes", null);
AnalyticsModule = __decorate([
    Module({ name: 'analytics' })
], AnalyticsModule);
export default AnalyticsModule;
