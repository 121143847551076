import { __decorate } from "tslib";
import { Vue, Component, Watch } from 'vue-property-decorator';
import { caixaStore, companyConfiguracaoStore, contaBancariaFormaPagamentoStore, contaBancariaStore, financeiroCategoriaStore, mainStore, userActionStore, userToolStore, } from '@/utils/store-accessor';
import ShowPopup from '@/components/ShowPopup.vue';
import _ from 'lodash';
import LogShowComponent from '@/components/LogShowComponent.vue';
import EntidadeLinkComponent from '@/components/EntidadeLinkComponent.vue';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import FinanceiroValorPagoTextComponent from '@/components/FinanceiroValorPagoTextComponent.vue';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser escolhida!`,
    validate: async (value) => value !== null && value !== undefined,
});
let Show = class Show extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            { text: 'Hora', value: 'created_at' },
            { text: 'Aluno', value: 'aluno_nome' },
            { text: 'Valor', value: 'valor' },
            { text: 'Descrição', value: 'financeiro_observacao' },
            { text: 'Parcela', value: 'financeiro_parcela_parcela_numero' },
            { text: 'Vencimento', value: 'financeiro_parcela_vencimento' },
            { text: 'Tipo', value: 'financeiro_categoria_nome' },
            { text: 'Forma de pagamento', value: 'forma_de_pagamento' },
            { text: 'Observação', value: 'observacao' },
        ];
        this.headersConferidos = [
            { text: 'Nome da conta', value: 'conta_nome' },
            { text: 'Valor total', value: 'valor' },
        ];
        this.anexosHeaders = [
            { text: 'Nome', value: 'blob_name' },
            { text: 'Tamanho', value: 'blob_size' },
            { text: 'Ações', value: 'actions' },
        ];
        this.anexosSelected = [];
        this.dialogAnexos = false;
        this.aberturaCaixaValor = 0;
        this.backupSelectedCaixaItem = { id: 0 };
        this.caixa = null;
        this.caixaItems = [];
        this.dialogConferir = false;
        this.id = null;
        this.isPrinting = false;
        this.loading = true;
        this.search = '';
        this.selectedCaixaItem = { selected: false };
        this.selectedContaBancaria = { id: 0 };
        this.showSelectCaixaItemsTable = true;
        this.valorTransferirConferencia = 0;
        this.recebimentosItems = [];
        this.movimentacoesConferidas = [];
        this.currentCodigosRepetidos = [];
        this.codigosRepetidosDialog = false;
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed.Caixa) {
            return userToolStore.userToolsIndexed.Caixa[0].allow_edit;
        }
    }
    get userActionsAcessoAOLogDoSistema() {
        return userActionStore.acessoAOLogDoSistema;
    }
    get userActionsVisualizarAnexosMovimentacoesCaixa() {
        return userActionStore.visualizarAnexosMovimentacoesCaixa;
    }
    showCodigosRepetidos(codigos) {
        this.currentCodigosRepetidos = codigos;
        this.codigosRepetidosDialog = true;
    }
    async setConferenciaItems() {
        this.loading = true;
        this.caixaItems = await caixaStore.getCaixaMovimentacao(this.$router.currentRoute.params.id);
        this.loading = false;
        this.dialogConferir = true;
        this.valorTransferirConferencia =
            +this.saldoConferenciaParaTransferir.toFixed(2);
        const items = _.filter(this.filteredCaixaItems, (i) => i.observacao != 'Abertura do caixa');
        const agrupados = _.groupBy(items, 'forma_de_pagamento');
        const contaPadrao = _.find(this.contasBancarias, (item) => item.conta_padrao);
        const ret = Object.keys(agrupados).map((key) => {
            const contaFormaDePagamento = _.find(this.contasBancariasFormasPagamento, (item) => item.forma_pagamento_id == key);
            return {
                forma_pagamento_id: key,
                valor_total: _.sumBy(agrupados[key], (i) => +i.valor)
                    .toFixed(2)
                    .toString()
                    .replace('.', ','),
                data: agrupados[key],
                transferencias: [
                    {
                        valor: _.sumBy(agrupados[key], (i) => i.valor)
                            .toFixed(2)
                            .toString()
                            .replace('.', ','),
                        conta: contaFormaDePagamento
                            ? _.find(this.contasBancarias, (item) => item.id == contaFormaDePagamento.conta_bancaria_id)
                            : contaPadrao,
                    },
                ],
            };
        });
        const aberturaCaixaObj = _.find(this.caixaItems, (val) => val.observacao == 'Abertura do caixa');
        // abertura do caixa
        if (aberturaCaixaObj) {
            if (aberturaCaixaObj.valor > 0) {
                ret.push({
                    forma_pagamento_id: '1',
                    valor_total: aberturaCaixaObj.valor,
                    // @ts-ignore
                    transferencias: [
                        {
                            valor: aberturaCaixaObj.valor,
                            conta: null,
                        },
                    ],
                    // @ts-ignore
                    aberturaCaixa: true,
                    data: [aberturaCaixaObj],
                });
            }
        }
        this.recebimentosItems = _.orderBy(ret, ['forma_pagamento_id', 'aberturaCaixa'], ['desc', 'desc']);
    }
    salvarValorFormaDeRecebimento(item) {
        if (item.isRecemCriado && item.valor > 0) {
            item.isRecemCriado = false;
        }
    }
    async adicionarFormaDeRecebimento(formaDeRecebimento) {
        formaDeRecebimento.transferencias.push({
            valor: 0,
            conta: null,
            isAdicional: true,
        });
    }
    async removerFormaDeRecebimento(formaDeRecebimento, item) {
        const idx = this.recebimentosItems.indexOf(formaDeRecebimento);
        const idx_item = this.recebimentosItems[idx].transferencias.indexOf(item);
        this.recebimentosItems[idx].transferencias.splice(idx_item, 1);
    }
    valorRestante(formaDeRecebimento) {
        const valorRestante = _.sumBy(formaDeRecebimento.transferencias, (o) => {
            // @ts-ignore
            if (o.valor) {
                // @ts-ignore
                if (typeof o.valor === 'string' || o.valor instanceof String) {
                    // @ts-ignore
                    return +o.valor.replace(',', '.');
                    // @ts-ignore
                }
                else if (o.valor) {
                    // @ts-ignore
                    return o.valor;
                }
                else {
                    return 0;
                }
            }
        });
        if (typeof formaDeRecebimento.valor_total === 'string' ||
            formaDeRecebimento.valor_total instanceof String) {
            // @ts-ignore
            return (+formaDeRecebimento.valor_total.replace(',', '.') -
                (valorRestante ? valorRestante : 0)).toFixed(2);
        }
        else {
            // @ts-ignore
            return (formaDeRecebimento.valor_total - (valorRestante ? valorRestante : 0)).toFixed(2);
        }
    }
    get contasBancariasFormasPagamento() {
        return contaBancariaFormaPagamentoStore.contasBancariasFormasPagamentos;
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    get companyConfiguracoes() {
        return companyConfiguracaoStore.companyConfiguracoes;
    }
    showFinanceiroButton(item) {
        if (item.financeiro_tipo == 0) {
            if (this.allowViewContaReceber) {
                return true;
            }
        }
        if (item.financeiro_tipo == 1) {
            if (this.allowViewContaPagar) {
                return true;
            }
        }
    }
    get allowViewContaReceber() {
        if (userToolStore.userToolsIndexed['Contas a receber']) {
            return userToolStore.userToolsIndexed['Contas a receber'][0]
                .allow_view;
        }
    }
    get allowViewContaPagar() {
        if (userToolStore.userToolsIndexed['Contas a pagar']) {
            return userToolStore.userToolsIndexed['Contas a pagar'][0]
                .allow_view;
        }
    }
    get userActionsConferirCaixaAllow() {
        return userActionStore.conferirCaixaAllow;
    }
    get contasBancarias() {
        return contaBancariaStore.contasBancarias;
    }
    get financeiroCategoria() {
        return financeiroCategoriaStore.FinanceiroCategorias;
    }
    get saldoConferenciaParaTransferir() {
        return _.sumBy(this.filteredCaixaItems, (item) => {
            return item.valor;
        });
    }
    get filteredCaixaItems() {
        return _.filter(this.caixaItems, (val) => val.financeiro_categoria_nome !== 'Troco'
        // && val.observacao !== 'Abertura do caixa'
        );
    }
    onChangeValorTransferirConferencia(val) {
        if (+val.toFixed(2) > +this.saldoConferenciaParaTransferir.toFixed(2)) {
            this.$nextTick(() => {
                this.valorTransferirConferencia =
                    +this.saldoConferenciaParaTransferir.toFixed(2);
            });
        }
    }
    editCaixaItem() {
        // @ts-ignore
        this.backupSelectedCaixaItem = { ...this.selectedCaixaItem };
        this.showSelectCaixaItemsTable = false;
    }
    async saveEditCaixaItem() {
        await caixaStore.updateCaixaMovimentacaoDetalhamento(this.selectedCaixaItem);
        this.backupSelectedCaixaItem = { id: 0 };
        this.selectedCaixaItem.selected = false;
        this.selectedCaixaItem = { selected: false };
        this.showSelectCaixaItemsTable = true;
    }
    cancelEditCaixaItem() {
        const val = _.find(this.filteredCaixaItems, (item) => item.id === this.backupSelectedCaixaItem.id);
        for (const key of Object.keys(this.backupSelectedCaixaItem)) {
            val[key] = this.backupSelectedCaixaItem[key];
        }
        this.backupSelectedCaixaItem = { id: 0 };
        this.showSelectCaixaItemsTable = true;
    }
    onChangeSelectedCaixaItem(item) {
        this.selectedCaixaItem = item;
        // @ts-ignore
        this.backupSelectedCaixaItem = { ...this.selectedCaixaItem };
        this.showSelectCaixaItemsTable = false;
    }
    async openContaBancaria(id) {
        // @ts-ignore
        this.$router.push({
            name: 'main-contas-bancarias-edit',
            params: { id },
        });
    }
    async openCaixa(id) {
        // @ts-ignore
        const rota = this.$router.resolve({ name: 'main-caixas-edit', params: { id } });
        window.open(rota.href, '_blank');
    }
    async openFinanceiro(item) {
        if (item.financeiro_tipo) {
            // @ts-ignore
            this.$router.push({
                name: 'main-contasapagar-edit',
                params: { id: item.financeiro_parcela_id },
            });
        }
        else {
            // @ts-ignore
            this.$router.push({
                name: 'main-contasareceber-edit',
                params: { id: item.financeiro_parcela_id },
            });
        }
    }
    showLog(ref) {
        const form = ref;
        form.show();
    }
    showLogMovimentacoes(ref, id) {
        ref.id = id;
        const form = ref;
        form.show();
    }
    async conferir() {
        const isValoresFaltantes = _.some(this.recebimentosItems, (item) => {
            return +this.valorRestante(item) != 0;
        });
        if (this.loading) {
            return;
        }
        this.loading = true;
        if (
        // @ts-ignore
        this.companyConfiguracoes.caixa_conferencia_obrigar_selecao_conta_bancaria) {
            const isValid = await this.$refs.conferirObserver.validate();
            if (!isValid) {
                if (isValoresFaltantes) {
                    mainStore.addErrorToasted({
                        // @ts-ignore
                        toasted: this.$toasted,
                        errors: ['Alguns valores não foram corretamente distribuídos'],
                    });
                }
                this.loading = false;
                return;
            }
        }
        if (isValoresFaltantes) {
            mainStore.addErrorToasted({
                // @ts-ignore
                toasted: this.$toasted,
                errors: ['Alguns valores não foram corretamente distribuídos'],
            });
            this.loading = false;
            return;
        }
        const items = [];
        _.forEach(this.recebimentosItems, (item) => {
            _.forEach(item.transferencias, (transferencia) => {
                items.push({
                    forma_pagamento_id: item.forma_pagamento_id,
                    valor: transferencia.valor,
                    conta: transferencia.conta,
                    aberturaCaixa: item.aberturaCaixa,
                    data: item.data,
                    caixa_id: this.id,
                });
            });
        });
        const id = parseInt(this.$router.currentRoute.params.id, 0);
        const itemsComConta = _.filter(items, (item) => !!item.conta);
        await contaBancariaStore.createContaBancariaMovimentacoesConferenciaDeCaixa({
            id,
            items,
        });
        this.caixa.status = 2;
        this.loading = false;
        this.movimentacoesConferidas =
            await contaBancariaStore.getContaBancariaMovimentacoesByCaixaId(this.id);
        this.dialogConferir = false;
    }
    async mounted() {
        this.loading = true;
        this.id = this.$router.currentRoute.params.id;
        await companyConfiguracaoStore.getCompanyConfiguracoes();
        await contaBancariaStore.getContasBancarias(true);
        await contaBancariaFormaPagamentoStore.getContasBancariasFormasPagamentos();
        this.selectedContaBancaria = _.find(this.contasBancarias, (item) => item.conta_padrao);
        this.caixa = await caixaStore.getCaixa(this.id);
        if (this.caixa.status === 2) {
            this.movimentacoesConferidas =
                await contaBancariaStore.getContaBancariaMovimentacoesByCaixaId(this.id);
        }
        this.caixaItems = await caixaStore.getCaixaMovimentacao(this.$router.currentRoute.params.id);
        this.loading = false;
        if (this.allowViewContaReceber || this.allowViewContaPagar) {
            this.headers.push({ text: 'Valor integral do financeiro', value: 'valor_integral' });
            this.headers.push({ text: 'Valor com desconto do financeiro', value: 'valor_original' });
            this.headers.push({ text: 'Total pago no financeiro', value: 'valor_pago' });
            this.headers.push({ text: 'Financeiro', value: 'financeiro' });
        }
        if (this.userActionsConferirCaixaAllow && this.caixa) {
            this.headers.push({ text: '', value: 'actions' });
        }
    }
    get caixaItemsByFormaDePagamento() {
        return _(this.filteredCaixaItems)
            .groupBy('forma_pagamento_nome')
            .map((item, id) => ({
            forma_pagamento_nome: id,
            valor: _.sumBy(item, 'valor'),
        }))
            .value();
    }
    get caixaItemsByFinanceiroCategoria() {
        return _(this.filteredCaixaItems)
            .groupBy('financeiro_categoria_nome')
            .map((item, id) => ({
            financeiro_categoria_nome: id,
            valor: _.sumBy(item, 'valor'),
        }))
            .value();
    }
    get caixaTotal() {
        return _.sumBy(this.filteredCaixaItems, (item) => {
            if (item.valor > 0) {
                return item.valor;
            }
            else {
                return 0;
            }
        });
    }
    get saldoFinal() {
        return _.sumBy(this.filteredCaixaItems, (item) => {
            if (item.financeiro_categoria_nome === 'Troco') {
                return 0;
            }
            return item.valor;
        });
    }
    async getAnexosByMovimetacao(id) {
        this.anexosSelected = await caixaStore.getAnexosByMovimetacaoId(id);
        this.dialogAnexos = true;
    }
    getAnexoSize(size) {
        if (size / 1000 < 1000) {
            const value = (size / 1000).toFixed(2);
            return `${value} kB`;
        }
        else if (size / 1000 >= 1000) {
            const value = (size / 1000000).toFixed(2);
            return `${value} MB`;
        }
    }
    getNomeAnexo(nome) {
        return nome.split('-').slice(2).join('-');
    }
    async handleOpenAnexo(url) {
        const element = document.createElement('a');
        element.setAttribute('href', url);
        element.setAttribute('target', '_blank');
        element.click();
        element.remove();
    }
    showDetails(id) {
        // @ts-ignore
        const form = this.$refs.showPopupCaixa;
        form.show(id);
    }
    async imprimirPDF() {
        const parts = this.caixa.data.split('-');
        // @ts-ignore
        const date = parts[2] + '/' + parts[1] + '/' + parts[0];
        const payload = {
            usuario_nome: this.caixa.user.full_name,
            items: this.filteredCaixaItems,
            totaisPorFormaPagamento: this.caixaItemsByFormaDePagamento,
            totaisPorCategoria: this.caixaItemsByFinanceiroCategoria,
            caixa_data: date,
        };
        const html = await caixaStore.imprimirCaixa(payload);
        if (html) {
            const w = window.open();
            w.document.write(html);
            setTimeout(() => {
                w.print();
            }, 500);
        }
    }
};
__decorate([
    Watch('valorTransferirConferencia')
], Show.prototype, "onChangeValorTransferirConferencia", null);
Show = __decorate([
    Component({
        components: {
            ShowPopup,
            LogShowComponent,
            EntidadeLinkComponent,
            OpenNewTabComponent,
            FinanceiroValorPagoTextComponent,
            ValidationObserver,
            ValidationProvider,
        },
    })
], Show);
export default Show;
