import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { ocorrenciaTarefaStore, } from '@/utils/store-accessor';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import ShowPopup from '@/components/ShowPopup.vue';
import _ from 'lodash';
import SearchEntidadeComponent from '@/components/SearchEntidadeComponent.vue';
import ClickableCard from '@/components/ClickableCard.vue';
import TextExpandableComponent from '@/components/TextExpandableComponent.vue';
import TarefaAgendaReuniaoComponent from '@/components/TarefaAgendaReuniaoComponent.vue';
import TarefaChangeStatusComponent from '@/components/TarefaChangeStatusComponent.vue';
import PeriodSelectorUpdatedComponent from '@/components/PeriodSelectorUpdatedComponent.vue';
let List = class List extends Vue {
    constructor() {
        super(...arguments);
        this.header = [
            { text: 'Criado por', value: 'criado_por_nome' },
            { text: 'Criado em', value: 'created_at' },
            { text: 'Entidade', value: 'ocorrencia' },
            { text: 'Descrição', value: 'descricao' },
            { text: 'Reunião', value: 'pedido_reuniao_tipo_id' },
            { text: 'Data do pedido', value: 'data_hora_agendamento' },
            { text: 'Status', value: 'status_id' },
            { text: '', value: 'actions' },
        ];
        this.items = [];
        this.selectPeriodDate = [];
        this.search = '';
        this.currentId = null;
        this.currentOcorrenciaId = null;
        this.reuniaoMarcadaDialog = false;
        this.tarefaCanceladaDialog = false;
        this.loading = false;
        this.selectedStatus = null;
        this.statusPedidoOptions = [
            { id: 0, text: 'Pendente' },
            { id: 1, text: 'Marcado' },
            { id: 2, text: 'Cancelado' }
        ];
    }
    get filteredItems() {
        if (!(typeof this.selectedStatus === 'number' && isFinite(this.selectedStatus))) {
            return this.items;
        }
        return _.filter(this.items, (el) => el.status_id == this.selectedStatus);
    }
    getStatusPedido(value) {
        switch (value) {
            case 0:
                return 'Pendente';
            case 1:
                return 'Marcado';
            case 2:
                return 'Cancelado';
        }
    }
    getTarefaStatusClass(status) {
        switch (status) {
            case 'Realizada':
                return 'success';
            case 'Pendente':
                return '';
            case 'Cancelada':
                return 'error';
        }
    }
    async tarefaRealizada(item) {
        this.currentId = item.id;
        this.currentOcorrenciaId = item.ocorrencia_id;
        this.reuniaoMarcadaDialog = true;
    }
    async tarefaCancelada(item) {
        this.currentId = item.id;
        this.currentOcorrenciaId = item.ocorrencia_id;
        this.tarefaCanceladaDialog = true;
    }
    async buscar() {
        this.loading = true;
        this.items = await ocorrenciaTarefaStore.getOcorrenciaTarefasPedidosReuniao({
            data_inicial: this.selectPeriodDate[0],
            data_final: this.selectPeriodDate[1],
        });
        this.loading = false;
    }
    async onConfirm() {
        this.buscar();
    }
    async mounted() {
        this.$nextTick(() => {
            this.buscar();
        });
    }
};
List = __decorate([
    Component({
        components: {
            ClickableCard,
            SearchEntidadeComponent,
            ShowPopup,
            TarefaAgendaReuniaoComponent,
            TarefaChangeStatusComponent,
            TextExpandableComponent,
            OpenNewTabComponent,
            PeriodSelectorUpdatedComponent
        },
    })
], List);
export default List;
