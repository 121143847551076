import { __decorate } from "tslib";
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
let ConfigMetasComponent = class ConfigMetasComponent extends Vue {
    constructor() {
        super(...arguments);
        this.editMetaData = {
            meta1: '',
            meta2: '',
            meta3: '',
            bonus1: '',
            bonus2: '',
            bonus3: '',
        };
    }
    formatCurrency(field) {
        const rawValue = this.editMetaData[field].toString().replace(/[^\d]/g, '');
        const formattedValue = new Intl.NumberFormat('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: true,
        }).format(Number(rawValue) / 100);
        this.editMetaData[field] = formattedValue;
    }
    preventNonNumeric(event) {
        const charCode = event.key.charCodeAt(0);
        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
        }
    }
    formatForApi(value) {
        const rawValue = value.replace(/[^\d,]/g, '').replace(',', '.');
        return rawValue || '';
    }
    loadEditData() {
        if (this.selectedItem) {
            this.editMetaData = {
                meta1: this.selectedItem.meta_01,
                meta2: this.selectedItem.meta_02,
                meta3: this.selectedItem.meta_03,
                bonus1: this.selectedItem.bonificacao_01,
                bonus2: this.selectedItem.bonificacao_02,
                bonus3: this.selectedItem.bonificacao_03,
            };
        }
    }
    closeEditDialog() {
        this.onClose();
    }
    saveEdit() {
        const updatedItem = {
            ...this.selectedItem,
            bonus1: this.editMetaData.bonus1,
            bonus2: this.editMetaData.bonus2,
            bonus3: this.editMetaData.bonus3,
            meta_01: this.editMetaData.meta1,
            meta_02: this.editMetaData.meta2,
            meta_03: this.editMetaData.meta3,
            bonificacao_01: this.formatForApi(this.editMetaData.bonus1),
            bonificacao_02: this.formatForApi(this.editMetaData.bonus2),
            bonificacao_03: this.formatForApi(this.editMetaData.bonus3),
        };
        this.onSave(updatedItem);
        this.closeEditDialog();
    }
    handler(newItem) {
        if (newItem) {
            this.loadEditData();
        }
    }
    mounted() {
        this.loadEditData();
    }
};
__decorate([
    Prop({ type: Boolean, required: true })
], ConfigMetasComponent.prototype, "editDialog", void 0);
__decorate([
    Prop({ type: Object, required: false, default: () => ({}) })
], ConfigMetasComponent.prototype, "selectedItem", void 0);
__decorate([
    Prop({ type: Function, required: true })
], ConfigMetasComponent.prototype, "onClose", void 0);
__decorate([
    Prop({ type: Function, required: true })
], ConfigMetasComponent.prototype, "onSave", void 0);
__decorate([
    Watch('selectedItem')
], ConfigMetasComponent.prototype, "handler", null);
ConfigMetasComponent = __decorate([
    Component
], ConfigMetasComponent);
export default ConfigMetasComponent;
