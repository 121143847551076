import { __decorate } from "tslib";
// import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
// import { mainStore } from '@/utils/store-accessor';
let ObjecaoModule = class ObjecaoModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.objecoes = [];
    }
    setObjecoes(payload) {
        this.objecoes = payload;
    }
    async getObjecaos() {
        this.setObjecoes([
            { nome: 'Mudar para carnê ', tool_id: null, id: 125, assunto: 'Alteração de forma de pgto', departamento_id: 6 },
            { nome: 'Mudar para pix', tool_id: null, id: 124, assunto: 'Alteração de forma de pgto', departamento_id: 6 },
            { nome: 'Mudar para boleto', tool_id: null, id: 123, assunto: 'Alteração de forma de pgto', departamento_id: 6 },
            { nome: 'Mudar para crédito recorrente', tool_id: null, id: 126, assunto: 'Alteração de forma de pgto', departamento_id: 6 },
            { nome: 'Problemas técnicos', tool_id: null, id: 42, assunto: 'Alteração de vencimento', departamento_id: 2 },
            { nome: 'Problemas de saúde', tool_id: null, id: 40, assunto: 'Alteração de vencimento', departamento_id: 2 },
            { nome: 'Desemprego', tool_id: null, id: 39, assunto: 'Alteração de vencimento', departamento_id: 2 },
            { nome: 'Atraso de salário', tool_id: null, id: 38, assunto: 'Alteração de vencimento', departamento_id: 2 },
            { nome: 'Viagem', tool_id: null, id: 41, assunto: 'Alteração de vencimento', departamento_id: 2 },
            { nome: 'Atendimento', tool_id: null, id: 135, assunto: 'Atendimento', departamento_id: 4 },
            { nome: 'Aviso de inicio da aula', tool_id: null, id: 73, assunto: 'Aviso de inicio da aula', departamento_id: 4 },
            { nome: 'Transferência externa', tool_id: null, id: 149, assunto: 'Cancelamento de contrato/Evasão', departamento_id: 2 },
            { nome: 'Mudança de cidade', tool_id: null, id: 154, assunto: 'Cancelamento de contrato/Evasão', departamento_id: 2 },
            { nome: 'Aluno nunca veio a aula', tool_id: null, id: 151, assunto: 'Cancelamento de contrato/Evasão', departamento_id: 2 },
            { nome: 'Aluno evadido', tool_id: null, id: 150, assunto: 'Cancelamento de contrato/Evasão', departamento_id: 2 },
            { nome: 'Não adequação ao nível', tool_id: null, id: 34, assunto: 'Cancelamento de contrato/Evasão', departamento_id: 3 },
            { nome: 'Problemas de saúde', tool_id: null, id: 139, assunto: 'Cancelamento de contrato/Evasão', departamento_id: 3 },
            { nome: 'Problemas de saúde', tool_id: null, id: 138, assunto: 'Cancelamento de contrato/Evasão', departamento_id: 2 },
            { nome: 'Problemas financeiros', tool_id: null, id: 30, assunto: 'Cancelamento de contrato/Evasão', departamento_id: 3 },
            { nome: 'Problemas financeiros', tool_id: null, id: 49, assunto: 'Cancelamento de contrato/Evasão', departamento_id: 2 },
            { nome: 'Não adequação ao horário', tool_id: null, id: 50, assunto: 'Cancelamento de contrato/Evasão', departamento_id: 2 },
            { nome: 'Não adequação à turma', tool_id: null, id: 51, assunto: 'Cancelamento de contrato/Evasão', departamento_id: 2 },
            { nome: 'Não adequação ao professor', tool_id: null, id: 52, assunto: 'Cancelamento de contrato/Evasão', departamento_id: 2 },
            { nome: 'Não adequação ao nível', tool_id: null, id: 53, assunto: 'Cancelamento de contrato/Evasão', departamento_id: 2 },
            { nome: 'Desalinhamento comercial-pedagógico', tool_id: null, id: 54, assunto: 'Cancelamento de contrato/Evasão', departamento_id: 2 },
            { nome: 'Não adequação ao horário', tool_id: null, id: 31, assunto: 'Cancelamento de contrato/Evasão', departamento_id: 3 },
            { nome: 'Não adequação à turma', tool_id: null, id: 32, assunto: 'Cancelamento de contrato/Evasão', departamento_id: 3 },
            { nome: 'Não adequação ao professor', tool_id: null, id: 33, assunto: 'Cancelamento de contrato/Evasão', departamento_id: 3 },
            { nome: 'Permuta', tool_id: null, id: 43, assunto: 'Cancelamento de títulos', departamento_id: 2 },
            { nome: 'Residual', tool_id: null, id: 44, assunto: 'Cancelamento de títulos', departamento_id: 2 },
            { nome: 'Parcela isenta', tool_id: null, id: 129, assunto: 'Cancelar parcela', departamento_id: 6 },
            { nome: 'Isentar metade da parcela', tool_id: null, id: 130, assunto: 'Cancelar parcela', departamento_id: 6 },
            { nome: 'Parcela já paga', tool_id: null, id: 128, assunto: 'Cancelar parcela', departamento_id: 6 },
            { nome: 'Parcela incorreta', tool_id: null, id: 127, assunto: 'Cancelar parcela', departamento_id: 6 },
            { nome: 'Contato', tool_id: 1, id: 156, assunto: 'Comercial', departamento_id: 4 },
            { nome: 'Contra-Proposta', tool_id: null, id: 71, assunto: 'Contra-Proposta', departamento_id: 4 },
            { nome: 'Alterar data de reunião', tool_id: null, id: 94, assunto: 'Correção de informação', departamento_id: 5 },
            { nome: 'Alteração de dados/informação', tool_id: null, id: 92, assunto: 'Correção de informação', departamento_id: 5 },
            { nome: 'Revisão de boletim ou faltas', tool_id: null, id: 95, assunto: 'Correção de informação', departamento_id: 5 },
            { nome: 'Alterar responsável financeiro', tool_id: null, id: 93, assunto: 'Correção de informação', departamento_id: 5 },
            { nome: 'Desistência', tool_id: null, id: 75, assunto: 'Desistência', departamento_id: 4 },
            { nome: 'Problemas financeiros', tool_id: null, id: 136, assunto: 'Em investigação', departamento_id: 3 },
            { nome: 'Condições climáticas', tool_id: null, id: 58, assunto: 'Falta em Reunião', departamento_id: 2 },
            { nome: 'Problemas de saúde', tool_id: null, id: 60, assunto: 'Falta em Reunião', departamento_id: 2 },
            { nome: 'Desimportância', tool_id: null, id: 61, assunto: 'Falta em Reunião', departamento_id: 2 },
            { nome: 'Falta em Reunião', tool_id: null, id: 14, assunto: 'Falta em Reunião', departamento_id: 3 },
            { nome: 'Compromisso trabalhista ou estudantil', tool_id: null, id: 59, assunto: 'Falta em Reunião', departamento_id: 2 },
            { nome: 'Contato', tool_id: 1, id: 157, assunto: 'Financeiro', departamento_id: 4 },
            { nome: 'Parcela de prova', tool_id: null, id: 132, assunto: 'Incluir parcela', departamento_id: 6 },
            { nome: 'Parcela de reposição', tool_id: null, id: 131, assunto: 'Incluir parcela', departamento_id: 6 },
            { nome: 'Indicações', tool_id: null, id: 76, assunto: 'Indicações', departamento_id: 4 },
            { nome: 'Interessado em outros idiomas', tool_id: null, id: 72, assunto: 'Interessado em outros idiomas', departamento_id: 4 },
            { nome: 'Não está recebendo', tool_id: null, id: 120, assunto: 'Lembrete de vencimento', departamento_id: 6 },
            { nome: 'Não quer receber', tool_id: null, id: 119, assunto: 'Lembrete de vencimento', departamento_id: 6 },
            { nome: 'Alterar responsavel que recebe', tool_id: null, id: 121, assunto: 'Lembrete de vencimento', departamento_id: 6 },
            { nome: 'Lembrete de vencimento', tool_id: null, id: 122, assunto: 'Lembrete de vencimento', departamento_id: 6 },
            { nome: 'Ligação não atendida', tool_id: null, id: 152, assunto: 'Ligação não atendida', departamento_id: 2 },
            { nome: 'Ligação não atendida', tool_id: null, id: 153, assunto: 'Ligação não atendida', departamento_id: 3 },
            { nome: 'Problemas de saúde', tool_id: null, id: 12, assunto: 'Motivação de falta', departamento_id: 3 },
            { nome: 'Condições climáticas', tool_id: null, id: 10, assunto: 'Motivação de falta', departamento_id: 3 },
            { nome: 'Compromisso trabalhista ou estudantil', tool_id: null, id: 11, assunto: 'Motivação de falta', departamento_id: 3 },
            { nome: 'Desimportância', tool_id: null, id: 13, assunto: 'Motivação de falta', departamento_id: 3 },
            { nome: 'Renegociação de título', tool_id: null, id: 3, assunto: 'Não pagamento', departamento_id: 2 },
            { nome: 'Sem dinheiro', tool_id: null, id: 7, assunto: 'Não pagamento', departamento_id: 2 },
            { nome: 'Não recebeu o carnê', tool_id: null, id: 8, assunto: 'Não pagamento', departamento_id: 2 },
            { nome: 'Pensando em parar', tool_id: null, id: 9, assunto: 'Não pagamento', departamento_id: 2 },
            { nome: 'Alteração de vencimento de título', tool_id: null, id: 4, assunto: 'Não pagamento', departamento_id: 2 },
            { nome: 'Cancelamento de título', tool_id: null, id: 2, assunto: 'Não pagamento', departamento_id: 2 },
            { nome: 'Protesto de título', tool_id: null, id: 5, assunto: 'Outros', departamento_id: 2 },
            { nome: 'Retirar protesto de título', tool_id: null, id: 6, assunto: 'Outros', departamento_id: 2 },
            { nome: 'Solicitação de informações', tool_id: null, id: 18, assunto: 'Outros', departamento_id: 3 },
            { nome: 'Baixa de título', tool_id: null, id: 1, assunto: 'Outros', departamento_id: 2 },
            { nome: 'Acompanhamento pedagógico', tool_id: null, id: 63, assunto: 'Outros', departamento_id: 1 },
            { nome: 'Aluno sem turma', tool_id: null, id: 118, assunto: 'Outros', departamento_id: 1 },
            { nome: 'Aula extra', tool_id: null, id: 114, assunto: 'Outros', departamento_id: 1 },
            { nome: 'Mudança de turma', tool_id: null, id: 115, assunto: 'Outros', departamento_id: 1 },
            { nome: 'Nivelamento', tool_id: null, id: 113, assunto: 'Outros', departamento_id: 1 },
            { nome: 'Reposição ', tool_id: null, id: 116, assunto: 'Outros', departamento_id: 1 },
            { nome: 'Reunião', tool_id: null, id: 29, assunto: 'Outros', departamento_id: 3 },
            { nome: 'Comunicação', tool_id: null, id: 19, assunto: 'Outros', departamento_id: 3 },
            { nome: 'Aluno sem livros', tool_id: null, id: 117, assunto: 'Outros', departamento_id: 1 },
            { nome: 'Contato', tool_id: 12, id: 155, assunto: 'Parceria', departamento_id: 4 },
            { nome: 'Primeira parcela', tool_id: null, id: 74, assunto: 'Primeira parcela', departamento_id: 4 },
            { nome: 'Aula extra', tool_id: null, id: 22, assunto: 'Reativação', departamento_id: 3 },
            { nome: 'Nivelamento', tool_id: null, id: 20, assunto: 'Reativação', departamento_id: 3 },
            { nome: 'Análise de turma', tool_id: null, id: 21, assunto: 'Reativação', departamento_id: 3 },
            { nome: 'Colaborador insistente', tool_id: null, id: 35, assunto: 'Reclamação', departamento_id: 3 },
            { nome: 'Descumprimento de condições', tool_id: null, id: 36, assunto: 'Reclamação', departamento_id: 3 },
            { nome: 'Descumprimento de agendamento', tool_id: null, id: 37, assunto: 'Reclamação', departamento_id: 3 },
            { nome: 'Retenção', tool_id: null, id: 111, assunto: 'Reclamação ou sugestão', departamento_id: 5 },
            { nome: 'Pedagógico', tool_id: null, id: 109, assunto: 'Reclamação ou sugestão', departamento_id: 5 },
            { nome: 'Comercial', tool_id: null, id: 110, assunto: 'Reclamação ou sugestão', departamento_id: 5 },
            { nome: 'Financeiro', tool_id: null, id: 112, assunto: 'Reclamação ou sugestão', departamento_id: 5 },
            { nome: ' Administrativo', tool_id: null, id: 99, assunto: 'Reclamação ou sugestão', departamento_id: 5 },
            { nome: 'Não irá efetuar a rematrícula', tool_id: null, id: 143, assunto: 'Rematrícula', departamento_id: 3 },
            { nome: 'Troca de livro', tool_id: null, id: 146, assunto: 'Rematrícula', departamento_id: 3 },
            { nome: 'Efetuou a rematrícula', tool_id: null, id: 142, assunto: 'Rematrícula', departamento_id: 3 },
            { nome: 'Ainda não decidiu', tool_id: null, id: 144, assunto: 'Rematrícula', departamento_id: 3 },
            { nome: 'Dívidas inesperadas', tool_id: null, id: 55, assunto: 'Renegociação', departamento_id: 2 },
            { nome: 'Desacordo com o financeiro', tool_id: null, id: 57, assunto: 'Renegociação', departamento_id: 2 },
            { nome: 'Desemprego', tool_id: null, id: 56, assunto: 'Renegociação', departamento_id: 2 },
            { nome: 'Solicitação de declaração de horas cursadas ou matricula', tool_id: null, id: 101, assunto: 'Requisição de documentos', departamento_id: 5 },
            { nome: 'Solicitação de boletim', tool_id: null, id: 91, assunto: 'Requisição de documentos', departamento_id: 5 },
            { nome: 'Cópia do contrato', tool_id: null, id: 103, assunto: 'Requisição de documentos', departamento_id: 5 },
            { nome: 'Mudança de horário de turma', tool_id: null, id: 102, assunto: 'Requisição de documentos', departamento_id: 5 },
            { nome: 'Solicitação de certifcado', tool_id: null, id: 100, assunto: 'Requisição de documentos', departamento_id: 5 },
            { nome: 'Trancamento', tool_id: null, id: 147, assunto: 'Trancamento', departamento_id: 3 },
            { nome: 'Mudança de cidade', tool_id: null, id: 148, assunto: 'Trancamento', departamento_id: 2 },
            { nome: 'Problemas de saúde', tool_id: null, id: 47, assunto: 'Trancamento', departamento_id: 2 },
            { nome: 'Outras prioridades', tool_id: null, id: 48, assunto: 'Trancamento', departamento_id: 2 },
            { nome: 'Falta de tempo', tool_id: null, id: 141, assunto: 'Trancamento', departamento_id: 2 },
            { nome: 'Destrancamento', tool_id: null, id: 145, assunto: 'Trancamento', departamento_id: 2 },
            { nome: 'Motivos financeiros', tool_id: null, id: 140, assunto: 'Trancamento', departamento_id: 2 },
            { nome: 'Mudança', tool_id: null, id: 27, assunto: 'Transferência para outra unidade', departamento_id: 3 },
            { nome: 'Preferência por outra escola', tool_id: null, id: 28, assunto: 'Transferência para outra unidade', departamento_id: 3 },
            { nome: 'Não adequação ao professor', tool_id: null, id: 26, assunto: 'Transferência para outro horário', departamento_id: 3 },
            { nome: 'Não adequação à turma', tool_id: null, id: 24, assunto: 'Transferência para outro horário', departamento_id: 3 },
            { nome: 'Nivel do aluno inferior a turma', tool_id: null, id: 133, assunto: 'Transferência para outro horário', departamento_id: 3 },
            { nome: 'Nivel do aluno superior a turma', tool_id: null, id: 134, assunto: 'Transferência para outro horário', departamento_id: 3 },
            { nome: 'Análise de turma', tool_id: null, id: 23, assunto: 'Transferência para outro horário', departamento_id: 3 },
            { nome: 'Não adequação ao horário', tool_id: null, id: 25, assunto: 'Transferência para outro horário', departamento_id: 3 },
            { nome: 'Confirmar validação de informações', tool_id: null, id: 137, assunto: 'Validação de infomação', departamento_id: 5 },
            { nome: 'Incluir na van', tool_id: null, id: 108, assunto: 'Van', departamento_id: 5 },
            { nome: 'Reclamação do aluno', tool_id: null, id: 97, assunto: 'Van', departamento_id: 5 },
            { nome: 'Aviso que não irá vir de van', tool_id: null, id: 98, assunto: 'Van', departamento_id: 5 },
            { nome: 'Aviso que não irá voltar de van', tool_id: null, id: 104, assunto: 'Van', departamento_id: 5 },
            { nome: 'Alterar de ponto/horário', tool_id: null, id: 105, assunto: 'Van', departamento_id: 5 },
            { nome: 'Problema com transporte ', tool_id: null, id: 106, assunto: 'Van', departamento_id: 5 },
            { nome: 'Retirar da van', tool_id: null, id: 107, assunto: 'Van', departamento_id: 5 },
            { nome: 'Reclamação do motorista', tool_id: null, id: 96, assunto: 'Van', departamento_id: 5 },
            { nome: 'Duas faltas resolvido', tool_id: null, id: 1082, assunto: 'Motivação de falta', departamento_id: 3 },
            { nome: 'Duas faltas consecutivas', tool_id: null, id: 1083, assunto: 'Motivação de falta', departamento_id: 3 }
        ]);
        // nao precisa bater na api
        // try {
        //   const response = await api.getObjecaos(mainStore.token);
        //   if (response) {
        //     this.setObjecoes(response.data);
        //   }
        // } catch (error) {
        //   await mainStore.checkApiError(error);
        // }
    }
};
__decorate([
    Mutation
], ObjecaoModule.prototype, "setObjecoes", null);
__decorate([
    Action
], ObjecaoModule.prototype, "getObjecaos", null);
ObjecaoModule = __decorate([
    Module({ name: 'objecao' })
], ObjecaoModule);
export default ObjecaoModule;
