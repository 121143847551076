import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { mapaHorarioStore, salaStore, feriadoStore, userStore, userActionStore, mainStore, } from '@/store';
import { format } from 'date-fns';
import EntidadeLinkComponent from '@/components/EntidadeLinkComponent.vue';
import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';
import 'vue-cal/dist/i18n/pt-br.js';
import _ from 'lodash';
let MapaHorarioList = class MapaHorarioList extends Vue {
    constructor() {
        super(...arguments);
        this.loading = true;
        this.tab = 0;
        this.dayOffset = 0;
        this.dayOffsetWeek = 0;
        this.dayOffsetDay = 0;
        this.selectedEvent = null;
        this.selectedView = 'week';
        this.showDialog = false;
        this.search = '';
        this.selectedItem = {};
        this.customDaySplit = [];
        this.filtroTarefasTodosUsuarios = false;
        this.focus = '';
        this.focusWeek = '';
        this.events = [];
        this.eventsWeek = [];
        this.colors = {
            aula: 'blue',
            reposicao: 'indigo',
            aula_extra: 'deep-purple',
        };
        this.selectedOpenWeek = false;
        this.weekEventsSize = 0;
        this.selectedEventWeek = {};
        this.selectedElementWeek = null;
        this.filtroSala = [2, 3, 4];
        this.filtroSemana = [0, 1, 2, 3, 4];
        this.professoreSelecionadosPorSala = [];
        this.professoreSelecionadosPorSemana = [];
        this.responsaveis = [];
        this.nomesEntidades = {
            'turma': 'Turma',
            'reposicao': 'Reposição',
            'aula-extra': 'Aula Extra',
        };
    }
    get visualizarTarefasUsuariosMapaHorarios() {
        return userActionStore.visualizarTarefasUsuariosMapaHorarios;
    }
    get salas() {
        return salaStore.salas.map((item) => item.nome);
    }
    get salaDiaItems() {
        const groupedBySala = _.groupBy(mapaHorarioStore.mapaHorarioPorSala, 'sala_nome');
        return groupedBySala;
    }
    get eventsWeekFiltered() {
        const filtered = [];
        let allowedItem = true;
        _.forEach(this.eventsWeek, (item) => {
            let achouProfessor = true;
            achouProfessor = false;
            allowedItem = true;
            for (const professorSelecionado of this.professoreSelecionadosPorSemana) {
                if (item.professor_nome &&
                    item.professor_nome === professorSelecionado) {
                    achouProfessor = true;
                }
            }
            if (!(this.filtroSemana.includes(this.getTipoValue(item.name)) &&
                achouProfessor)) {
                allowedItem = false;
            }
            if (!((item.tipo == 'tarefa' &&
                (this.filtroTarefasTodosUsuarios ||
                    mainStore.userProfile.id == item.responsavel_id)) ||
                item.tipo != 'tarefa')) {
                allowedItem = false;
            }
            if (allowedItem) {
                filtered.push(item);
            }
        });
        return filtered;
    }
    get eventsFiltered() {
        const filtered = [];
        _.forEach(this.events, (item) => {
            let achouProfessor = true;
            achouProfessor = false;
            for (const professorSelecionado of this.professoreSelecionadosPorSala) {
                if (item.professor_nome &&
                    item.professor_nome === professorSelecionado) {
                    achouProfessor = true;
                }
            }
            if (this.filtroSala.includes(this.getTipoValue(item.name)) &&
                achouProfessor) {
                filtered.push(item);
            }
        });
        return filtered;
    }
    getChipColor(value) {
        switch (value) {
            case 0:
                return '#e57373'; // reunião
            case 1:
                return '#4dd0e1'; // tarefa
            case 2:
                return '#81c784'; // aula
            case 3:
                return '#4fc3f7'; // reposição
            case 4:
                return '#ff8a65'; // aula extra
        }
    }
    getTipoValue(value) {
        switch (value) {
            case 'reuniao':
                return 0; // reunião
            case 'tarefa':
                return 1; // tarefa
            case 'aula':
                return 2; // aula
            case 'reposicao':
                return 3; // reposição
            case 'aula_extra':
                return 4; // aula extra
        }
    }
    ajustaHora(hora) {
        if (hora) {
            const formated_hora = format(hora, 'HH:mm:ss').split(':');
            return `${formated_hora[0]}:${formated_hora[1]}`;
        }
    }
    getWeekEventsSize() {
        let atividadeLen = 0;
        const eventsMesmoHorario = {};
        _.forEach(this.eventsWeek, (item) => {
            const dataItem = new Date(item.start);
            if (dataItem.getHours() > 5) {
                if (item.descricao.length > atividadeLen) {
                    atividadeLen = item.descricao.length;
                }
                if (!eventsMesmoHorario[item.start]) {
                    eventsMesmoHorario[item.start] = 0;
                }
                eventsMesmoHorario[item.start] = eventsMesmoHorario[item.start] + 1;
            }
        });
        let maiorDensidadeHorario = 0;
        _.forEach(eventsMesmoHorario, (item) => {
            if (maiorDensidadeHorario < item) {
                maiorDensidadeHorario = item;
            }
        });
        return maiorDensidadeHorario * 120;
    }
    getEventColor(event) {
        return event.color;
    }
    getTime(item) {
        const horas = new Date(item);
        horas.setHours(horas.getHours() - 3);
        const res = horas.toISOString().split('T')[1].split(':');
        return `${res[0]}:${res[1]}`;
    }
    setToday(ref) {
        // @ts-ignore
        this.$refs[ref].switchView('day', new Date());
        if (ref === 'vuecalsalas') {
            this.dayOffset = 0;
            const hj = new Date().toISOString().split('T')[0].split('-');
            this.fetchEvents({
                start: { day: hj[2], month: hj[1], year: hj[0] },
                end: { day: hj[2], month: hj[1], year: hj[0] },
            }, 'calendar');
        }
        else {
            this.dayOffsetWeek = 0;
            const curr = new Date();
            const firsty = curr.getDate() - curr.getDay();
            const last = curr.getDate() - curr.getDay() + 7;
            const firstday = new Date(curr.setDate(firsty))
                .toISOString()
                .split('T')[0]
                .split('-');
            const lastday = new Date(curr.setDate(last))
                .toISOString()
                .split('T')[0]
                .split('-');
            const start = { day: firstday[2], month: firstday[1], year: firstday[0] };
            const end = { day: lastday[2], month: lastday[1], year: lastday[0] };
            this.fetchEvents({ start, end }, '');
        }
    }
    prev(ref) {
        // @ts-ignore
        if (ref === 'vuecalsalas') {
            this.dayOffset = this.dayOffset - 1;
            const hj = new Date();
            hj.setDate(hj.getDate() + this.dayOffset);
            const data = hj.toISOString().split('T')[0].split('-');
            this.fetchEvents({
                start: { day: data[2], month: data[1], year: data[0] },
                end: { day: data[2], month: data[1], year: data[0] },
            }, 'calendar');
        }
        else {
            const curr = new Date();
            const ender = new Date();
            if (this.selectedView === 'week') {
                this.dayOffsetWeek = this.dayOffsetWeek - 7;
                curr.setDate(curr.getDate() + this.dayOffsetWeek);
                ender.setDate(ender.getDate() + this.dayOffsetWeek);
            }
            else {
                this.dayOffsetDay = this.dayOffsetDay - 1;
                curr.setDate(curr.getDate() + this.dayOffsetDay);
                ender.setDate(ender.getDate() + this.dayOffsetWeek);
            }
            const firsty = curr.getDate() - curr.getDay();
            const last = ender.getDate() - ender.getDay() + 7;
            const firstday = new Date(curr.setDate(firsty))
                .toISOString()
                .split('T')[0]
                .split('-');
            const lastday = new Date(ender.setDate(last))
                .toISOString()
                .split('T')[0]
                .split('-');
            const start = { day: firstday[2], month: firstday[1], year: firstday[0] };
            const end = { day: lastday[2], month: lastday[1], year: lastday[0] };
            this.fetchEvents({ start, end }, '');
        }
    }
    next(ref) {
        // @ts-ignore
        if (ref === 'vuecalsalas') {
            this.dayOffset = this.dayOffset + 1;
            const hj = new Date();
            hj.setDate(hj.getDate() + this.dayOffset);
            const data = hj.toISOString().split('T')[0].split('-');
            this.fetchEvents({
                start: { day: data[2], month: data[1], year: data[0] },
                end: { day: data[2], month: data[1], year: data[0] },
            }, 'calendar');
        }
        else {
            const curr = new Date();
            const ender = new Date();
            if (this.selectedView === 'week') {
                this.dayOffsetWeek = this.dayOffsetWeek + 7;
                curr.setDate(curr.getDate() + this.dayOffsetWeek);
                ender.setDate(ender.getDate() + this.dayOffsetWeek);
            }
            else {
                this.dayOffsetDay = this.dayOffsetDay + 1;
                curr.setDate(curr.getDate() + this.dayOffsetDay);
                ender.setDate(ender.getDate() + this.dayOffsetWeek);
            }
            const firsty = curr.getDate() - curr.getDay();
            const last = ender.getDate() - ender.getDay() + 7;
            const firstday = new Date(curr.setDate(firsty))
                .toISOString()
                .split('T')[0]
                .split('-');
            const lastday = new Date(ender.setDate(last))
                .toISOString()
                .split('T')[0]
                .split('-');
            const start = { day: firstday[2], month: firstday[1], year: firstday[0] };
            const end = { day: lastday[2], month: lastday[1], year: lastday[0] };
            this.fetchEvents({ start, end }, '');
        }
    }
    async fetchEvents({ start, end }, ref) {
        this.events = [];
        this.eventsWeek = [];
        let tempCronograma = {};
        let tempData = '';
        let tempSala = '';
        const datas = { data_inicio: '', data_fim: '' };
        datas.data_inicio = start.year + '-' + start.month + '-' + start.day;
        datas.data_fim = end.year + '-' + end.month + '-' + end.day;
        const feriados = await feriadoStore.getFeriadosPeriodo(datas);
        const conjunto_salas = [];
        if (ref === 'calendar') {
            // @ts-ignore
            await mapaHorarioStore.getMapaHorarioPorSala({
                year: start.year,
                month: start.month,
                day: start.day,
            });
            if (mapaHorarioStore.mapaHorarioPorSala[1].length === 0) {
                return;
            }
            const cronogramaEvents = mapaHorarioStore.mapaHorarioPorSala[1].map((item) => {
                const dataToReturn = [];
                // - start
                _.forEach(item.cronograma_horario, (horarios) => {
                    const index = item.cronograma_horario.indexOf(horarios);
                    let atualCronograma = {};
                    const atualData = item.data_hora[index];
                    const atualSala = item.sala_nome[index];
                    let hora = '';
                    _.forEach(horarios, (obj) => {
                        const data = new Date(atualData);
                        data.setUTCHours(data.getTimezoneOffset() / 60);
                        const dia_semana = data.getDay();
                        if (obj.dia_semana_index === dia_semana) {
                            hora = obj.horario;
                            atualCronograma = obj;
                        }
                    });
                    let h = null;
                    let m = null;
                    if (hora) {
                        const hm = hora.split(':');
                        // const hm = ['09', '00'];
                        h = parseInt(hm[0], 0);
                        m = parseInt(hm[1], 0);
                    }
                    else {
                        const hm = ['00', '00'];
                        h = parseInt(hm[0], 0);
                        m = parseInt(hm[1], 0);
                    }
                    let s = null;
                    let e = null;
                    if (
                    // @ts-ignore
                    atualCronograma.horario === tempCronograma.horario &&
                        atualData === tempData &&
                        atualSala === tempSala) {
                        s = new Date(atualData);
                        e = new Date(atualData);
                        s.setDate(s.getDate() + 1);
                        e.setDate(e.getDate() + 1);
                        s.setHours(h);
                        s.setMinutes(m);
                        s.setHours(s.getHours() + 1);
                        e.setHours(s.getHours() + 1);
                        e.setMinutes(s.getMinutes());
                    }
                    else {
                        s = new Date(atualData);
                        e = new Date(atualData);
                        s.setDate(s.getDate() + 1);
                        e.setDate(e.getDate() + 1);
                        s.setHours(h);
                        s.setMinutes(m);
                        e.setHours(s.getHours() + 1);
                        e.setMinutes(s.getMinutes());
                    }
                    s.setHours(s.getHours() - s.getTimezoneOffset() / 60);
                    e.setHours(e.getHours() - e.getTimezoneOffset() / 60);
                    tempCronograma = atualCronograma;
                    tempData = atualData;
                    tempSala = atualSala;
                    hora = '';
                    dataToReturn.push({
                        descricao: item.descricao[index],
                        professor_nome: item.full_name[index],
                        name: item.tipo,
                        content: item.livro_nome[index].replace('LIVRO', '') +
                            '<br/>Atividade: ' +
                            item.descricao[index] +
                            '<br/> Turma: ' +
                            item.entidade_nome,
                        // @ts-ignore
                        start: this.dateAndTime(s.toISOString()).join(' '),
                        // @ts-ignore
                        end: this.dateAndTime(e.toISOString()).join(' '),
                        timed: true,
                        class: 'aula',
                        id: item.id,
                        entidade: item.entidade,
                        entidade_nome: item.entidade_nome,
                        title: 'Aula',
                        split: atualSala,
                        category: atualSala,
                        livro_nome: item.livro_nome[index],
                        livro_edicao: item.livro_edicao[index],
                    });
                });
                // - end
                return dataToReturn;
            });
            // anchor
            const cronogramaReposicaoEvents = mapaHorarioStore.mapaHorarioPorSala[0].map((item) => {
                if (item.entidade === 'reposicao' && item.sala_nome) {
                    const s = new Date(item.data_hora);
                    const e = new Date(item.data_hora);
                    const tmz = new Date().getTimezoneOffset() / 60;
                    s.setHours(s.getHours() - tmz);
                    e.setHours(s.getHours() + 1);
                    return {
                        descricao: item.descricao,
                        professor_nome: item.professor_nome,
                        name: item.tipo,
                        content: item.livro_nome.replace('LIVRO', '') +
                            '<br/>Atividade: ' +
                            item.descricao +
                            '<br/>Professor: ' +
                            item.professor_nome,
                        // @ts-ignore
                        start: this.dateAndTime(s.toISOString()).join(' '),
                        // @ts-ignore
                        end: this.dateAndTime(e.toISOString()).join(' '),
                        id: item.id,
                        entidade: item.entidade,
                        entidade_nome: item.entidade_nome,
                        color: this.colors[item.tipo],
                        timed: true,
                        class: 'reposicao',
                        category: item.sala_nome,
                        title: 'Reposição',
                        split: item.sala_nome,
                        livro_nome: item.livro_nome,
                        livro_edicao: item.livro_edicao,
                        alunos: item.alunos,
                    };
                }
            });
            const cronogramaExtraEvents = mapaHorarioStore.mapaHorarioPorSala[0].map((item) => {
                if (item.entidade === 'aula-extra' && item.sala_nome) {
                    const s = new Date(item.data_hora);
                    const e = new Date(item.data_hora);
                    const tmz = new Date().getTimezoneOffset() / 60;
                    s.setHours(s.getHours() - tmz);
                    e.setHours(s.getHours() + 1);
                    return {
                        descricao: item.descricao,
                        professor_nome: item.professor_nome,
                        name: item.tipo,
                        content: item.livro_nome.replace('LIVRO', '') +
                            '<br/>Atividade: ' +
                            item.descricao +
                            '<br/>Professor: ' +
                            item.professor_nome,
                        // @ts-ignore
                        start: this.dateAndTime(s.toISOString()).join(' '),
                        // @ts-ignore
                        end: this.dateAndTime(e.toISOString()).join(' '),
                        id: item.id,
                        entidade: item.entidade,
                        entidade_nome: item.entidade_nome,
                        color: this.colors[item.tipo],
                        timed: true,
                        class: 'aula-extra',
                        category: item.sala_nome,
                        title: 'Aula Extra',
                        split: item.sala_nome,
                        livro_nome: item.livro_nome,
                        livro_edicao: item.livro_edicao,
                        alunos: item.alunos,
                    };
                }
            });
            // this.events = [];
            _.forEach(feriados, (item) => {
                _.forEach(this.salas, (sala) => {
                    this.events.push({
                        descricao: '',
                        start: item.data,
                        end: item.data,
                        title: item.nome,
                        content: '',
                        class: 'feriado',
                        allDay: true,
                        name: 'Feriado',
                        split: sala,
                    });
                });
            });
            _.forEach(cronogramaEvents, (item) => {
                _.forEach(item, (dado) => {
                    if (dado) {
                        this.events.push(dado);
                    }
                });
            });
            _.forEach(cronogramaReposicaoEvents, (item) => {
                if (item) {
                    this.events.push(item);
                }
            });
            _.forEach(cronogramaExtraEvents, (item) => {
                if (item) {
                    this.events.push(item);
                }
            });
            const professores_sala = _(this.events)
                .uniqBy('professor_nome')
                .map('professor_nome')
                .uniq()
                .sort()
                .value();
            this.professoreSelecionadosPorSala = _.filter(professores_sala, (item) => {
                if (item) {
                    return item;
                }
            });
            return;
        }
        // @ts-ignore
        const events = await mapaHorarioStore.getHorarioPorSemana({
            startYear: start.year,
            startMonth: start.month,
            startDay: start.day,
            endYear: end.year,
            endMonth: end.month,
            endDay: end.day,
        });
        // @ts-ignore
        const cronograma = await mapaHorarioStore.getCronogramaPorSemana({
            startYear: start.year,
            startMonth: start.month,
            startDay: start.day,
            endYear: end.year,
            endMonth: end.month,
            endDay: end.day,
        });
        this.responsaveis = await userStore.getUserNames();
        this.responsaveis = this.responsaveis.sort();
        tempCronograma = {};
        tempData = '';
        tempSala = '';
        _.forEach(cronograma, (item) => {
            item.cronograma_horario = item.cronograma_horario.reverse();
            item.sala_nome = item.sala_nome.reverse();
            item.descricao = item.descricao.reverse();
            item.full_name = item.full_name.reverse();
            item.data_hora = item.data_hora.reverse();
            if (item.cronograma_horario.length > this.weekEventsSize) {
                this.weekEventsSize = item.cronograma_horario.length;
            }
        });
        const cronogramaEventsWeek = cronograma.map((item) => {
            const dataToReturn = [];
            _.forEach(item.cronograma_horario, (horarios) => {
                const index = item.cronograma_horario.indexOf(horarios);
                let atualCronograma = {};
                const atualData = item.data_hora[index];
                const atualSala = item.sala_nome[index];
                let hora = '';
                _.forEach(horarios, (obj) => {
                    const data = new Date(atualData);
                    data.setUTCHours(data.getTimezoneOffset() / 60);
                    const dia_semana = data.getDay();
                    if (obj.dia_semana_index === dia_semana) {
                        hora = obj.horario;
                        atualCronograma = obj;
                    }
                });
                let h = null;
                let m = null;
                if (hora) {
                    const hm = hora.split(':');
                    // const hm = ['09', '00'];
                    h = parseInt(hm[0], 0);
                    m = parseInt(hm[1], 0);
                }
                else {
                    const hm = ['00', '00'];
                    h = parseInt(hm[0], 0);
                    m = parseInt(hm[1], 0);
                }
                let s = null;
                let e = null;
                if (
                // @ts-ignore
                atualCronograma.horario === tempCronograma.horario &&
                    atualData === tempData &&
                    atualSala === tempSala) {
                    s = new Date(atualData);
                    e = new Date(atualData);
                    s.setDate(s.getDate() + 1);
                    e.setDate(e.getDate() + 1);
                    s.setHours(h);
                    s.setMinutes(m);
                    s.setHours(s.getHours() + 1);
                    e.setHours(s.getHours() + 1);
                    e.setMinutes(s.getMinutes());
                }
                else {
                    s = new Date(atualData);
                    e = new Date(atualData);
                    s.setDate(s.getDate() + 1);
                    e.setDate(e.getDate() + 1);
                    s.setHours(h);
                    s.setMinutes(m);
                    e.setHours(s.getHours() + 1);
                    e.setMinutes(s.getMinutes());
                }
                s.setHours(s.getHours() - s.getTimezoneOffset() / 60);
                e.setHours(e.getHours() - e.getTimezoneOffset() / 60);
                tempCronograma = atualCronograma;
                tempData = atualData;
                tempSala = atualSala;
                hora = '';
                if (s.getTime() === s.getTime()) {
                    dataToReturn.push({
                        descricao: item.descricao[index],
                        professor_nome: item.full_name[index],
                        name: item.tipo,
                        content: item.livro_nome[index].replace('LIVRO', '') +
                            '<br/>Atividade: ' +
                            item.descricao[index] +
                            '<br/> Turma: ' +
                            item.entidade_nome,
                        // @ts-ignore
                        start: this.dateAndTime(s.toISOString()).join(' '),
                        // @ts-ignore
                        end: this.dateAndTime(e.toISOString()).join(' '),
                        timed: true,
                        class: 'aula',
                        id: item.id,
                        entidade: item.entidade,
                        entidade_nome: item.entidade_nome,
                        title: 'Aula',
                        category: atualSala,
                        livro_nome: item.livro_nome[index],
                        livro_edicao: item.livro_edicao[index],
                    });
                }
            });
            return dataToReturn;
        });
        // this.eventsWeek = [];
        _.forEach(feriados, (item) => {
            this.eventsWeek.push({
                descricao: '',
                start: item.data,
                end: item.data,
                title: item.nome,
                content: '',
                class: 'feriado',
                allDay: true,
                name: 'Feriado',
            });
        });
        _.forEach(cronogramaEventsWeek, (item) => {
            _.forEach(item, (dado) => {
                this.eventsWeek.push(dado);
            });
        });
        const tempEventsWeek = events.map((item) => {
            const s = new Date(item.data_hora);
            const e = new Date(item.data_hora);
            const tmz = new Date().getTimezoneOffset() / 60;
            s.setHours(s.getHours() - tmz * 2);
            e.setHours(s.getHours() + 1);
            return {
                descricao: item.descricao,
                professor_nome: item.professor_nome,
                name: item.tipo,
                content: item.livro_nome.replace('LIVRO', '') +
                    '<br/>Atividade: ' +
                    item.descricao +
                    '<br/>Professor: ' +
                    item.professor_nome,
                // @ts-ignore
                start: this.dateAndTime(s.toISOString()).join(' '),
                // @ts-ignore
                end: this.dateAndTime(e.toISOString()).join(' '),
                id: item.id,
                entidade: item.entidade,
                entidade_nome: item.entidade_nome,
                livro_nome: item.livro_nome,
                livro_edicao: item.livro_edicao,
                color: this.colors[item.tipo],
                timed: true,
                title: item.tipo === 'aula_extra' ? 'Aula Extra' : 'Reposição',
                class: item.tipo === 'aula_extra' ? 'aula-extra' : 'reposicao',
                category: item.sala_nome,
                alunos: item.alunos,
            };
        });
        _.forEach(tempEventsWeek, (item) => {
            this.eventsWeek.push(item);
        });
        // @ts-ignore
        const cronogramaTarefas = 
        // @ts-ignore
        await mapaHorarioStore.getMapaHorarioTarefasReunioes({
            startYear: start.year,
            startMonth: start.month,
            startDay: start.day,
            endYear: end.year,
            endMonth: end.month,
            endDay: end.day,
        });
        _.forEach(cronogramaTarefas, (item) => {
            const s = new Date(item.data_hora);
            const e = new Date(item.data_hora);
            s.setHours(s.getHours() - 3);
            e.setHours(s.getHours() + 1);
            this.eventsWeek.push({
                descricao: item.descricao,
                // @ts-ignore
                start: this.dateAndTime(s.toISOString()).join(' '),
                // @ts-ignore
                end: this.dateAndTime(e.toISOString()).join(' '),
                title: item.tipo == 'tarefa' ? 'Tarefa' : 'Reunião',
                content: 'Departamento: ' +
                    item.departamento +
                    '<br/>Responsável: ' +
                    item.responsavel,
                departamento: item.departamento,
                professor_nome: item.responsavel,
                id: item.id,
                entidade: item.entidade,
                entidade_nome: item.entidade_nome,
                class: item.tipo,
                name: item.tipo,
                aluno_nome: item.aluno_nome,
                aluno_id: item.aluno_id,
                tipo: item.tipo,
                responsavel_id: item.responsavel_id,
            });
        });
        const professores_semana = _(this.eventsWeek)
            .uniqBy('professor_nome')
            .map('professor_nome')
            .uniq()
            .sort()
            .value();
        this.professoreSelecionadosPorSemana = _.filter(professores_semana, (item) => {
            if (item) {
                return item;
            }
        });
    }
    showEvent({ nativeEvent, event }) {
        const open = () => {
            this.selectedEventWeek = event;
            this.selectedElementWeek = nativeEvent.target;
            requestAnimationFrame(() => requestAnimationFrame(() => (this.selectedOpenWeek = true)));
        };
        if (this.selectedOpenWeek) {
            this.selectedOpenWeek = false;
            requestAnimationFrame(() => requestAnimationFrame(() => open()));
        }
        else {
            open();
        }
        nativeEvent.stopPropagation();
    }
    onEventClick(event, e) {
        this.selectedEvent = event;
        this.showDialog = true;
        // Prevent navigating to narrower view (default vue-cal behavior).
        e.stopPropagation();
    }
    onCancel() {
        this.showDialog = false;
    }
    async mounted() {
        this.loading = true;
        await salaStore.getSalas();
        _.forEach(this.salas, (item) => {
            this.customDaySplit.push({ id: item, label: item, class: item });
        });
        // criado mais um date pois usando o mesmo estava dando conflito quand era utilizado entre meses
        const curr = new Date();
        const ender = new Date();
        const firsty = curr.getDate() - curr.getDay();
        const last = ender.getDate() - ender.getDay() + 7;
        const firstday = new Date(curr.setDate(firsty))
            .toISOString()
            .split('T')[0]
            .split('-');
        const lastday = new Date(ender.setDate(last))
            .toISOString()
            .split('T')[0]
            .split('-');
        const hj = new Date().toISOString().split('T')[0].split('-');
        const start = { day: firstday[2], month: firstday[1], year: firstday[0] };
        const end = { day: lastday[2], month: lastday[1], year: lastday[0] };
        this.fetchEvents({ start, end }, '');
        this.fetchEvents({ start: { day: hj[2], month: hj[1], year: hj[0] }, end }, 'calendar');
        this.loading = false;
    }
    routeEdit(id) {
        this.$router.push({ name: 'main-salas-edit', params: { id } });
    }
};
MapaHorarioList = __decorate([
    Component({
        components: {
            VueCal,
            EntidadeLinkComponent,
        },
    })
], MapaHorarioList);
export default MapaHorarioList;
